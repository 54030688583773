import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: {},
};

const createRefineryTreeSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    createRefineryTree(state, action) {
      state.data = action.payload;
    },
  },
});
export const createRefineryTreeAction = createRefineryTreeSlice.actions;
export default createRefineryTreeSlice;
