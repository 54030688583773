/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { BhTitleWrapper } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './PlantDetails.scss';
import ChangeUnitModal from '../Modal/ChangeUnitModal';
import { useNavigate } from 'react-router-dom';

export default function PlantDetails(props: any) {
  const { getConfigData } = props;
  const navigate = useNavigate();
  const [subText, setSubText] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [isOpen, setOpenFlag] = useState(false);
  const [simulationLabel, setSimulationLabel] = useState('');

  const closeModal = () => {
    setOpenFlag(false);
  };

  // const closeSimulationModal = () => {
  //   setSimulationOpen(false);
  // };

  const CtaClicked = (event: CustomEvent) => {
    if (event.detail === 'Change unit') {
      setOpenFlag(true);
    }
    if (event.detail === 'New simulation' || event.detail === 'New sandbox simulation') {
      //setSimulationOpen(true);
      navigate('/new-simulation');
    }
  };
  useEffect(() => {
    let configData=JSON.parse(localStorage.getItem('defaultConfig')!);
    if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') ==='true')
    { 
      setSimulationLabel('New sandbox simulation');
      setTimeout(() => {
        document.getElementsByClassName('bh-title-wrapper__cta--secondary') && document.getElementsByClassName('bh-title-wrapper__cta--secondary')[0] && document.getElementsByClassName('bh-title-wrapper__cta--secondary')[0]!.setAttribute('disabled', 'true');
      }, 50);
    }
    else{
      setSimulationLabel('New simulation');
      setTimeout(() => {
      document.getElementsByClassName('bh-title-wrapper__cta--secondary') && document.getElementsByClassName('bh-title-wrapper__cta--secondary')[0] && document.getElementsByClassName('bh-title-wrapper__cta--secondary')[0]!.setAttribute('disabled', 'false');
    }, 50);
    }
    setSubText(localStorage.getItem('subtitle')!);
    let custName= configData &&
    configData.refinery &&
    configData.refinery.customerName;
    setCustomerName(custName);
  },[localStorage.getItem('subtitle'), localStorage.getItem('isSandbox')]);

  return (
    <React.Fragment>
      <BhTitleWrapper
        data-testid="plant-info-title"
        type="title"
        header={customerName}
        subtext={subText}
        cta={JSON.stringify({
          primary: simulationLabel,
          secondary : 'Change unit'
        })}
        onBhEventCtaClick={(event: any) => {
          CtaClicked(event);
        }}
      ></BhTitleWrapper>

      <ChangeUnitModal
        isOpen={isOpen}
        header="Change unit"
        isDismissible={true}
        closeModal={closeModal}
        getConfigData={getConfigData}
      ></ChangeUnitModal>
    </React.Fragment>
  );
}
