/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { BhModal, BhTextInput } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Alert, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import { sortWithKey } from '../../utils/utils';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import { useNavigate } from 'react-router-dom';
import { FAVORITE_COUNT, commonConditionCheck, searchTreeFavorite, sortOrder } from './helper';

declare const window: any;

export interface RefineriesData {
  children: (string | number)[];
  expanded: boolean;
  key: string;
  name: string;
}

export default function FavoriteModal(props: any) {
  const { header, isOpen, closeModal, isEdit, getConfigData, getFavoriteListData } = props;
  const [ctaData, setCtaData] = useState<any>([]);
  const refineryTreeFromStore = useSelector((state: any) => state.createRefineryTree);
  const favoriteStoreData = useSelector((state: any) => state.createSummaryResponse);
  const [expanded, setExpanded] = useState<any>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState<boolean>(false);
  const [isSuccess, setSuccessFlag] = useState<boolean>(false);
  const [nodeList, setNodeList] = useState([]);
  const [removeList, setRemoveList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let selectedItemList: any = [];

    // if (isEdit) {
          if (favoriteStoreData.summary) {
            favoriteStoreData.summary.forEach((fav: any) => {
                            let selectedItem = {
                              key: fav.subUnitId + '_' + 'subunit',
                              name: fav.subUnitName,
                              expanded: false,
                              refineryID: fav.refineryId,
                              customerID: fav.customerId,
                              unitID: fav.unitId,
                            };
                            selectedItemList.push(selectedItem);
      });
      setNodeList(selectedItemList);
    }
   //  }
  }, [isEdit, favoriteStoreData, isOpen]);

  useEffect(() => {
    let ctaArray = [];
    ctaArray.push(
      {
        type: 'ghost',
        label: 'Cancel',
        key: 'btnCancel',
      },
      { type: 'primary', label: 'Add to favorites', key: 'btnSave', isDisabled: btnDisable(nodeList), class: 'cls-save' }
    );
    setCtaData(ctaArray);
  }, [nodeList]);

  const btnDisable = (listItem: any) => {
    if (listItem.length || isEdit) {
      return false;
    } else {
      return true;
    }
  };


  const dashboardAdd = (event: any, selectedItem: any) => {
    let itemWhichSelected: any = [...nodeList];
    if(event.target.checked) {
      if(itemWhichSelected.length === FAVORITE_COUNT) {
        return
      }
    }
    let indexValue = commonConditionCheck(itemWhichSelected, selectedItem);

  
    if (indexValue !== -1) {
      itemWhichSelected.splice(indexValue, 1);
    } else {
      itemWhichSelected.push(selectedItem);
    }
    setNodeList(itemWhichSelected);
  };

  const favoriteAddEdit = (event: any, selectedItem: any) => {
    // store
    let itemWhichSelected: any = [...nodeList];
    let removeItems: any = [...removeList];
    if (event.target.checked) {
      if(itemWhichSelected.length === FAVORITE_COUNT) {
        return
      }
      itemWhichSelected.push(selectedItem);

      let rmItems = removeItems.findIndex((jj: any) => jj === Number(selectedItem.key.split('_')[0]));
      if (rmItems !== -1) {
        removeItems.splice(rmItems, 1);
        setRemoveList(removeItems);
      }

      setNodeList(itemWhichSelected);
    } else {
      let indexValue = commonConditionCheck(itemWhichSelected, selectedItem);
      if (indexValue !== -1) {
        if (Object.keys(favoriteStoreData).length) {
              removeItems.push(Number(itemWhichSelected[indexValue].key.split('_')[0]));
              setRemoveList(removeItems);
        }

         itemWhichSelected.splice(indexValue, 1);
        setNodeList(itemWhichSelected);
      }
    }
  };

  const findCheck = (item: any) => {
    if (nodeList.length) {
      let findElement = nodeList.find(
        (value: any) =>
          value.name === item.name && value.key === item.key && value.customerID === item.customerID && value.refineryID === item.refineryID
      );
      if (findElement) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getTreeItemsFromData = (treeItems: any) => {
    if (treeItems && treeItems.length) {
      return treeItems.map((treeItemData: any) => {
        let children;
        let newLabel = null;
        if (treeItemData.children && treeItemData.children.length > 0) {
          children = getTreeItemsFromData(treeItemData.children);
        }

        let conditionCheck =
          treeItemData.name.trim().toLowerCase() === 'atm tower overhead'
            ? 'Atmospheric Tower Overhead'
            : treeItemData.name.trim().toLowerCase() === 'prefractionator overhead'
            ? 'Prefractionator Overhead'
            : treeItemData.name.trim().toLowerCase() === 'fractionator overhead'
            ? 'Fractionator Overhead'
            : treeItemData.name.trim().toLowerCase() === 'main fractionator overhead'
            ? 'Main Fractionator Overhead'
            : treeItemData.name;



        if (children) {
          newLabel = conditionCheck;
        } else {
          newLabel = (
            <div>
              <Checkbox
                checked={findCheck(treeItemData)}
                onChange={(e: any) => (!isEdit ? dashboardAdd(e,treeItemData) : favoriteAddEdit(e, treeItemData))}
                id={conditionCheck}
                color="primary"
              />
              <span>{conditionCheck}</span>
            </div>
          );
        }

        return <TreeItem  key={treeItemData.key} nodeId={treeItemData.key} label={newLabel} children={children} className="favorite-tree" />;
      });
    } else {
      return <TreeItem key="1" nodeId="1" label="No items to display" />;
    }
  };


  const cleaningData = (txt = '') => {
    setNodeList([]);
    setExpanded([]);
    setRemoveList([]);
    if (txt !== 'no-call') {
      setTimeout(() => {
        getConfigData && getConfigData(true);
      }, 2000);
    }

    closeModal();
  };

  // api
  const editFavorite = () => {
    let eachSelected: any;

    eachSelected = { addedSubUnits: [], removedSubUnits: [] };

    nodeList.forEach((element: any) => {
      if (favoriteStoreData) {
        if (favoriteStoreData.summary && favoriteStoreData.summary.length > 0) {
            let requestItem: any = {
              refineryId: element.refineryID,
              customerId: element.customerID,
              subUnitId: Number(element.key.split('_')[0]),
              unitId: element.unitID,
            };
            eachSelected.addedSubUnits.push(requestItem);
        }
      }
    });

    removeList.forEach((element: any) => {
      eachSelected.removedSubUnits.push(element);
    });

    apiResponse(eachSelected);
  };

  // api dashboard empty case
  const addFavorite = () => {
    let eachSelected: any;
    eachSelected = { addedSubUnits: [] };
    nodeList.forEach((element: any) => {
          let requestItem: any = {
            refineryId: element.refineryID,
            customerId: element.customerID,
            subUnitId: Number(element.key.split('_')[0]),
            unitId: element.unitID,
          };
          eachSelected.addedSubUnits.push(requestItem);
    });

    apiResponse(eachSelected);
  };

  const handleCtaClick = (event: any, items: any) => {
    if (event.target.innerText === 'Add to favorites') {
      if(isEdit === false) {
        if (items.length === 0) {
          return;
        }
      }
     
      if (isEdit) {
        editFavorite();
      } else {
        addFavorite();
      }
    }

    if (event.target.innerText === 'Cancel') {
      closeModal();
      setNodeList([]);
      setRemoveList([]);
    }
  };

  const apiResponse = (requestData: any) => {
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries/favourite`;
    apiRequest(encodeURI(apiUrl), requestData, APIEnum.POST)
      .then((result) => {
        if (result?.responseStaus?.status === 'SUCCESS') {
          setSuccessFlag(true);
          setMessage('Unit details saved successfully');
          cleaningData();
          if(isEdit) getFavoriteListData();
          setTimeout(() => {
            if (!isEdit) {
              navigate('/');
            }
          }, 1000);
      
        } else {
          setErrorFlag(true);
          result?.responseStaus?.errorResponse[0]?.message
            ? setMessage(result.responseStaus.errorResponse[0].message)
            : setMessage('Bad Request');

            setTimeout(() => {
              setErrorFlag(false);
              setMessage('');
              cleaningData("no-call")
             }, 2000);

        }
      
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage(error.message);
        cleaningData();
      });
  };

  const closeFavoriteModal = () => {
    closeModal();
    setNodeList([]);
    setRemoveList([]);
  };

  const handleToggleFavorite = (event: React.SyntheticEvent, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  // function to expand searched nodes
  const expandSearchedNodesFavorite = (items: any[], matchedItemsFavorite: any[]): any[] => {
    items.forEach((item) => {
      if (item.expanded) {
        !matchedItemsFavorite.includes(item.key) && matchedItemsFavorite.push(item.key);
      }
      if (item.children && item.children.length > 0) {
        let newItems = expandSearchedNodesFavorite(item.children, matchedItemsFavorite);
        if (newItems.length > 0) {
          !matchedItemsFavorite.includes(item.key) && matchedItemsFavorite.push(item.key);
        }
      }
    });
    setExpanded(matchedItemsFavorite);
    return matchedItemsFavorite;
  };

  useEffect(() => {
    //bind filtered tree data
    let sortedList = sortOrder(refineryTreeFromStore.data, 'name', 'asc');
    setFilteredData(sortedList);
    setExpanded([]);
    //set error and success flags to false in component unmount
    return () => {
      setSuccessFlag(false);
      setErrorFlag(false);
    };
  }, [props]);

  return (
    <div style={{ zIndex: 40000 }}>
      {isOpen ? (
        <BhModal
          isOpen={isOpen}
          header={header}
          ctas={ctaData}
          onClick={(event: any) => {
            handleCtaClick(event, nodeList);
          }}
          onBhEventClose={closeFavoriteModal}
          width="medium"
          data-testid="bh-favorite-unit"
          id="favorite-unit-modal"
        >
          <div>
            <BhTextInput
              placeholder="Search facility, unit"
              onBhEventInput={(event: any) => searchTreeFavorite(refineryTreeFromStore.data, event.detail, refineryTreeFromStore.data, setFilteredData)}
              onKeyUp={(event: any) => expandSearchedNodesFavorite(filteredData, [])}
              isFluid={true}
              isSmall={true}
              data-testid="search-items-click"
              type="text"
              id="txt-search"
            ></BhTextInput>

            <TreeView
              className="treeTest"
              multiSelect={true}
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon  />}
              defaultExpandIcon={<ChevronRightIcon  />}
              expanded={expanded}
              onNodeToggle={handleToggleFavorite}
              disableSelection 
            >
              {getTreeItemsFromData(filteredData)}
            </TreeView>

            {isSuccess ? (
              <Alert
                icon={<CheckCircleOutlineIcon />}
                severity="success"
                className="alert-box-success alert-message-changeunit"
                id="alert-message-changeunit"
              >
                {message}
              </Alert>
            ) : isError ? (
              <Alert
                icon={<SecurityIcon />}
                severity="error"
                className="alert-box-error alert-message-changeunit"
                id="alert-message-changeunit"
              >
                {message}
              </Alert>
            ) : (
              <Alert hidden id="nodisplay-alert"></Alert>
            )}
          </div>
        </BhModal>
      ) : (
        ''
      )}
    </div>
  );
}
