export const CRUDE_GRAVITY_MIN = '0.5';
export const CRUDE_GRAVITY_MAX = '50';
export const WASH1_PERCENTAGE_MIN = '3';
export const WASH1_PERCENTAGE_MAX = '9';
export const WASH1_PERCENTAGE_MIN1 = '.03';
export const WASH1_PERCENTAGE_MAX1 = '.09';
export const BRINE_RECYCLE_MIN = '0';
export const BRINE_RECYCLE_MAX = '50';
export const PERCENTAGE_ATMH2O_DESALTER_MIN_B = '0';
export const PERCENTAGE_ATMH2O_DESALTER_MAX_B = '100';
export const PERCENTAGE_ATMH2O_SWS_MIN_1B = '0';
export const PERCENTAGE_ATMH2O_SWS_MAX_1B = '100';
export const PERCENTAGE_SSW_DESALTER_MIN = '0';
export const PERCENTAGE_SSW_DESALTER_MAX = '100';
export const BRINE_PH_MIN = '3';
export const BRINE_PH_MAX = '11';
export const BRINE_SAMPLE_TEMP_MIN_F = '32';
export const BRINE_SAMPLE_TEMP_MAX_F = '320';
export const BRINE_SAMPLE_TEMP_MIN_C = '0';
export const BRINE_SAMPLE_TEMP_MAX_C = '160';
export const DESCRUDE_BSW_MIN = '0';
export const DESCRUDE_BSW_MAX = '5';
export const OH_TEMP_MIN_F = '0';
export const OH_TEMP_MAX_F = '600';
export const OH_TEMP_MIN_C = '0';
export const OH_TEMP_MAX_C = '316';
export const WASH_PROCESS_TEMP_MIN_F = '32';
export const WASH_PROCESS_TEMP_MAX_F = '600';
export const WASH_PROCESS_TEMP_MIN_C = '0';
export const WASH_PROCESS_TEMP_MAX_C = '316';
export const GRAVITY_MIN = '0.5';
export const GRAVITY_MAX = '100';
export const REFLUXPRODUCT_MIN = '0';
export const REFLUXPRODUCT_MAX = '200000.0';
export const PRODUCT1_MIN = '0.001';
export const PRODUCT1_MAX = '200000.0';
export const GRAVITY1_MIN = '0.5';
export const GRAVITY1_MAX = '110';
export const DISTILLATION0_MIN_F = '-459';
export const DISTILLATION0_MAX_F = '2000';
export const DISTILLATION0_MIN_C = '-273';
export const DISTILLATION0_MAX_C = '1100';
export const OFFGAS_MIN = '0';
export const OFFGAS_MAX = '100';
export const PH_MIN = '2';
export const PH_MAX = '10';
export const CL_MIN = '0';
export const CL_MAX = '10000';
export const NH3_MIN = '0';
export const NH3_MAX = '10000';
export const AMINE_PPM_MIN = '0';
export const AMINE_PPM_MAX = '1000';
export const STEAM_AMINE_PPM_MIN = '0';
export const STEAM_AMINE_PPM_MAX = '100';
export const SULFIDE_MIN = '0';
export const SULFIDE_MAX = '50';
export const COMBINED_REFLUX_FLOW_MIN = '0';
export const COMBINED_REFLUX_FLOW_MAX = '200000';
export const COMBINED_REFLUX_TEMPERATURE_MIN_C = '0';
export const COMBINED_REFLUX_TEMPERATURE_MAX_C = '200';

export const COMBINED_REFLUX_TEMPERATURE_MIN_F = '0';
export const COMBINED_REFLUX_TEMPERATURE_MAX_F = '392';

export const CHARGE_GRAVITY_MIN = '0.5';
export const CHARGE_GRAVITY_MAX = '50';
