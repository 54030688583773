import React, { useEffect, useState } from 'react';
import { BhIllustration, BhTitleWrapper } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './ConfigurationPreview.scss';

export default function ConfigurationPreview(props: any) {
  const onestageOverheadOneStageDeslater = 'One stage overhead  |  1 Stage desalter';
  const onestageOverheadTwoStageDeslater = 'One stage overhead  |  2 Stage desalter';
  const twostageOverheadOneStageDeslater = 'Two stage overhead  |  1 Stage desalter';
  const twostageOverheadTwoStageDeslater = 'Two stage overhead  |  2 Stage desalter';
  const onestageOverheadOneStage = 'One stage overhead';
  const twostageOverheadOneStage = 'Two stage overhead';
  const [subText, setSubText] = useState<string>('');
  const { type, idName, unitType } = props;

  useEffect(() => {
    if (type) {
      switch (type) {
        case '1A':
          (unitType === 'coker' || unitType === 'fcc') ? setSubText('') : setSubText(onestageOverheadOneStageDeslater);
          break;
        case '1B':
          setSubText(onestageOverheadTwoStageDeslater);
          break;
        case '2A':
          setSubText(twostageOverheadOneStageDeslater);
          break;
        case '2B':
          setSubText(twostageOverheadTwoStageDeslater);
          break;
        case '1C':
          setSubText(onestageOverheadOneStage);
          break;
        case '2C':
          setSubText(twostageOverheadOneStage);
          break;
      }
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="preview-subtitle">
        <BhTitleWrapper type="label" subtext={subText}></BhTitleWrapper>
      </div>
      {type === '1A' || type === '1B' || type === '1C' ? (
        <div data-testid="previewFor1A" className={unitType === 'coker' || unitType === 'fcc' ? 'mrg-left-50':''}>
          <BhIllustration id={idName} source={`${process.env.PUBLIC_URL}/assets/images//stage_1_illustration.svg`}></BhIllustration>
        </div>
      ) : (
        <div data-testid="previewFor2A">
          <BhIllustration id={idName} source={`${process.env.PUBLIC_URL}/assets/images//stage_2_illustration.svg`}></BhIllustration>
        </div>
      )}
    </React.Fragment>
  );
}
