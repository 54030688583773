/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index';
import './index.css';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import { applyPolyfills, defineCustomElements } from '@bh-digitalsolutions/ui-toolkit/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/Shared/ErrorHandler/ErrorFallback';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
     <Provider store={store}>
      <App />
     </Provider>
    </ErrorBoundary>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
applyPolyfills().then(() => {
  defineCustomElements();
});
