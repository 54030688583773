/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { BhDialog, BhTitleWrapper, BhDivider } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './SimulationReport.scss';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { APIEnum } from '../../constants/api-enum';
import apiRequest from '../../services/api-helper';
import { Breadcrumbs, Link, Stack, TablePaginationProps, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import { format } from 'date-fns-tz';
import { DataGrid, GridColDef, GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import { ionicInputFor2B } from '../Simulation/helper/InputForTwoB';
import { ionicInputFor2A } from '../Simulation/helper/InputForTwoA';
import { ionicInputFor1B } from '../Simulation/helper/InputForOneB';
import { ionicInputFor1A } from '../Simulation/helper/InputForOneA';
declare const window: any;

export default function SimulationReport() {
  const navigate = useNavigate();
  const [subText, setSubText] = useState('');
  const [simulationResult, setSimulationResult] = useState<any>({});
  const [simulationReportHeader, setSimulationReportHeader] = useState<string>('');
  const [simulationData, setSimulationData] = useState<any>({});
  const [dialogueMessage, setDialogueMessage] = useState('');
  const [dialogueHeader, setDialogueHeader] = useState('');
  const [dialogueOpen, setDialogueOpen] = useState(false);
    //coker
    const [unitType, setUnitType] = useState<string>('');
    const [isWithDesalter, setWithDesalter] = useState(false);
  const columns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      width: 195,
      headerClassName: 'grid-header',
      type: 'date',
      renderCell: (params) => renderDate(params.row),
      flex: 0,
    },
    {
      field: 'overheadTemp',
      headerName: 'Overhead temperature',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOverheadTemp(params.row),
      flex: 1,
    },
    {
      field: 'overheadFlashCalcTemp',
      headerName: 'Overhead Flash Calc temperature',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOverheadFlashCalcTemp(params.row),
      flex: 1,
    },
    {
      field: 'diff',
      headerName: 'Difference',
      width: 100,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderDiff(params.row),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderStatus(params.row),
      flex: 1,
    },
  ];

  useLayoutEffect(() => {
    setSubText(localStorage.getItem('subtitle')!);
    if (localStorage.getItem('isRunSimulationCalled') === null) {
      processSimulationResult();
      let apiInput = getSimulationApiInput();
      simulationApiCall(apiInput);
    }
    if (localStorage.getItem('isRunSimulationCalled') && localStorage.getItem('isRunSimulationCalled') === 'true') {
      let payLoadFromStorage = localStorage.getItem('payLoadRunSimulation');
      if (payLoadFromStorage) {
        processStorageSimulationResult(JSON.parse(payLoadFromStorage));
      }
    }
  }, []);

  const handleCloseDialogue = (event: any) => {
    setDialogueOpen(false);
  };

  const showDetails = (message: string, header: string) => {
    setDialogueMessage(message);
    setDialogueHeader(header);
    setDialogueOpen(true);
  };

  const renderOverheadFlashCalcTemp = (row: any) => {
    if (!row?.overheadFlashCalcTemp) {
      return '';
    } else {
      if (row.overheadFlashCalcTemp !== '--') {
        return row?.overheadFlashCalcTemp + ' °' + row.temp_UOM;
      }
    }
  };

  const renderDiff = (row: any) => {
    if (row.diff !== '--') {
      return row?.diff + ' °' + row.temp_UOM;
    }
  };
  const renderStatus = (row: any) => {
    if (row?.status === 'success') {
      return (
        <div className="text-link">
          <CheckCircleOutlineIcon style={{ color: '#548c69' }} fontSize="small" />
          <span className="text-margin typography--body-medium">Converging</span>
        </div>
      );
    } else if (row?.status === 'error') {
      return (
        <div data-testid="showDetails" className="text-link-pointer" onClick={() => showDetails(row?.message, row?.header)}>
          <WarningIcon style={{ color: '#cf2333' }} fontSize="small" />
          <span className="text-margin typography--body-medium">Error (click here for details)</span>
        </div>
      );
    } else if (row?.status === 'nonconverging') {
    let isConvergenceData: boolean = false;
    let key: any;
    if (row?.reportData && Object.keys(row?.reportData).length > 0) {
      key = Object.keys(row?.reportData)[0];
      isConvergenceData = row?.reportData[key].convergenceData ? true : false;
    }
    if(isConvergenceData && !(row?.reportData[key].convergenceData.isModelConverged))
      return (
        <div className="text-link">
          <ErrorIcon style={{ color: '#e87516' }} fontSize="small" />
          <span className="text-margin typography--body-medium">Non Converging</span>
        </div>
      );
    else
      return (
        <div className="text-link-pointer" onClick={() => showDetails(row?.message, row?.header)}>
          <ErrorIcon style={{ color: '#e87516' }} fontSize="small" />
          <span className="text-margin typography--body-medium">Non Converging (click here for details)</span>
        </div>
      );
    } else if (row?.status === 'inprogess') {
      return (
        <div className="text-link">
          <CircularProgress size="1rem" style={{ color: '#548c69' }} />
          <span className="text-margin typography--body-medium">In progress</span>
        </div>
      );
    }
  };

  const renderDate = (row: any) => {
    if (!row?.date) {
      return '';
    } else {
      if (row?.status === 'success' && row?.reportData && Object.keys(row?.reportData).length > 0) {
        var key: any = Object.keys(row?.reportData)[0];
        let isModelConverged = row?.reportData[key].convergenceData.isModelConverged;
        if (isModelConverged)
          return (
            <a className="date-link" onClick={() => redirectToReport(row)}>
              {format(new Date(row?.date), 'dd-MMM-yyyy')}
            </a>
          );
      } 
      else if (row?.status === 'nonconverging') {
        let isConvergenceData: boolean = false;
        let key: any;
        if (row?.reportData && Object.keys(row?.reportData).length > 0) {
          key = Object.keys(row?.reportData)[0];
          isConvergenceData = row?.reportData[key].convergenceData ? true : false;
        }
        if(isConvergenceData && !(row?.reportData[key].convergenceData.isModelConverged))
          return (
            <a className="date-link" onClick={() => redirectToReport(row)}>
            {format(new Date(row?.date), 'dd-MMM-yyyy')}
          </a>
          );
      }
      else return format(new Date(row?.date), 'dd-MMM-yyyy');
    }
  };

  const renderOverheadTemp = (row: any) => {
    if (!row?.overheadTemp) {
      return '';
    } else {
      if (row.overheadTemp !== '--') {
        return row?.overheadTemp + ' °' + row.temp_UOM;
      }
    }
  };

  const Pagination = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPagination = (props: any) => {
    return <GridPagination ActionsComponent={Pagination} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };

  const setExceptionData = (exception: any, ionicInput: any) => {
    //loop through successData
    let errorInfo: string = '';
    let errorHeader: string = '';
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    errorHeader = 'Exception';
    if (exception && exception.Exception) {
      errorInfo = exception.Exception;
    } else errorInfo = 'Exception occured';
    if (exception) {
      ionicInput.forEach((item: any) => {
        const dateFromInput = new Date(item.inputDate);
        payLoad.data.push({
          id: item.simulationId,
          message: errorInfo,
          header: errorHeader,
          date: dateFromInput,
          overheadTemp: '--',
          overheadFlashCalcTemp: '--',
          diff: '--',
          status: 'error',
        });
      });
      payLoad.length = ionicInput.length;
      localStorage.setItem('payLoadRunSimulation', JSON.stringify(payLoad));
      setSimulationResult(payLoad);
      let tableHeader = payLoad.data.length + ' Report(s)';
      setSimulationReportHeader(tableHeader);
    }
  };
  const setErrorData = (error: any, ionicInput: any) => {
    //loop through successData
    let errorHeader: string = '';
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    if (error && error.length) {
      errorHeader = error[0].description;
      ionicInput.forEach((item: any) => {
        let errorInfo: string = '';
        const dateFromInput = new Date(item.inputDate);
        error.forEach((errorItem: any) => {
          let counter = 1;
          if (errorItem.fields && errorItem.fields.length > 0) {
            for (let index = 0; index < errorItem.fields.length; index++) {
              let keyVal = Object.keys(errorItem.fields[index])[0]!;
              if (item.simulationId === errorItem[keyVal].simulationId) {
                errorInfo +=
                  counter +
                  '. ' +
                  errorItem.fields[index][item.inputDate] +
                  '                                                                                        ';
              }
            }
          }
          payLoad.data.push({
            id: item.simulationId,
            message: errorInfo,
            header: errorHeader,
            date: dateFromInput,
            overheadTemp: '--',
            overheadFlashCalcTemp: '--',
            diff: '--',
            status: 'error',
          });
        });
      });
      payLoad.length = ionicInput.length;
      localStorage.setItem('payLoadRunSimulation', JSON.stringify(payLoad));
      setSimulationResult(payLoad);
      let tableHeader = payLoad.data.length + ' Report(s)';
      setSimulationReportHeader(tableHeader);
    }
  };
  const setRejectedResult = (resultData: any, ionicInput: any) => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    if (resultData.rejectedData && resultData.rejectedData.length > 0) {
      let errorInfo: any = '';
      if (resultData.rejectedData && resultData.rejectedData.length > 0) {
        resultData.rejectedData.forEach((rejectedItem: any) => {
          let dataKey = Object.keys(rejectedItem)[0]!;
          ionicInput.forEach((item: any) => {
            //update converged result for that particular record
            const dateFromResult = new Date(Object.keys(rejectedItem)[0]!);
            if (item.simulationId === rejectedItem[dataKey].data.simulationId) {
              errorInfo = Object.values(rejectedItem)[0]!;
              if (
                errorInfo &&
                errorInfo.data &&
                errorInfo.data.convergenceData &&
                errorInfo.data.convergenceData.isModelConverged === false
              ) {
                payLoad.data.push({
                  reportData: errorInfo.data.convergenceData,
                  defaultUOM: errorInfo.data.defaultUOM,
                  id: item.simulationId,
                  message: errorInfo.errorReason,
                  header: '',
                  date: dateFromResult,
                  overheadTemp:
                    errorInfo.data.convergenceData &&
                    errorInfo.data.convergenceData.overheadTITemp &&
                    Math.round(errorInfo.data.convergenceData.overheadTITemp),
                  overheadFlashCalcTemp:
                    errorInfo.data.convergenceData &&
                    errorInfo.data.convergenceData.overheadFlashCalcTemp &&
                    Math.round(errorInfo.data.convergenceData.overheadFlashCalcTemp),
                  diff:
                    errorInfo.data.convergenceData &&
                    errorInfo.data.convergenceData.difference &&
                    Math.round(errorInfo.data.convergenceData.difference),
                  temp_UOM: errorInfo.data.defaultUOM && errorInfo.data.defaultUOM.temp_UOM,
                  status: 'nonconverging',
                });
              } else if (
                errorInfo &&
                errorInfo.data &&
                errorInfo.data.convergenceData &&
                errorInfo.data.convergenceData.isModelConverged === true
              ) {
                payLoad.data.push({
                  message: errorInfo.errorReason,
                  header: 'Error',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp: '--',
                  overheadFlashCalcTemp: '--',
                  diff: '--',
                  status: 'error',
                });
              } else if (
                errorInfo &&
                errorInfo.data &&
                errorInfo.data.errorData.length &&
                errorInfo.errorReason &&
                errorInfo.errorReason === 'not-started'
              ) {
                let errorDetails: string = '';
                let counter = 1;
                if (errorInfo.data.errorData.length > 0) {
                  for (let index = 0; index < errorInfo.data.errorData.length; index++) {
                    errorDetails +=
                      counter +
                      '. ' +
                      errorInfo.data.errorData[index] +
                      '                                                                                        ';
                  }
                }
                payLoad.data.push({
                  message: errorDetails,
                  header: 'Error',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp: '--',
                  overheadFlashCalcTemp: '--',
                  diff: '--',
                  status: 'error',
                });
              } else {
                payLoad.data.push({
                  message: errorInfo,
                  header: 'Error',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp: '--',
                  overheadFlashCalcTemp: '--',
                  diff: '--',
                  status: 'error',
                });
              }
            }
          });
        });
      }
    }
    payLoad.length = payLoad.data.length;
    localStorage.setItem('payLoadRunSimulation', JSON.stringify(payLoad));
    setSimulationResult(payLoad);
    let tableHeader = payLoad.data.length + ' Report(s)';
    setSimulationReportHeader(tableHeader);
  };
  const setSuccessRejectedResult = (resultData: any, ionicInput: any) => {
    //loop through successData
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    if (resultData.successData && resultData.successData.length > 0) {
      resultData.successData.forEach((successRejectItem: any) => {
        let dataKey = Object.keys(successRejectItem)[0]!;
        ionicInput.forEach((itemReject: any) => {
          //update converged result for that particulat record
          const dateFromResult = new Date(dataKey);
          if (itemReject.simulationId === successRejectItem[dataKey].simulationId) {
            if (successRejectItem[dataKey].convergenceData.isModelConverged) {
              payLoad.data.push({
                reportData: successRejectItem,
                defaultUOM: successRejectItem[dataKey].defaultUOM,
                inputData: itemReject,
                className: 'report-underline',
                id: itemReject.simulationId,
                date: dateFromResult,
                overheadTemp:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.overheadTITemp &&
                  Math.round(successRejectItem[dataKey].convergenceData.overheadTITemp),
                overheadFlashCalcTemp:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                  Math.round(successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp),
                diff:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.difference &&
                  Math.round(successRejectItem[dataKey].convergenceData.difference),
                temp_UOM: successRejectItem[dataKey].defaultUOM && successRejectItem[dataKey].defaultUOM.temp_UOM,
                status: 'success',
              });
            } else {
              if (!successRejectItem[dataKey].convergenceData.isModelConverged && Object.keys(successRejectItem[dataKey].corrosionRisk).length
            && Object.keys(successRejectItem[dataKey].dewPoint).length && Object.keys(successRejectItem[dataKey].overHeadLine).length && Object.keys(successRejectItem[dataKey].towerTop).length) {
                payLoad.data.push({
                  reportData: successRejectItem,
                  defaultUOM: successRejectItem[dataKey].defaultUOM,
                  inputData: itemReject,
                  className: 'report-underline',
                  id: itemReject.simulationId,
                  date: dateFromResult,
                  overheadTemp:
                  successRejectItem[dataKey].convergenceData &&
                  successRejectItem[dataKey].convergenceData.overheadTITemp &&
                    Math.round(successRejectItem[dataKey].convergenceData.overheadTITemp),
                  overheadFlashCalcTemp:
                  successRejectItem[dataKey].convergenceData &&
                  successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                    Math.round(successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp),
                  diff:
                  successRejectItem[dataKey].convergenceData &&
                  successRejectItem[dataKey].convergenceData.difference &&
                    Math.round(successRejectItem[dataKey].convergenceData.difference),
                  temp_UOM: successRejectItem[dataKey].defaultUOM && successRejectItem[dataKey].defaultUOM.temp_UOM,
                  status: 'nonconverging',
                });
              }
              else{
              payLoad.data.push({
                message: '',
                header: '',
                id: itemReject.simulationId,
                reportData: successRejectItem,
                defaultUOM: successRejectItem[dataKey].defaultUOM,
                date: dateFromResult,
                overheadTemp:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.overheadTITemp &&
                  Math.round(successRejectItem[dataKey].convergenceData.overheadTITemp),
                overheadFlashCalcTemp:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                  Math.round(successRejectItem[dataKey].convergenceData.overheadFlashCalcTemp),
                diff:
                successRejectItem[dataKey].convergenceData &&
                successRejectItem[dataKey].convergenceData.difference &&
                  Math.round(successRejectItem[dataKey].convergenceData.difference),
                temp_UOM: successRejectItem[dataKey].defaultUOM && successRejectItem[dataKey].defaultUOM.temp_UOM,
                status: 'nonconverging',
              });
             }
            }
          }
        });
      });
    }
    if (resultData.rejectedData && resultData.rejectedData.length > 0) {
      let errorInfoData: any = '';
      if (resultData.rejectedData && resultData.rejectedData.length > 0) {
        resultData.rejectedData.forEach((rejectedItem: any) => {
          let dataKey = Object.keys(rejectedItem)[0]!;
          ionicInput.forEach((item: any) => {
            //update converged result for that particular record
            const dateFromResult = new Date(dataKey);
            if (item.simulationId === rejectedItem[dataKey].data.simulationId) {
              errorInfoData = Object.values(rejectedItem)[0]!;
              if (
                errorInfoData &&
                errorInfoData.data &&
                errorInfoData.data.convergenceData &&
                errorInfoData.data.convergenceData.isModelConverged === false
              ) {
                payLoad.data.push({
                  message: errorInfoData.errorReason,
                  header: '',
                  reportData: errorInfoData.data.convergenceData,
                  defaultUOM: errorInfoData.data.defaultUOM,
                  className: 'typography--body-medium',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp:
                  errorInfoData.data.convergenceData &&
                  errorInfoData.data.convergenceData.overheadTITemp &&
                    Math.round(errorInfoData.data.convergenceData.overheadTITemp),
                  overheadFlashCalcTemp:
                  errorInfoData.data.convergenceData &&
                  errorInfoData.data.convergenceData.overheadFlashCalcTemp &&
                    Math.round(errorInfoData.data.convergenceData.overheadFlashCalcTemp),
                  diff:
                  errorInfoData.data.convergenceData &&
                  errorInfoData.data.convergenceData.difference &&
                    Math.round(errorInfoData.data.convergenceData.difference),
                  temp_UOM: errorInfoData.data.defaultUOM && errorInfoData.data.defaultUOM.temp_UOM,
                  status: 'nonconverging',
                });
              } else if (
                errorInfoData &&
                errorInfoData.data &&
                errorInfoData.data.convergenceData &&
                errorInfoData.data.convergenceData.isModelConverged === true
              ) {
                payLoad.data.push({
                  message: errorInfoData.errorReason,
                  header: 'Error',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp: '--',
                  overheadFlashCalcTemp: '--',
                  diff: '--',
                  status: 'error',
                });
              } else {
                let errorDetails: string = '';
                if (
                  errorInfoData &&
                  errorInfoData.data &&
                  errorInfoData.data.errorData.length &&
                  errorInfoData.errorReason &&
                  errorInfoData.errorReason === 'not-started'
                ) {
                  let counter = 1;
                  if (errorInfoData.data.errorData.length > 0) {
                    for (let index = 0; index < errorInfoData.data.errorData.length; index++) {
                      errorDetails +=
                        counter +
                        '. ' +
                        errorInfoData.data.errorData[index] +
                        '                                                                                        ';
                    }
                  }
                } else {
                  errorDetails = errorInfoData;
                }
                payLoad.data.push({
                  message: errorDetails,
                  header: 'Error',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp: '--',
                  overheadFlashCalcTemp: '--',
                  diff: '--',
                  status: 'error',
                });
              }
            }
          });
        });
      }
    }
    payLoad.length = payLoad.data.length;
    localStorage.setItem('payLoadRunSimulation', JSON.stringify(payLoad));
    setSimulationResult(payLoad);
    let tableHeader = payLoad.data.length + ' Report(s)';
    setSimulationReportHeader(tableHeader);
  };

  const setSuccessResult = (successData: any, ionicInput: any) => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    if (successData && successData.length > 0) {
      successData.forEach((successDataItem: any) => {
        let dataKey = Object.keys(successDataItem)[0]!;
        ionicInput.forEach((item: any) => {
          //update converged result for that particulat record
          const dateFromResult = new Date(dataKey);
          if (item.simulationId === successDataItem[dataKey].simulationId) {
            if (successDataItem[dataKey].convergenceData.isModelConverged) {
              payLoad.data.push({
                reportData: successDataItem,
                defaultUOM: successDataItem[dataKey].defaultUOM,
                inputData: item,
                className: 'report-underline',
                id: item.simulationId,
                date: dateFromResult,
                overheadTemp:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.overheadTITemp &&
                  Math.round(successDataItem[dataKey].convergenceData.overheadTITemp),
                overheadFlashCalcTemp:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                  Math.round(successDataItem[dataKey].convergenceData.overheadFlashCalcTemp),
                diff:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.difference &&
                  Math.round(successDataItem[dataKey].convergenceData.difference),
                temp_UOM: successDataItem[dataKey].defaultUOM && successDataItem[dataKey].defaultUOM.temp_UOM,
                status: 'success',
              });
            } else {
              if (!successDataItem[dataKey].convergenceData.isModelConverged && Object.keys(successDataItem[dataKey].corrosionRisk).length
            && Object.keys(successDataItem[dataKey].dewPoint).length && Object.keys(successDataItem[dataKey].overHeadLine).length && Object.keys(successDataItem[dataKey].towerTop).length) {
                payLoad.data.push({
                  reportData: successDataItem,
                  defaultUOM: successDataItem[dataKey].defaultUOM,
                  inputData: item,
                  className: 'report-underline',
                  id: item.simulationId,
                  date: dateFromResult,
                  overheadTemp:
                    successDataItem[dataKey].convergenceData &&
                    successDataItem[dataKey].convergenceData.overheadTITemp &&
                    Math.round(successDataItem[dataKey].convergenceData.overheadTITemp),
                  overheadFlashCalcTemp:
                    successDataItem[dataKey].convergenceData &&
                    successDataItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                    Math.round(successDataItem[dataKey].convergenceData.overheadFlashCalcTemp),
                  diff:
                    successDataItem[dataKey].convergenceData &&
                    successDataItem[dataKey].convergenceData.difference &&
                    Math.round(successDataItem[dataKey].convergenceData.difference),
                  temp_UOM: successDataItem[dataKey].defaultUOM && successDataItem[dataKey].defaultUOM.temp_UOM,
                  status: 'nonconverging',
                });
              }
              else{
              payLoad.data.push({
                message: '',
                header: '',
                id: item.simulationId,
                date: dateFromResult,
                overheadTemp:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.overheadTITemp &&
                  Math.round(successDataItem[dataKey].convergenceData.overheadTITemp),
                overheadFlashCalcTemp:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.overheadFlashCalcTemp &&
                  Math.round(successDataItem[dataKey].convergenceData.overheadFlashCalcTemp),
                diff:
                  successDataItem[dataKey].convergenceData &&
                  successDataItem[dataKey].convergenceData.difference &&
                  Math.round(successDataItem[dataKey].convergenceData.difference),
                temp_UOM: successDataItem[dataKey].defaultUOM && successDataItem[dataKey].defaultUOM.temp_UOM,
                status: 'nonconverging',
              });
            }
           }
          }
        });
      });
      payLoad.length = payLoad.data.length;
      localStorage.setItem('payLoadRunSimulation', JSON.stringify(payLoad));
      setSimulationResult(payLoad);
      let tableHeader = payLoad.data.length + ' Report(s)';
      setSimulationReportHeader(tableHeader);
    }
  };


  const getSimulationApiInput: any = () => {
    let simulationInput: any = { assetConfig: '', ionicInput: '' };
    let ionicInput: any = [];
    let assetConfigApiInput = {
      refineryID: '',
      refineryType: '',
      refineryName: '',
      unitID: '',
      unitType: '',
      unitName: '',
      subUnitId: '',
      subUnitType: '',
      subUnitName: '',
      configId: 0,
      customerName: '',
      customerId: '',
    };
    let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit.length > 0) {
        if (configFromStorage.refinery.unit[0]) {
          setUnitType(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
          if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
            setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false);
          }
          else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
            setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false);
          else setWithDesalter(false);
        }
        configFromStorage.refinery.unit.map((unitItem: any) => {
          if (unitItem.subUnit && unitItem.subUnit.length > 0) {
            unitItem.subUnit.map((subUnitItem: any) => {
              assetConfigApiInput.configId = subUnitItem.configId;
              assetConfigApiInput.subUnitName = subUnitItem.subUnitName;
              assetConfigApiInput.subUnitType = subUnitItem.typeSubunit;
              assetConfigApiInput.subUnitId = subUnitItem.subUnitId;
              assetConfigApiInput.unitName = unitItem.unitName;
              assetConfigApiInput.unitID = unitItem.unitId;
              assetConfigApiInput.unitType = unitItem.unitType;
              assetConfigApiInput.refineryName = configFromStorage.refinery.refineryName;
              assetConfigApiInput.refineryID = configFromStorage.refinery.refineryId;
              assetConfigApiInput.refineryType = configFromStorage.refinery.refineryType;
              assetConfigApiInput.customerName = configFromStorage.refinery.customerName;
              assetConfigApiInput.customerId = configFromStorage.refinery.customerId;
            });
          }
        });
      }
    }
    let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
    let configType = localStorage.getItem('configType')!;
    if (dataFromStorage && dataFromStorage.length) {
      dataFromStorage.forEach((element: any) => {
        //let formatedDate = changeToShortFormat(new Date(element.inputDate));
        switch (configType) {
          case '1A':
            let withDesalter;
            if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
              withDesalter = configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false;
            }
            else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
              withDesalter = configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false;
            else withDesalter = false;
            ionicInput.push(ionicInputFor1A(element.inputDate, element,configFromStorage.refinery.unit[0].unitType.trim().toLowerCase(), withDesalter, true, false, configType));
            break;
          case '1B':
            ionicInput.push(ionicInputFor1B(element.inputDate, element, true));
            break;
          case '2A':
            ionicInput.push(ionicInputFor2A(element.inputDate, element, true, false));
            break;
          case '2B':
            ionicInput.push(ionicInputFor2B(element.inputDate, element, true));
            break;
          case '1C':
            ionicInput.push(ionicInputFor1A(element.inputDate, element, unitType, isWithDesalter, true, true, configType));
            break;
          case '2C':
            ionicInput.push(ionicInputFor2A(element.inputDate, element, true, true));
            break;
        }
      });
    }
    simulationInput.assetConfig = assetConfigApiInput;
    simulationInput.ionicInput = ionicInput;
    return simulationInput;
  };
  const simulationApiCall: any = (simulationInput: any) => {
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/process`;
    apiRequest(encodeURI(apiUrl), simulationInput, APIEnum.POST, true, localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox':'Actual')
      .then((result: any) => {
        localStorage.setItem('isRunSimulationCalled', 'true');
        setSimulationData(result);
        //all cases are success
        if (result && result.status === 'success') {
          if (result.data && result.data.successData && result.data.successData.length > 0) {
            setSuccessResult(result.data.successData, simulationInput.ionicInput);
          }
        } else if (result && result.status === 'partial-success') {
          if (result.data && result.data.rejectedData && result.data.successData) {
            setSuccessRejectedResult(result.data, simulationInput.ionicInput);
          } else if (result.data && result.data.Exception) {
          }
        } else if (result && result.status === 'all-data-rejected') {
          setRejectedResult(result.data, simulationInput.ionicInput);
        } else if (result && result.errors && result.errors.length > 0) {
          setErrorData(result.errors, simulationInput.ionicInput);
        } else if (result && result.status === 'Exception') {
          setExceptionData(result.data, simulationInput.ionicInput);
        }
      })
      .catch((error: any) => {
        //setErrorData(error,simulationInput.ionicInput);
      })
      .finally(() => {
        localStorage.removeItem('simulationData');
      });
  };

  const processStorageSimulationResult = (payLoadFromStorage: any) => {
    if (payLoadFromStorage.data && payLoadFromStorage.data.length > 0) {
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoadFromStorage.data.forEach((element: any) => {
        payLoad.data.push({
          message: element.message,
          header: element.header,
          reportData: element.reportData,
          defaultUOM: element.defaultUOM,
          inputData: element.inputData,
          className: element.status.includes('check_circle') ? 'report-underline' : '',
          id: element.id,
          date: new Date(element.date),
          overheadTemp: element.overheadTemp,
          overheadFlashCalcTemp: element.overheadFlashCalcTemp,
          diff: element.diff,
          temp_UOM: element.temp_UOM,
          status: element.status,
        });
      });
      payLoad.length = payLoadFromStorage.data.length;
      setSimulationResult(payLoad);
      let tableHeader = payLoad.data.length + ' Report(s)';
      setSimulationReportHeader(tableHeader);
    }
  };
  const processSimulationResult = () => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
    let tableHeader = dataFromStorage && dataFromStorage.length + ' Report(s)';
    setSimulationReportHeader(tableHeader);
    if (dataFromStorage && dataFromStorage.length) {
      dataFromStorage.forEach((item: any) => {
        payLoad.data.push({
          id: item.simulationId,
          date: new Date(item.inputDate),
          overheadTemp: '--',
          overheadFlashCalcTemp: '--',
          diff: '--',
          status: 'inprogess',
        });
      });
      payLoad.length = dataFromStorage.length;
    }
    setSimulationResult(payLoad);
  };

  const redirectToReport = (item: any) => {
    localStorage.setItem('primaryReportData', JSON.stringify(item.reportData));
    localStorage.setItem('ionicInput', JSON.stringify(item.inputData));
    localStorage.setItem('pageName', 'Run Simulation');
    localStorage.setItem('defaultUOM', JSON.stringify(item.defaultUOM));
    if (item.reportData && Object.keys(item.reportData).length > 0) {
      var key: any = Object.keys(item.reportData)[0];
      let resultDataId = parseInt(item.reportData[key].resultDataId);
      localStorage.setItem('resultDataId', JSON.stringify(resultDataId));
    }
    navigate('/primary-report', {
      state: { reportData: item.reportData, ionicInput: item.inputData, pageName: 'Run Simulation', defaultUOM: item.defaultUOM, modeType: (localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') ? 'SandBox':'Actual' },
    });
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '20px', marginTop: '-17px', height: '30px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            position: 'absolute',
          }}
        >
          <Link color="inherit" href="/">
            <PublicIcon />
          </Link>
          <Link
            className="bread-crumb-font inactive-breadcrumb-label"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/new-simulation"
          >
            New Simulation{'    '}
          </Link>

          <Typography style={{ color: 'black', fontWeight: '500' }} className="bread-crumb-font">
            Simulation Report(s)
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ marginTop: '-15px' }}>
        <BhDivider></BhDivider>
      </div>

      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
        <div style={{ height: '50px' }}>
          <BhTitleWrapper data-testid="new-sim-title" type="subtitle" header="Simulation report(s)" subtext={subText}></BhTitleWrapper>
        </div>

        <div style={{ marginTop: '40px' }}>
          {Object.keys(simulationResult).length > 0 && simulationResult.data && simulationResult.data.length ? (
            <div className="simulation-table-width" data-testid="table-grid">
              <DataGrid
                pagination
                slots={{
                  pagination: CustomPagination,
                }}
                rows={simulationResult.data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
                pageSizeOptions={[5, 10, 15, 20, 25]}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                disableRowSelectionOnClick
                showCellVerticalBorder
                showColumnVerticalBorder
                rowHeight={44}
                columnHeaderHeight={42}
              />
            </div>
          ) : (
            <div style={{ height: 150, width: '100%' }}>
              <DataGrid
                pagination
                slots={{
                  pagination: CustomPagination,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                rows={[]}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
                pageSizeOptions={[5, 10, 15, 20, 25]}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                disableRowSelectionOnClick
                showCellVerticalBorder
                showColumnVerticalBorder
                rowHeight={44}
                columnHeaderHeight={42}
              />
            </div>
          )}
        </div>
      </div>

      <BhDialog
        id="errorDialog"
        isDismissible={true}
        isOpen={dialogueOpen}
        message={dialogueMessage}
        onBhEventClose={(event: any) => handleCloseDialogue(event)}
        header={dialogueHeader}
      ></BhDialog>
    </React.Fragment>
  );
}
