import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  navigatedUrl: '/',
};

const createNavigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    createNavigation(state, action) {
      state.navigatedUrl = action.payload;
    },
  },
});
export const createNavigationAction = createNavigationSlice.actions;
export default createNavigationSlice;
