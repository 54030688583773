/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { BhButtonTabs, BhDivider } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './SaltTemperatureGraph.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  mapSaltGraphData, setDewPointDiffGraphConfig, setDewPointGraphConfig, setTowerTopDiffGraphConfig, setTowerTopGraphConfig } from './helper';
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale);

export default function SaltTemperatureGraph(props: any) {
  const { defaultUOM, saltGraphApiResult, isCokerOrFcc } = props;
  const [towerTopData, setTowerTopData] = useState({ datasets: [] });
  const [towerTopDiffData, setTowerTopDiffData] = useState({ datasets: [] });
  const [dewPointData, setDewPointData] = useState({ datasets: [] });
  const [dewPointDiffData, setDewPointDiffData] = useState({ datasets: [] });
  const [towerTopOption, setTowerTopOption] = useState();
  const [towerTopDiffOption, setTowerTopDiffOption] = useState();
  const [dewPointOption, setDewPointOption] = useState();
  const [dewPointDiffOption, setDewPointDiffOption] = useState();
  const [showTowerTopGraph, setShowTowerTopGraph] = useState(true);
  const [showTowerTopDiffGraph, setShowTowerTopDiffGraph] = useState(false);
  const [showDewPointGraph, setShowDewPointGraph] = useState(true);
  const [showDewPointDiffGraph, setShowDewPointDiffGraph] = useState(false);
  const [corrosionHighRiskLine, setCorrosionHighRiskLine] = useState<any>({});
  const [corrosionLowRiskLine, setCorrosionLowRiskLine] = useState<any>({});
  const [corrosionLowRiskLineDewPoint, setCorrosionLowRiskLineDewPoint] = useState<any>({});
  const [corrosionHighRiskLineDewPoint, setCorrosionHighRiskLineDewPoint] = useState<any>({});
  ChartJS.defaults.font.size = 14;
  ChartJS.defaults.font.family = 'Poppins';
  const onButtonClick = (event: CustomEvent) => {
    if (event.detail.key === 'tower-top') {
      setShowTowerTopDiffGraph(false);
      setShowTowerTopGraph(true);
    } else if (event.detail.key === 'diff-towertop') {
      setShowTowerTopDiffGraph(true);
      setShowTowerTopGraph(false);
    } else if (event.detail.key === 'dew-point') {
      setShowDewPointGraph(true);
      setShowDewPointDiffGraph(false);
    } else {
      setShowDewPointGraph(false);
      setShowDewPointDiffGraph(true);
    }
  };




  useEffect(() => {
    setTowerTopGraphConfig(defaultUOM, setTowerTopOption);
    setTowerTopDiffGraphConfig(setTowerTopDiffOption, defaultUOM, setCorrosionLowRiskLine, setCorrosionHighRiskLine, null);
    setDewPointGraphConfig(defaultUOM, setDewPointOption);
    setDewPointDiffGraphConfig(setCorrosionHighRiskLineDewPoint, setCorrosionLowRiskLineDewPoint, defaultUOM, setDewPointDiffOption,  null);
    mapSaltGraphData(saltGraphApiResult, isCokerOrFcc, setTowerTopData, setTowerTopDiffData, setDewPointData, setDewPointDiffData, false);
  }, [props]);
  return (
    <React.Fragment>
      <Accordion elevation={0} classes={{}} className="report-outline" sx={{ marginTop: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
          <Typography className="primary-report-header">Salt temperature graphs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="heading-wrapper">
            <h5 className="salt-temp-heading">Salt Temperatures at Tower Top</h5>
            <div className="salt-temp-buttontabs">
              <BhButtonTabs
                items={[
                  { label: 'Towertop', key: 'tower-top' },
                  { label: 'ΔT with tower top', key: 'diff-towertop' },
                ]}
                menuWidth="fluid"
                onBhEventSelected={(event: any) => onButtonClick(event)}
              ></BhButtonTabs>
            </div>
          </div>
          <div className="salt-graph-container">
            {showTowerTopGraph && <Scatter id="tower-top" options={towerTopOption} data={towerTopData}></Scatter>}
            {showTowerTopDiffGraph && (
              <Scatter
                id="tower-top-diff"
                options={towerTopDiffOption}
                data={towerTopDiffData}
                plugins={[corrosionHighRiskLine, corrosionLowRiskLine]}
              ></Scatter>
            )}
          </div>
          <div>
            <BhDivider></BhDivider>
          </div>
          <div className="heading-wrapper">
            <h5 className="salt-temp-heading">Salt Temperature at water dew point</h5>
            <div className="dew-temp-buttontabs">
              <BhButtonTabs
                items={[
                  { label: 'Water dew point', key: 'dew-point' },
                  { label: 'ΔT  with water dew point', key: 'diff-dewpoint' },
                ]}
                menuWidth="fluid"
                onBhEventSelected={(event: any) => onButtonClick(event)}
              ></BhButtonTabs>
            </div>
          </div>
          <div className="dewpoint-graph-container">
            {showDewPointGraph && <Scatter id="dew-point" options={dewPointOption} data={dewPointData}></Scatter>}
            {showDewPointDiffGraph && (
              <Scatter
                id="diff-dewpoint"
                options={dewPointDiffOption}
                data={dewPointDiffData}
                plugins={[corrosionHighRiskLineDewPoint, corrosionLowRiskLineDewPoint]}
              ></Scatter>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
