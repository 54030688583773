import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: {},
};

const createRefineryResponseSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    createRefineryResponse(state, action) {
      state.data = action.payload;
    },
  },
});
export const createRefineryResponseAction = createRefineryResponseSlice.actions;
export default createRefineryResponseSlice;
