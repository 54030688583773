declare const window: any;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const clientId = `${window.extended?.IONIC_APP_CLIENT_ID}`;
const issuer = `${window.extended?.IONIC_APP_ISSUER}`;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: clientId,
    issuer: issuer,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:3000/api/messages',
  },
};
