/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import { Outlet } from 'react-router-dom';
import { createUserRoleAction } from '../../store/userrole-slice';
import { encryptData } from '../../utils/utils';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  const getUserRole = () => {
    let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/user/role`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result && result.status === 'success') {
          dispatch(createUserRoleAction.createUserRole(result));
          //encrypt user role object and save it in localstorage
          let encryptedData = encryptData(result);
          localStorage.setItem('salt', encryptedData);
        }
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage(error.message);
      });
  };

  if (authState?.isAuthenticated == true) {
    let idTokenVal = localStorage.getItem('okta-token-storage');
    let uIdVal, fNameVal, lNameVal, emailVal;
    if (idTokenVal) {
      sessionStorage.setItem('idToken', JSON.parse(idTokenVal).idToken.idToken);
      sessionStorage.setItem('expiresAtId', JSON.parse(idTokenVal).idToken.expiresAt);
      sessionStorage.setItem('Issuer', JSON.parse(idTokenVal).idToken.issuer);
      sessionStorage.setItem('firstName', JSON.parse(idTokenVal).idToken.claims.firstName);
      sessionStorage.setItem('lastName', JSON.parse(idTokenVal).idToken.claims.lastname);
      sessionStorage.setItem('emailId', JSON.parse(idTokenVal).idToken.claims.mail);

      fNameVal = sessionStorage.getItem('firstName');
      lNameVal = sessionStorage.getItem('lastName');
      emailVal = sessionStorage.getItem('emailId');
    }

    let accessTokenVal = localStorage.getItem('okta-token-storage');
    if (accessTokenVal) {
      sessionStorage.setItem('accessToken', JSON.parse(accessTokenVal).accessToken.accessToken);
      sessionStorage.setItem('userId', JSON.parse(accessTokenVal).accessToken.claims.uid);
      sessionStorage.setItem('clientId', JSON.parse(accessTokenVal).accessToken.claims.cid);
      sessionStorage.setItem('expiresAtAccess', JSON.parse(accessTokenVal).accessToken.expiresAt);

      uIdVal = sessionStorage.getItem('userId');
    }

    let isLogged = localStorage.getItem('userLogged');

    if (idTokenVal && accessTokenVal && isLogged === null) {
      localStorage.setItem('userLogged', true);
      let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/user`;
      apiRequest(
        encodeURI(apiUrl),
        {
          firstName: fNameVal,
          lastName: lNameVal,
          email: emailVal,
          createdBy: uIdVal,
          lastUpdatedBy: uIdVal,
          oktaUserId: uIdVal,
        },
        APIEnum.POST
      )
        .then((result) => {
          if (result && result.responseStatus && result.responseStatus.status === 'SUCCESS') {
            setSuccessFlag(true);
            setMessage('User details saved successfully');
            localStorage.setItem('found', true);
            if(result.data && result.data.length > 0) {
              localStorage.setItem('userId', result.data[0].userId);
            }
            getUserRole();
          } else if (result && result.responseStatus && result.responseStatus.status === 'NOT FOUND') {
            localStorage.setItem('found', false);
            dispatch(createUserRoleAction.createFlag(false));
          } else {
            dispatch(createUserRoleAction.createFlag(false));
            localStorage.setItem('found', false);
            setErrorFlag(true);
            result &&
            result.responseStatus &&
            result.responseStatus.errorResponse &&
            result.responseStatus.errorResponse[0] &&
            result.responseStatus.errorResponse[0].message
              ? setMessage(result.responseStatus.errorResponse[0].message)
              : setMessage('Bad Request');
          }
        })
        .catch((error) => {
          setErrorFlag(true);
          setMessage(error.message);
        });
    }
  }

  if (!authState || !authState?.isAuthenticated) {
    return <div>{''}</div>; // Error page
  }

  return <Outlet />;
};
