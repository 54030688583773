/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { useState, useEffect } from 'react';
import './AdminCenter.scss';
import {
  BhButton,
  BhModal,
  BhTitleWrapper,
  BhDivider,
  BhTooltip,
  BhAlertItem,
  BhTextArea,
  BhCard,
  BhSelectionGroup,
  BhRadioButton,
  BhFormMessage,
  BhDatePicker,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns-tz';
import ConfigurationPreview from '../Shared/ConfigurationPreview/ConfigurationPreview';
import { APIEnum } from '../../constants/api-enum';
import apiRequest from '../../services/api-helper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { changeToShortFormat, decryptData } from '../../utils/utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
declare const window: any;

export default function ViewRequest(props: any) {
  const { viewRequestPopupOpen, closeModal, selectedRequest, currentConfig, getChangeRequests, role } = props;
  const [viewCurrentPopupOpen, setViewCurrentPopupOpen] = useState<boolean>(false);
  const [viewMoreOpen, setViewMoreOpen]= useState<boolean>(false);
  const [ctaData, setCtaData] = useState<any>([]);
  const [ctaDataView, setCtaDataView] = useState<any>([]);
  const [modalHeader, setModalHeader] = useState('');
  const [requestedDate,setRequestedDate] = useState<string>('');
  const [requestDetails, setRequestDetails] = useState<any>([]);
  const [isSubmitEnable, setSubmitEnable] = useState<boolean>(false);
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [expiryComments, setExpiryComments] = useState<string>('');
  const [viewMoreLabel, setViewMoreLabel] = useState<string>('View more');
  const [isExpiryCommentError,setExpiryCommentError] = useState(false);
  const [isCommentError,setCommentError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [isProcessed, setProcessed] = useState(false);
  const [endDate, setEndDate] = useState<string>('');
  const [isInvalidEndDate, setInvalidEndDate] = useState(false);
  const [isEndDateBeyondYear, setEndDateBeyondYear] = useState(false);
  const [isRenewalApproved, setRenewalApproved] = useState('');
  const [isActionClicked, setActionClicked] = useState(false);


  useEffect(() => {
    let renewalText = selectedRequest.status === 'Pending for renewal' || selectedRequest.status === 'Renewal Approved' || selectedRequest.status === 'Renewal Rejected' || selectedRequest.status === 'Renewed' ? ', renewal':'';
    setModalHeader('Configuration change request ' + renewalText + '- '+ selectedRequest.customer + ', ' + selectedRequest.refinery + ', ' + selectedRequest.subunitName)
    setEndDate(selectedRequest.endDate);
    let ctaArray = [];
    ctaArray.push(
      {
        type: 'ghost',
        label: 'Cancel',
        key: 'btnRequestCancel',
      },
      { type: 'primary', label: 'Submit', key: 'btnDone', isDisabled: !enableSubmit }
    );
    setCtaData(ctaArray);
    setRequestedDate(format(new Date(selectedRequest.requestedDate), 'dd-MMM-yyyy HH:mm z').toString());
    let view = [];
    view.push(
      { type: 'primary', label: 'Back to request', key: 'backToRequest' }
    );
    setCtaDataView(view);
    //set enable flag for submit button
    selectedRequest.status === 'Approved' || selectedRequest.status === 'Rejected' || selectedRequest.status === 'Renewal Approved' || selectedRequest.status === 'Renewal Rejected' || selectedRequest.status === 'Renewed' || selectedRequest.status === 'Partially approved' || selectedRequest.status === 'Partially Approved' || selectedRequest.status === 'Expiring soon'? setSubmitEnable(false) : setSubmitEnable(true) ;
    setRequestDetailsObject(false,false);
    return () => {
      setRequestDetails([]);
    }
  }, [props]);

  const renderAction = (row: any) => {
    if (((row.approvalStatus === 'Expiring Soon' || row.approvalStatus === 'Pending' || row.approvalStatus === 'Renewal Requested') && role === 'account manager')) {
      return <div>Pending</div>;
    } else {
    return (
      <BhSelectionGroup layout="horizontal" className='pd-top' isDisabled={(row.approvalStatus === 'Approved' || row.approvalStatus === 'Rejected' || row.approvalStatus === 'Renewal Approved' || row.approvalStatus === 'Renewal Rejected'  || row.approvalStatus === 'Renewed' ||  row.approvalStatus === 'Expiring Soon') ? true : false}>
      <BhRadioButton
        label="Reject"
        value={row.approvalStatus === 'Rejected' ? 'true' : 'false'}
        name={row.id + 'action'}
        isChecked={row.approvalStatus === 'Rejected' ? true : false}
        onClick={(event: any) => setRadioButtonValue(event, row.id)}
      ></BhRadioButton>
      <BhRadioButton
        label="Approve"
        value={row.approvalStatus === 'Approved' || row.approvalStatus === 'Expiring Soon' ? 'true' : 'false'}
        name={row.id + 'action'}
        isChecked={row.approvalStatus === 'Approved' || row.approvalStatus === 'Expiring Soon' ? true : false}
        onClick={(event: any) => setRadioButtonValue(event, row.id)}
      ></BhRadioButton>
    </BhSelectionGroup>
    );
   }
  };

  const changeSubmitButtonVisibility =(isDisabled:boolean)=>{
    let btnDisable = [...ctaData];
    btnDisable.forEach((item) => {
      if (item.label === 'Submit') {
        item.isDisabled = isDisabled;
      }
    });
    setCtaData(btnDisable);
  }
  const setRadioButtonValue = (event: any, id:any) => {
    if (!event.currentTarget.isDisabled) {
    setActionClicked(true);
    setEnableSubmit(true);
    comments && changeSubmitButtonVisibility(false);
    changeApprovalStatus(event.currentTarget.label === 'Reject' ? false : true, id);
    }
  }
  const changeApprovalStatus = (isApprove:boolean, id:any) => {
    let requestDetailsTemp = requestDetails;
      let requestDetailItem = requestDetailsTemp.data.filter((item: any) => item.id === id)[0];
      requestDetailItem.approvalStatus = isApprove ? 'Approved': 'Rejected';
      setRequestDetails(requestDetailsTemp);
  }

  const setRequestDetailsObject =(isModify:boolean, isApprove:boolean)=>{
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    setComments(selectedRequest.approverComments);
    if(selectedRequest.crDetails && selectedRequest.crDetails.length){
      selectedRequest.crDetails.forEach((item:any) => {
        payLoad.data.push({
          id: item.itemId,
          section: item.section,
          parameter: item.parameter,
          currentValue: item.currentValue,
          requestedValue: item.requestedValue,
          ids: item.currentValue,
          approvalStatus:!isModify || item.approvalStatus ==='Approved' || item.approvalStatus ==='Rejected' ||item.approvalStatus ==='Expiring Soon'  ? item.approvalStatus :(isApprove ? 'Approved': 'Rejected'),
          field: item.field,
          requestedValueActual: item.requestedValueActual
          //actionN:''
        });
      });
      payLoad.length = selectedRequest.crDetails.length;
    }
    setRequestDetails(payLoad);
  }

  const checkDefaultConfigChanged =(configName:string)=>{
    if(selectedRequest.crDetails && selectedRequest.crDetails.length){
      const resObject = selectedRequest.crDetails.find((item:any) => item.field === configName);
      if(resObject){
         return true;
      }
      else return false;
    }
  }

  const changeRequestColumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'section',
      headerName: 'Section',
      width: 250,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      width: 250,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'currentValue',
      headerName: 'Current value',
      width: 250,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'requestedValue',
      headerName: 'Requested value',
      width: 250,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'actionN',
      headerName: ((selectedRequest.status === 'Pending' || selectedRequest.status === 'Renewal Requested') && role === 'account manager') ? 'Status':'Action',
      width: 150,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderAction(params.row),
      flex: 1,
    }
  ];

  const ViewCurrent = () => {
    setViewCurrentPopupOpen(true);
  };

  const ViewMore = (event:any) => {
    setViewMoreOpen(!viewMoreOpen);
    setViewMoreLabel(event.target?.innerText && event.target?.innerText === 'View more' ? 'View less': 'View more');
  };

  const submitApiCall = (e:any) => {
    setIsLoading(true);
    let today=format(new Date(),'dd-MMM-yyyy');
    if(role === 'account manager'){
      let apiPayLoad:any={
        changeRequestIds : [selectedRequest.changeRequestId],
        renewalComments : expiryComments,
        startDateRenewal : today,
        endDateRenewal : endDate,
        renewalStatus: 'Renewal Requested'
     }
      //api call
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/changerequest/renewal`;
    apiRequest(encodeURI(apiUrl), apiPayLoad, APIEnum.PUT)
    .then((result) => {
      if(result && result.responseStatus && result.responseStatus.status === 'SUCCESS'){
       setSuccessFlag(true);
       setMessage('Your renewal request has been submitted successfully!');
      }
      else if(result && result.responseStatus && result.responseStatus.status === 'ERROR'){
        setMessage(result.responseStatus.errorResponse[0].message);
        setErrorFlag(true);
      }
    })
    .catch((error) => {
      setErrorFlag(true);
      setMessage('Bad Request');
      setTimeout(() => {
        setErrorFlag(false);
      }, 4000);
    })
    .finally(() => {
      setTimeout(() => {
        closeModal(e);
        selectedRequest.status === 'Expiring Soon' ? getChangeRequests('ExpiringSoon'): getChangeRequests('Pending') ;
      }, 3000);
      setIsLoading(false);
    });
    }
    else if(role === 'admin' || role === 'sme'){
    let apiPayLoad:any;
    if(selectedRequest.status === 'Expiring Soon'){
      apiPayLoad={
        changeRequestId: selectedRequest.changeRequestId,
        subUnitId: selectedRequest.subunitId,
        approverComments: expiryComments,
        startDateRenewal: today,
        endDateRenewal:  endDate,
        renewalApprovalStatus: "Renewed"
    };
    }
    else if(selectedRequest.status === 'Pending for renewal'){
      apiPayLoad={
        changeRequestId: selectedRequest.changeRequestId,
        subUnitId: selectedRequest.subunitId,
        approverComments: expiryComments,
        startDateRenewal: selectedRequest.startDate,
        endDateRenewal:  endDate,
        renewalApprovalStatus: isRenewalApproved === 'true'? "Renewal Approved" : "Renewal Rejected"
      };
    }
    else{
      apiPayLoad ={
        changeRequestId: selectedRequest.changeRequestId,
        subUnitId: selectedRequest.subunitId,
        approverComments: comments
      };
      if(requestDetails && requestDetails.data && requestDetails.data.length){
        let crDetails:any=[];
        requestDetails.data.forEach((item:any) => {
          crDetails.push({
            itemId:item.id,
            approvalStatus:item.approvalStatus,
            field:item.field,
            requestedValueActual: item.requestedValueActual
          })
        });
        apiPayLoad.crDetails=crDetails;
      }
    }
    //api call
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/changerequest`;
    apiRequest(encodeURI(apiUrl), apiPayLoad, APIEnum.POST)
        .then((result) => {
          if(result && result.responseStatus && result.responseStatus.status === 'SUCCESS'){
           setSuccessFlag(true);
           result.data ? setMessage(result.data) :
           setMessage('You have approved the change request and updated the configuration in '+ selectedRequest.customer + ', '+ selectedRequest.refinery +'.');
          }
          else if(result && result.responseStatus && result.responseStatus.status === 'ERROR'){
           if(result.responseStatus.errorResponse && result.responseStatus.errorResponse.length){
            //case when someone already approved the request inbetween need to show alert in that scenario
            if(result.responseStatus.errorResponse[0] && result.responseStatus.errorResponse[0].errorCode === 'AlreadyProcessed'){
              setMessage('This change request is already processed by another user !');
              setProcessed(true);
            }
            else{
              setMessage(result.responseStatus.errorResponse[0].message);
              setErrorFlag(true);
            }
           }
          }
        })
        .catch((error) => {
          setErrorFlag(true);
          setMessage('Bad Request');
          setTimeout(() => {
            setErrorFlag(false);
          }, 4000);
        })
        .finally(() => {
          setTimeout(() => {
            closeModal(e);
            selectedRequest.status === 'Expiring Soon' ? getChangeRequests('ExpiringSoon'): getChangeRequests('Pending') ;
          }, 3000);
          setIsLoading(false);
        });
      }
  };

  const submitClick = (e: any) => {
    if(e.detail === 'btnDone'){
      if(!isInvalidEndDate  && !isEndDateBeyondYear)
        {
          if(role === 'account manager'){
            submitApiCall(e);
          }
          else if(role === 'admin' || role === 'sme'){
            if(isActionClicked && selectedRequest.status !== 'Expiring Soon')
            submitApiCall(e);
            else if(!isActionClicked && selectedRequest.status !== 'Expiring Soon') {
              setMessage('Please click Approve/Reject before submission!');
              setErrorFlag(true);
            }
            else{
              submitApiCall(e);
            }
          }
        } 
    }
    else if(e.detail === 'btnRequestCancel'){
      setInvalidEndDate(false);
      setEndDateBeyondYear(false);
      closeModal(e);
    }
  };

  const closeViewCurrent = (e: any) => {
    setInvalidEndDate(false);
    setEndDateBeyondYear(false);
    setViewCurrentPopupOpen(false);
  };

  const approveAll = (isRenewal:boolean) => {
    setActionClicked(true);
    setEnableSubmit(true);
    if(isRenewal) setRenewalApproved('true');
    else{
    setRequestDetailsObject(true,true);
    }
    comments && changeSubmitButtonVisibility(false);
  };

  const rejectAll = (isRenewal:boolean) => {
    setActionClicked(true);
    setEnableSubmit(true);
    if(isRenewal) setRenewalApproved('false');
    else{
    setRequestDetailsObject(true,false);
    }
    comments && changeSubmitButtonVisibility(false);
  }

  const setPreFlashValue = (preflashValue: string) => {
    let preflashConfigText = '';
    if(preflashValue){
    switch (preflashValue.toLowerCase()) {
      case 'no preflash':
        preflashConfigText = 'No Preflash';
        break;
      case 'preflash vapors feed atmospheric tower':
        preflashConfigText = 'Preflash vapors feed atmospheric tower';
        break;
      case 'preflash vapors feed atmospheric tower overhead line':
        preflashConfigText = 'Preflash vapors feed atmospheric tower overhead line';
        break;
      case 'preflash vapors leave crude unit':
        preflashConfigText = 'Preflash vapors leave crude unit';
        break;
      case 'prefractionation tower':
        preflashConfigText = 'Prefractionation tower';
        break;
    }
   }
    return preflashConfigText;
  };

  const setInjectionEquipment =(configValue:string) =>{
    let injectionEquipmentText = '';
    if (configValue === 'Spray nozzle oriented cocurrent')
    injectionEquipmentText = 'Spray nozzle oriented cocurrent';
    else if (configValue === 'Spray nozzle oriented countercurrent')
    injectionEquipmentText = 'Spray nozzle oriented countercurrent';
    else if (configValue === 'Other') injectionEquipmentText='Other';
    return injectionEquipmentText;
  }

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };
  
  const backToRequest = () => {
    setViewCurrentPopupOpen(false);
  };

  const decodeEscapeHtml =(text: string) =>{
     const textarea = document.createElement('textarea');
     textarea.innerHTML = text;
     return textarea.value;
  }

  const commentsChange = (e: CustomEvent) => {
    setComments(decodeEscapeHtml(e.detail));
    if(e.detail){
      enableSubmit && changeSubmitButtonVisibility(false);
      setCommentError(false);
    }
    else if(!e.detail) {
      changeSubmitButtonVisibility(true);
      setCommentError(true);
    }
  };

  const expiryCommentsChange = (e: CustomEvent) => {
    setExpiryComments(decodeEscapeHtml(e.detail));
    if(e.detail){
      changeSubmitButtonVisibility(false);
      setExpiryCommentError(false);
    }
    else if(!e.detail) {
      changeSubmitButtonVisibility(true);
      setExpiryCommentError(true);
    }
  };

  const endDateClick = (event: any) => {
    let endDate = changeToShortFormat(new Date(event.$d));
  if (endDate  && endDate !== '' && endDate !== 'aN-undefined-NaN') {
    //date validations start date shouldnt be past date, end date cannot be today's date, end date can be maximum upto 1 year
    let today= new Date();
    let endDateFormat= new Date(event.$d);
    let startDateFormat=new Date(selectedRequest.startDate);
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let oneYearAfter = new Date(year + 1, month, day);
    if((endDateFormat.setHours(0,0,0,0) < today.setHours(0,0,0,0)) || (endDateFormat.setHours(0,0,0,0) === startDateFormat.setHours(0,0,0,0))){
      setInvalidEndDate(true);
      setEndDateBeyondYear(false);
    }
    else if(endDateFormat.setHours(0,0,0,0) > oneYearAfter.setHours(0,0,0,0)){
      setInvalidEndDate(false);
      setEndDateBeyondYear(true);
    }
    else
    {
      setInvalidEndDate(false);
      setEndDateBeyondYear(false);
      setEndDate(endDate);
    } 
  }
  }

  return (
    <div>
      {viewRequestPopupOpen && (
        <BhModal
          onBhEventClose={(e) => closeModal(e)}
          isOpen={viewRequestPopupOpen}
          header={modalHeader}
          width="large"
          id="request-modal"
          ctas={ctaData}
          onBhEventCtaClick={(e) => submitClick(e)}
        >
          <div className="mainHead">
            <div className="mainHeadContent">
              <div className="normalSpaceDiv">
                <span className="typography--decorative-medium typography--color-secondary">Unit</span>
                <Typography className="viewRequestHead">{selectedRequest.unitName}</Typography>
              </div>
              <div className="largeSpaceDiv" style={{marginLeft:'-3px'}}>
                <span className="typography--decorative-medium typography--color-secondary">Sub unit</span>
                <Typography className="viewRequestHead">{selectedRequest.subunitName}</Typography>
              </div>
              <div className="largeSpaceDiv">
                <span className="typography--decorative-medium typography--color-secondary">Requested by</span>
                <Typography className="viewRequestHead">{selectedRequest.requestBy}</Typography>
              </div>
              <div className="normalSpaceDiv">
                <span className="typography--decorative-medium typography--color-secondary">Requested date</span>
                <Typography className="viewRequestHead">{requestedDate}</Typography>
              </div>

              <div className={selectedRequest.status === 'Expiring Soon' ? 'normalSpaceDiv date-margin-left' :'normalSpaceDiv'}>
                <span className="typography--decorative-medium typography--color-secondary">Start date</span>
                <Typography className="viewRequestHead">{selectedRequest.startDate}</Typography>
              </div>

              <div className="normalSpaceDiv">
                <span className="typography--decorative-medium typography--color-secondary">End date</span>
                {selectedRequest.status === 'Expiring Soon' ? 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(selectedRequest.endDate)}
                  onChange={(event: any) => endDateClick(event)}
                  className="end-date-pick"
                />
              </LocalizationProvider>
                :<Typography className="viewRequestHead">{selectedRequest.endDate}</Typography>}
                <BhFormMessage
                      data-testid="bh-error-end"
                      isError={isInvalidEndDate || isEndDateBeyondYear}
                      message={isInvalidEndDate ? 'End date should be future date'
                      : (isEndDateBeyondYear ? 'End date cannot go beyond one year': '')}
                      id="date-input-error"
                    ></BhFormMessage>
              </div>
            </div>
          </div>
          <div className="buttonArea">
            <div>
              <BhButton onClick={() => ViewCurrent()} label="View current configuration" type="secondary"></BhButton>
            </div>
            {(((selectedRequest.status === 'Approved' || selectedRequest.status.toLowerCase() === 'partially approved' || selectedRequest.status === 'Rejected' ) && role === 'account manager')) ||
            ((role === 'admin' || role === 'sme') && (selectedRequest.status !== 'Expiring Soon')) ?
            <div className="buttonRightSide">
             <div className="approveButton">
                <BhButton onClick={() => rejectAll(selectedRequest.status === 'Pending for renewal' ? true : false)} leftIcon="close" label={selectedRequest.status === 'Pending for renewal' ? 'Reject':'Reject all'} type="secondary" isDisabled={!isSubmitEnable}></BhButton>
              </div>
              <div>
                <BhButton onClick={() => approveAll(selectedRequest.status === 'Pending for renewal' ? true : false)} leftIcon="done" label={selectedRequest.status === 'Pending for renewal' ? 'Approve':'Approve all'} type="primary" isDisabled={!isSubmitEnable}></BhButton>
              </div>
            </div>
            : null}
          </div>
          <div className="tableArea" data-testid='view-request-grid'>
          {Object.keys(requestDetails).length > 0 && requestDetails.data && requestDetails.data.length ?
            (<DataGrid
              hideFooter
              rows={requestDetails.data}
              columns={changeRequestColumns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'section', sort: 'desc' }],
                },
              }}
              columnVisibilityModel={{ id: false}}
              disableColumnMenu
              disableRowSelectionOnClick
              showCellVerticalBorder
              showColumnVerticalBorder
              rowHeight={55}
              columnHeaderHeight={46}
            />):
            (
              <div style={{ height: 150, width: '100%' }} data-testid='view-request-grid'>
              <DataGrid
                hideFooter
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                rows={[]}
                columns={changeRequestColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                  sorting: {
                    sortModel: [{ field: 'section', sort: 'desc' }],
                  },
                }}
                columnVisibilityModel={{ id: false}}
                disableColumnMenu
                disableRowSelectionOnClick
                showCellVerticalBorder
                showColumnVerticalBorder
                rowHeight={44}
                columnHeaderHeight={42}
              />
            </div>
            )}
            {selectedRequest.status === 'Expiring Soon' || selectedRequest.status === 'Pending for renewal' || selectedRequest.status === 'Renewal Approved' || selectedRequest.status === 'Renewal Rejected' || selectedRequest.status === 'Renewed' ? (
               <div>
               {(selectedRequest.status === 'Expiring Soon' || selectedRequest.status === 'Pending for renewal') &&
               (<div style={{ display: 'flex', flexDirection: 'column', width: '80%', marginTop: '30px' }}>
                 <Typography>
                   Comments<span style={{ color: 'red' }}>*</span>
                 </Typography>
                 <BhTextArea id="expiry" onChanged={(e) => expiryCommentsChange(e)} value={expiryComments} isError={isExpiryCommentError} ></BhTextArea>
                 <BhFormMessage isError message={isExpiryCommentError ? 'This field is mandatory': ''} id="comment-error"></BhFormMessage>
               </div>)}
             <div style={{ display: 'flex', flexDirection: 'column', width: '80%', marginTop: '30px', marginLeft:'10%' }}>
               <Typography>
                   {selectedRequest.commentResponse && selectedRequest.commentResponse.length && selectedRequest.commentResponse[0].commentedBy}, {selectedRequest.commentResponse && selectedRequest.commentResponse.length && format(new Date(selectedRequest.commentResponse[0].commentedOn), 'dd-MMM-yyyy HH:mm z').toString()}
                 </Typography>
                 <BhTextArea id="expiryhistory" value={selectedRequest.commentResponse && selectedRequest.commentResponse.length && selectedRequest.commentResponse[0].comments} isDisabled></BhTextArea>   
               </div>
               {viewMoreOpen && 
               <div>
                {selectedRequest.commentResponse && selectedRequest.commentResponse.length && 
              selectedRequest.commentResponse.map(function (commentItem: any, index: number) {
                return (
                  index > 0 &&
                 <div style={{ display: 'flex', flexDirection: 'column', width: '80%', marginTop: '30px', marginLeft:'10%' }}>
               <Typography>
                  {commentItem.commentedBy}, {format(new Date(commentItem.commentedOn), 'dd-MMM-yyyy HH:mm z').toString()}
                 </Typography>
                 <BhTextArea id="expiryhistory" value={commentItem.comments} isDisabled></BhTextArea>   
               </div>
                );
                })}
                </div>}
               <Divider style={{paddingTop:'10px'}}>
                <BhButton label={viewMoreLabel} type='secondary' onClick={(event:any) => ViewMore(event)} id='btnViewMore'></BhButton>
               </Divider>
             </div>
             
            ) : (
                <div className={(((selectedRequest.status === 'Approved' || selectedRequest.status.toLowerCase() === 'partially approved' || selectedRequest.status === 'Rejected' ) && role === 'account manager')) ||
               (role === 'admin' || role === 'sme') ? 'coment-wrapper' :'mg-top-30'}>
               <div style={{ display: 'flex', flexDirection: 'column' }} className={(((selectedRequest.status === 'Approved' || selectedRequest.status.toLowerCase() === 'partially approved' || selectedRequest.status === 'Rejected' ) && role === 'account manager')) ||
               (role === 'admin' || role === 'sme') ? 'wd-48' :'wd-80'}>
                 <Typography>Requester Comments</Typography>
                 <BhTextArea id="request" value={selectedRequest.comments} isDisabled></BhTextArea>
               </div>
               {(((selectedRequest.status === 'Approved' || selectedRequest.status.toLowerCase() === 'partially approved' || selectedRequest.status === 'Rejected' ) && role === 'account manager')) ||
            (role === 'admin' || role === 'sme') ?
               <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                 <Typography>
                   Approver Comments<span style={{ color: 'red' }}>*</span>
                 </Typography>
                 <BhTextArea id="approver" onChanged={(e) => commentsChange(e)} value={comments} isDisabled={!isSubmitEnable} isError={isCommentError}></BhTextArea>
                 <BhFormMessage isError message={isCommentError ? 'This field is mandatory': ''} id="comment-error"></BhFormMessage>
               </div>
               :null}
             </div>           
            )}
           
          </div>
          <div style={{ height: '40px', width: '97.2%', position: 'absolute', bottom: '25%' }}>
              {isSuccess ? (
                <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success user-error" id="alert-message">
                  {message}
                </Alert>
              ) : isError ? (
                <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error user-error" id="alert-message">
                  {message}
                </Alert>
              ) : isProcessed ? (
                <Alert icon={<InfoOutlinedIcon />} severity="info" className="alert-box-info user-error" id="alert-message">
                  {message}
                </Alert>
              ) : (
                <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
              )}
            </div>
        </BhModal>
      )}
      {viewCurrentPopupOpen && (
        <BhModal
          ctas={ctaDataView}
          header={modalHeader}
          isOpen={viewCurrentPopupOpen}
          id="current-modal"
          onBhEventCtaClick={()=> backToRequest()}
          onBhEventClose={() => closeViewCurrent(false)}
        >
          <div>
          <BhCard class="card-wrapper">
            <BhTitleWrapper type="subtitle" header={selectedRequest.subunitName && selectedRequest.subunitName.toLowerCase() === 'atm tower overhead' ? 'Atmospheric Tower Overhead' : 
            (selectedRequest.subunitName.toLowerCase() === 'prefractionator overhead' ? 'Prefractionator Overhead' :(selectedRequest.subunitName.toLowerCase() === 'fractionator overhead' ? 'Fractionator Overhead' :
            (selectedRequest.subunitName.toLowerCase() === 'main fractionator overhead' ? 'Main Fractionator Overhead' :(selectedRequest.subunitName ? selectedRequest.subunitName : ''))))}></BhTitleWrapper>
            <BhDivider></BhDivider>
            <div style={{ display: 'flex', justifyContent: 'space-between', minHeight: '500px' }}>
              <div style={{ width: '50%', marginRight: '5px' }}>
                <div style={{ marginLeft: '20px' }}>
                  <BhTitleWrapper type="subtitle" header="Process and preflash"></BhTitleWrapper>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '30%',
                        flexDirection: 'column',
                        height: '50px',
                        alignContent: 'left',
                        justifyContent: 'left',
                      }}
                    >
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('numOvhdStages') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Overhead configuration
                        </span>
                      </div>
                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="One stage">
                            {currentConfig.numOvhdStages === 1 ? 'One Stage Overhead': 'Two Stage Overhead'}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '30%',
                        flexDirection: 'column',
                        height: '50px',
                        alignContent: 'left',
                        justifyContent: 'left',
                      }}
                    >
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('numDesalterStages') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Desalter
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {currentConfig.numDesalterStages === 1 ? 'One Stage Desalter': 'Two Stage Desalters'}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '30%',
                        flexDirection: 'column',
                        height: '50px',
                        alignContent: 'left',
                        justifyContent: 'left',
                      }}
                    >
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('preflashPrefractionator') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Preflash configuration
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="Preflash vapors leave crude unit">
                          {setPreFlashValue(currentConfig.preflashPrefractionator)}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>
                  </div>

                  <BhDivider></BhDivider>

                  <BhTitleWrapper type="subtitle" header="Velocity parameters"></BhTitleWrapper>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '30%',
                        flexDirection: 'column',
                        height: '50px',
                        alignContent: 'left',
                        justifyContent: 'left',
                      }}
                    >
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('idOvhdStream') || checkDefaultConfigChanged('idOvhdStreamUom') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Pipe inside diameter
                        </span>
                      </div>
                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                            {currentConfig.idOvhdStream + ' ' + currentConfig.idOvhdStreamUom}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '65%',
                        flexDirection: 'column',
                        height: '50px',
                        alignContent: 'left',
                        justifyContent: 'left',
                      }}
                    >
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('numBranchesOvhdStream') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Number of branches
                        </span>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          width: '30%',
                          flexDirection: 'column',
                          height: '50px',
                          alignContent: 'left',
                          justifyContent: 'left',
                        }}
                      >
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {currentConfig.numBranchesOvhdStream}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>
                  </div>

                  <BhDivider></BhDivider>

                  <BhTitleWrapper type="subtitle" header="Water wash"></BhTitleWrapper>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '90px' }}>
                    <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('waterWash') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Is water wash used?
                        </span>
                      </div>
                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                            {currentConfig.waterWash ? 'Yes':'No'}
                          </BhTooltip>
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('washFrequency') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Wash frequency:
                        </span>
                      </div>
                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {currentConfig.washFrequency}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div style={{ display: 'flex', width: '40%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('stageOvhdWashStream') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Wash injection point:
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {currentConfig.stageOvhdWashStream === '1' ? 'First Stage' : 'Second Stage'}
                          </BhTooltip>
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('equipmentOvhdWashStream') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Injection equipment used:
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {setInjectionEquipment(currentConfig.equipmentOvhdWashStream)}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ display: 'flex', width: '20%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}
                    ></div>
                  </div>

                  <BhDivider></BhDivider>

                  <BhTitleWrapper type="subtitle" header="Neutralizer"></BhTitleWrapper>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: ' 100%', height: '50px' }}>
                    <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('injectedNeutralizerStream') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Neutralizer injection point:
                        </span>
                      </div>
                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                            {currentConfig.injectedNeutralizerStream.toLowerCase() === 'with or after wash water' ? 'In or After Water Wash' : 'Before Water Wash'}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}>
                      <div>
                        <span slot="left--first" className={checkDefaultConfigChanged('stageOvhdNeutralizerStream') ? 'typography--decorative-small typography--color-secondary green' : 'typography--decorative-small typography--color-secondary'}>
                          Neutralizer injected at stage:
                        </span>
                      </div>

                      <div>
                        <span slot="left--first" className="typography--label-small typography--color-primary">
                          <BhTooltip isInline={true} placement="bottom" message="44">
                          {currentConfig.stageOvhdNeutralizerStream === '1' ? 'First Stage':'Second Stage'}
                          </BhTooltip>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{ display: 'flex', width: '30%', flexDirection: 'column', alignContent: 'left', justifyContent: 'left' }}
                    ></div>
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', marginLeft: '5px' }}>
            <BhCard class="preview-frame">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '75px',
                }}
              >
                <BhTitleWrapper type="label" header="Configuration preview"></BhTitleWrapper>
                <div>
                  {currentConfig.configType && (
                    <ConfigurationPreview type={currentConfig.configType} isFromConfig={false} idName="stage-preview"></ConfigurationPreview>
                  )}
                </div>
              </div>
            </BhCard>
          </div>
            </div>
          </BhCard>
          </div>
        </BhModal>
      )}
    </div>
  );
}
