/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { useLayoutEffect, useState } from 'react';
import { DataGrid, GridColDef, GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import './AdminCenter.scss';
import { renderStatus, requestApprovedBy, requestTakenBy } from './helper';
import { Card, CardHeader, CardContent, IconButton, TablePaginationProps, Stack, Backdrop, TextField, InputAdornment } from '@mui/material';
import ViewRequest from './ViewRequest';
import { APIEnum } from '../../constants/api-enum';
import apiRequest from '../../services/api-helper';
import MuiPagination from '@mui/material/Pagination';
import React from 'react';
import { BhSpinner } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { format } from 'date-fns-tz';
import SearchIcon from '@mui/icons-material/Search';
import { decryptData } from '../../utils/utils';
import DOMPurify from 'dompurify';
declare const window: any;

export default function RequestDetails(props: any) {
  const [viewRequestPopupOpen, setViewRequestPopupOpen] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [changeRequestData, setChangeRequestData] = useState<any>([]);
  const [changeRequestDataFromApi, setchangeRequestDataFromApi] = useState<any>([]);
  const [isError, setErrorFlag] = useState(false);
  const [paginationModelChangeRequest, setPaginationModelChangeRequest] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const { status } = props;
  const [searchedText, setSearchedText] = useState('');
  const [currentConfiguration, setCurrentConfiguration] = useState<any>({});
  const [role, setRole] = useState<any>({});

  const changeRequestColumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer',
      sortable: true,
      width: 200,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'refinery',
      headerName: 'Refinery',
      width: 200,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'requestBy',
      headerName: 'Requested by',
      width: 180,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => requestTakenBy(params.row),
      flex: 0,
    },
    {
      field: 'requestedDate',
      headerName: 'Requested date',
      width: 220,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderRequestedDate(params.row),
      flex: 0,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 145,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderStatus(params.row),
      flex: 0,
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry date',
      width: 130,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderExpiryDate(params.row),
      flex: 0,
    },
    {
      field: 'approve',
      headerName: 'Approved by',
      width: 180,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => requestApprovedBy(params.row),
      flex: 0,
    },
    {
      field: 'approvedDate',
      headerName: 'Approved date',
      width: 220,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => rendeApprovedDate(params.row),
      flex: 0,
    },
    {
      field: 'comments',
      headerName: 'Requester cmt.',
      width: 140,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'approverComments',
      headerName: 'Approver cmt.',
      width: 140,
      sortable: false,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderHorizontalMenu(params.row),
      flex: 1,
    },
  ];

  const decodeEscapeHtml =(text: string) =>{
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
 }

  const renderExpiryDate = (row: any) => {
    if (!row?.expiryDate) {
      return '';
    } else return format(new Date(row?.expiryDate), 'dd-MMM-yyyy');
  };

  const renderRequestedDate = (row: any) => {
    if (!row?.requestedDate) {
      return '';
    } else return format(new Date(row?.requestedDate), 'dd-MMM-yyyy HH:mm z');
  };
  const rendeApprovedDate = (row: any) => {
    if (!row?.approvedDate) {
      return '';
    } else return format(new Date(row?.approvedDate), 'dd-MMM-yyyy HH:mm z');
  };
  
  useLayoutEffect(() => {
    let decryptedRole: any;
    if (localStorage.getItem('salt') !== null) {
    let dataFromStorage = localStorage.getItem('salt')!;
    decryptedRole = decryptData(dataFromStorage);
    setRole(decryptedRole.userRole.toLowerCase());
    }
    getChangeRequests(status);
    return (()=>{
      setChangeRequestData([]);
      setchangeRequestDataFromApi([]);
      setSearchedText('');
      setPaginationModelChangeRequest({pageSize: 5,
        page: 0})
    });
  },[status]);
  
  const getChangeRequests = (status: string) => {
    setIsLoading(true);
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    let inc=0;
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/changerequestdetails?status=${status}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result) {
          if (result && result.configChangeRequests && result.configChangeRequests.length) {
            result.configChangeRequests.forEach((item: any) => {
              if(item.comments.length){
                item.comments.forEach((commentItem:any) => {
                  if(commentItem.comments){
                    commentItem.comments = decodeEscapeHtml(DOMPurify.sanitize(commentItem.comments))
                  }
                });
              }
              payLoad.data.push({
                id:inc++,
                changeRequestId: item.changeRequestId,
                customer: item.customerName,
                refinery: item.refineryName,
                requestBy: item.requestedBy,
                requestedDate: new Date(item.requestedDate),
                status: status === 'ExpiringSoon' ? item.expiryStatus : (status === 'Pending' && item.renewalStatus === 'Renewal Requested' ? 'Pending for renewal':(status === 'Completed' && item.requestType === 'Renewal Request' ? item.renewalStatus : item.approvalStatus)),
                comments: decodeEscapeHtml(DOMPurify.sanitize(item.requestorComments)),
                approve:item.approvedBy,
                approverComments: decodeEscapeHtml(DOMPurify.sanitize(item.approverComments)),
                approvedDate: status === 'Completed' && item.requestType === 'Renewal Request' && (item.renewalStatus === 'Renewed' || item.renewalStatus === 'Renewal Approved' || item.renewalStatus === 'Renewal Rejected') ? (item.renewedDate ? new Date(item.renewedDate): '') : (item.approvedDate ? new Date(item.approvedDate): ''),
                crDetails: item.crDetails,
                startDate:item.startDate,
                endDate:item.endDate,
                subunitId:item.subunitId,
                subunitName:item.subunitName,
                unitName:item.unitName,
                expiryDate:item.endDate,
                commentResponse:item.requestType === 'Renewal Request' || status === 'ExpiringSoon' ? item.comments :''
              });
            });
            payLoad.length = result.configChangeRequests.length;
          }
          else{
            setChangeRequestData([]);
            setchangeRequestDataFromApi([]);
          }
          setChangeRequestData(payLoad);   
          setchangeRequestDataFromApi(payLoad);  
        }
        else{
          setChangeRequestData([]);
          setchangeRequestDataFromApi([]);
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setChangeRequestData([]);
        setchangeRequestDataFromApi([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const viewRequestDetails = (row: any) => {
    row.comments = decodeEscapeHtml(row.comments);
    setSelectedRequest(row);
    getCurrentConfig(row.subunitId);
    setViewRequestPopupOpen(true);
  };

  const getCurrentConfig = (subUnitId:any) =>{
    //api to get refinery data and default configuration
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/config/${subUnitId}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET, true, 'Actual').then((data) => {
      if (data.responseStatus.status === 'SUCCESS' && data.defaultConfig && Object.keys( data.defaultConfig).length) {
      //need to update configurations in redux and in local storage
      let localStoreConfig:any={};
      localStoreConfig.preflashPrefractionator=data.defaultConfig.preflashPrefractionator;
      localStoreConfig.numDesalterStages = data.defaultConfig.numDesalterStages;
      localStoreConfig.waterWash = data.defaultConfig.waterWash;
      localStoreConfig.numOvhdStages = data.defaultConfig.numOvhdStages;
      localStoreConfig.idOvhdStream = data.defaultConfig.idOvhdStream;
      localStoreConfig.idOvhdStreamUom = data.defaultConfig.idOvhdStreamUom;
      localStoreConfig.numBranchesOvhdStream = data.defaultConfig.numBranchesOvhdStream;
      localStoreConfig.stageOvhdWashStream = data.defaultConfig.stageOvhdWashStream;
      localStoreConfig.equipmentOvhdWashStream = data.defaultConfig.equipmentOvhdWashStream;
      localStoreConfig.stageOvhdNeutralizerStream = data.defaultConfig.stageOvhdNeutralizerStream;
      localStoreConfig.injectedNeutralizerStream = data.defaultConfig.injectedNeutralizerStream;
      localStoreConfig.washFrequency = data.defaultConfig.washFrequency;
      localStoreConfig.configType = data.defaultConfig.configType;
      localStoreConfig.cokerType = data.defaultConfig.cokerType;
      localStoreConfig.fccType = data.defaultConfig.fccType;
      setCurrentConfiguration(localStoreConfig);
      }
    }).catch((error)=> {
      setErrorFlag(true);
      setMessage(error.message);
    });
  }

  const closeModal = (e:any) => {
    if(!e.detail) return;
    setSelectedRequest({});
    setViewRequestPopupOpen(false);
  };

  const PaginationForRequestDetails = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRefRequest = useGridApiContext();
    const pageCountRequest = useGridSelector(apiRefRequest, gridPageCountSelector);

    return (
      <MuiPagination
        className={className}
        count={pageCountRequest}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPaginationForRequestDetails = (props: any) => {
    return <GridPagination ActionsComponent={PaginationForRequestDetails} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlayForRequestDetails = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };
  
  const handleBackDropClose = () => {
    setIsLoading(false);
  };

  const requestSearch = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setSearchedText(event.target.value); 
      filterChangeRequestPayload(changeRequestDataFromApi, event.target.value);
    } else {
      setSearchedText('');
      setChangeRequestData(changeRequestDataFromApi);
    }
  };

  const filterChangeRequestPayload =(payLoadData:any, text:string)=>{
    let filteredRows = payLoadData.data.filter((row: any) => {
      return (
        row.customer.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.refinery.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.requestBy.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.status.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.comments?.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.approve?.toLowerCase().includes(text.trim().toLowerCase()) ||
        row.requestedDate.toString().toLowerCase().includes(text.trim().toLowerCase()) ||
        row.approvedDate?.toString().toLowerCase().includes(text.trim().toLowerCase()) ||
        row.approverComments?.toLowerCase().includes(text.trim().toLowerCase())
      );
    });
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    payLoad.data = filteredRows;
    payLoad.length = filteredRows.length;
    setChangeRequestData(payLoad);
  }

  const renderHorizontalMenu = (row: any) => {
    return (
      <div>
        <IconButton data-testid="view-request" aria-label="settings" onClick={() => viewRequestDetails(row)}>
          <RemoveRedEyeOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <div>
      <Card id="change-request-card">
        <CardHeader title="Change requests"
        action={
          <div className="user-action-container">
              <div className="user-action-wrapper search-wrapper tp-22">
                    <TextField
                      data-testid="txt-request-search-test"
                      id="txt-request-search"
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      value={searchedText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => requestSearch(event)}
                    />
                  </div>
          </div>
        }></CardHeader>
        <CardContent>
          <div style={{ height: changeRequestData?.data?.length ? 500 : 150, width: '100%'}} data-testid='change-request-grid'>
            <DataGrid
              pagination
              paginationModel={paginationModelChangeRequest}
              slots={{
                pagination: CustomPaginationForRequestDetails,
                noRowsOverlay: CustomNoRowsOverlayForRequestDetails,
              }}
              rows={changeRequestData?.data?.length ? changeRequestData?.data : []}
              columns={changeRequestColumns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'requestedDate', sort: 'desc' }],
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25]}
              onPaginationModelChange={setPaginationModelChangeRequest}
              columnVisibilityModel={{ id: false, approve:status === 'Completed' || (status === 'ExpiringSoon' && (role === 'sme' || role === 'admin')) ? true:false, approvedDate :status === 'Completed' || (status === 'ExpiringSoon' && (role === 'sme' || role === 'admin'))? true:false, approverComments:status === 'Completed' || (status === 'ExpiringSoon' && (role === 'sme' || role === 'admin'))? true:false, expiryDate:status === 'ExpiringSoon'? true:false }}
              disableColumnMenu
              disableRowSelectionOnClick
              showCellVerticalBorder
              showColumnVerticalBorder
              rowHeight={44}
              columnHeaderHeight={42}
            />
          </div>
        </CardContent>
      </Card>

      {viewRequestPopupOpen && (
        <ViewRequest selectedRequest={selectedRequest} viewRequestPopupOpen={viewRequestPopupOpen} closeModal={closeModal} currentConfig={currentConfiguration} getChangeRequests={getChangeRequests} role={role} />
      )}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
            open={isLoading}
            onClick={handleBackDropClose}
          >
            {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
          </Backdrop>
        </div>
    </div>
  );
}
