import { WASH_RATE_UNITS } from '../constants/report-constants';
import CryptoJS from 'crypto-js';
declare const window: any;

export const changeToShortFormat = (date: Date) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const day = ('0' + date.getDate()).slice(-2);

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day}-${monthName}-${year}`;
};

//Degree to Fahrenheit conversion
export const convertToFarenheit = (unit: any, value: any, isDiffTemp: boolean) => {
  if (unit === 'C') {
    var temp = isDiffTemp ? (value * 9) / 5 : (value * 9) / 5 + 32;
    return temp;
  } else {
    return value;
  }
};

export const getTempSign = (val: any) => {
  if (Math.sign(val) === 1) return '+';
};

export const encryptData = (data: any) => {
  let passPhrase = `${window.extended?.REACT_APP_PPHRASE}`;
  return CryptoJS.AES.encrypt(JSON.stringify(data), passPhrase).toString();
};

export const decryptData = (ciphertext: any) => {
  let passPhrase = `${window.extended?.REACT_APP_PPHRASE}`;
  const bytes = CryptoJS.AES.decrypt(ciphertext, passPhrase);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const sortWithKey = (data: any, key: string, sortValue: string) => {
  if (data?.[0]?.[key] === undefined) return data;
  data = JSON.parse(JSON.stringify(data));
  data.sort((a: any, b: any) => {
    if (sortValue === 'dsc') {
      [a, b] = [b, a];
    }
    if (Array.isArray(a[key])) {
      a = a[key][0]?.toString();
      b = b[key][0]?.toString();
    } else {
      a = a[key]?.toString();
      b = b[key]?.toString();
    }
    let hasAOnlyNumber = /^\d+$/.test(a);
    let hasBOnlyNumber = /^\d+$/.test(b);
    if (hasAOnlyNumber !== hasBOnlyNumber) {
      return hasAOnlyNumber ? -1 : 1;
    }
    return a.localeCompare(b, 'en', { numeric: true });
  });
  return data;
};

//velocity conversion to ft/s
export const convertVelocityToFeetPerSecond = (velocity: any) => {
  return velocity * 3.2808;
};

//get subunit name
export const getSubUnitName = (subUnitType:any) =>{
  let subUnitName;
  switch(subUnitType){
    case 'atm tower overhead':
      subUnitName = 'Atmospheric Tower Overhead';
      break;
    case 'prefractionator overhead':
      subUnitName = 'Prefractionator Overhead';
      break;
    case 'fractionator overhead':
      subUnitName = 'Fractionator Overhead';
      break;
    case 'main fractionator overhead':
      subUnitName = 'Main Fractionator Overhead';
      break;
    default:
      subUnitName = subUnitType;
      break;
  }
  return subUnitName;
}
//get unit name
export const getUnitName = (unitType:any) =>{
  let unitName;
  switch(unitType){
    case 'crude':
      unitName = 'Crude Unit';
      break;
    case 'coker':
      unitName = 'Coker';
      break;
    case 'fcc':
      unitName = 'FCC';
      break;
    default:
      unitName = unitType;
      break;
  }
  return unitName;
}
