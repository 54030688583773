import { useEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import { Provider } from 'react-redux';
import store from '../../store/index';
import { useNavigate, useLocation  } from 'react-router-dom';
import config from '../../config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { createNavigationAction } from '../../store/navigation-slice';
import Layout from '../../components/Layout';

function App() {
  const oktaAuth = new OktaAuth(config.oidc);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string | undefined) => {
    navigate(toRelativeUrl(originalUri, window.location.origin));
  };

  useEffect(() => {
    if (location.pathname) {
       let path : any = location.pathname;
       let slashRemove  = "";
       if(path === '/') {
         slashRemove = path;
       }
       else {
         slashRemove = path.substr(1).toLowerCase();
       }
       dispatch(createNavigationAction.createNavigation(slashRemove));
    }
  }, []);

  return (
    <React.Fragment>
      <Provider store={store}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Layout />
        </Security>
      </Provider>
    </React.Fragment>
  );
}

export default App;
