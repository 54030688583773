/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import {
  BhButtonTabs,
  BhTabs,
  BhDateRangePicker,
  BhTitleWrapper,
  BhButton,
  BhSpinner,
  BhDialog,
  BhDivider,
  BhTextArea,
  BhTextInput,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { DataGrid, GridColDef, GridPagination } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useLayoutEffect, useState } from 'react';
import './NewSimulation.scss';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import { format } from 'date-fns-tz';
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TablePaginationProps,
} from '@mui/material';
import DraftListing from './DraftListing';
import { changeToShortFormat, decryptData } from '../../utils/utils';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import CommentIcon from '@mui/icons-material/Comment';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { BootstrapDialog, BootstrapDialogTitle } from '../Dashboard/helper';
declare const window: any;


export default function AllSimulations() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabModelStatus, setTabModelStatus] = useState<string>('All');
  const [isHistoricTableOpen, setHistoricTableOpen] = useState(true);
  const [isSandboxTableOpen, setSandboxTableOpen] = useState(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [isDateClear, setDateClear] = useState<boolean>(false);
  const [isDatePikcerActive, setDatePickerActive] = useState(false);
  const [tabStatus, setTabStatus] = useState<string>('All');
  const [tabStatusKey, setTabStatusKey] = useState<string>('all');
  const navigate = useNavigate();
  const [subText, setSubText] = useState('');
  const [message, setMessage] = useState('');
  const [dialogueMessage, setDialogueMessage] = useState('');
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [isDatesGiven, setDatesGiven] = useState(false);
  const [totalPages, setTotalPages] = useState<number>();
  const [totalRecords, setTotalRecords] = useState<number>();
  const [simulationData, setSimulationData] = useState<any>([]);
  const [resPayload, setPayload] = useState<any>([]);
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortState, setSortState] = useState({
    sortBy: 'executedOn',
    orderBy: 'desc',
  });
  const [submitClickCounter, setSubmitClickCounter] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteDialogueOpen, setisDeleteDialogueOpen] = useState<boolean>(false);
  const [isDeleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [isHideVisible, setHideVisible] = useState<boolean>(false);
  const [resultDataId, setResultDataId] = useState<number>();
  const [exportButtonLoading, setExportButtonLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('All');
  const open = Boolean(anchorEl);

  const [simulationName, setSimulationName] = useState('');
  const [comments, setComments] = useState('');
  const [isSimulationModalOpen,setSimulationModalOpen] = useState(false);

  const ALL_SIM_PAGE_ROW_WIDTH = 110;
  const ALL_SIM_PAGE_ROW_CLASS = 'grid-header';
  const ALL_SIM_PAGE_ROW_WIDTH_LONG = 120;
  const ALL_SIM_FLEX_ZERO = 0;


  const allsimcolumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: ALL_SIM_PAGE_ROW_CLASS, flex: 1 },
    {
      field: 'inputDate',
      headerName: 'Date',
      width: ALL_SIM_PAGE_ROW_WIDTH,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      type: 'date',
      renderCell: (params) => renderDate(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'overheadTITemp',
      headerName: 'Overhead temperature',
      width: ALL_SIM_PAGE_ROW_WIDTH_LONG,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderOverheadTemp(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'overheadFlashCalcTemp',
      headerName: 'Overhead Flash Calc temperature',
      width: ALL_SIM_PAGE_ROW_WIDTH_LONG,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderOverheadFlashCalcTemp(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'difference',
      headerName: 'Difference',
      width: 90,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderDiff(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'statusText',
      headerName: 'Status',
      width: 190,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderStatus(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 195,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderCreatedBy(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'executedOn',
      headerName: 'Created On',
      width: 225,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderCreatedOn(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'uviewStatus',
      headerName: 'uView Status',
      width: ALL_SIM_PAGE_ROW_WIDTH_LONG,
      sortable: true,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderUView(params.row),
      flex: ALL_SIM_FLEX_ZERO,
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      headerClassName: ALL_SIM_PAGE_ROW_CLASS,
      renderCell: (params) => renderHorizontalMenu(params.row),
      flex: 1,
    },
  ];

  const allsandboxsimcolumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'inputDate',
      headerName: 'Date',
      width: 110,
      headerClassName: 'grid-header',
      type: 'date',
      renderCell: (params) => renderDate(params.row),
      flex: 0,
    },
    {
      field: 'simName',
      headerName: 'Simulation Name',
      width: 130,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderSimName(params.row),
      flex: 0,
    },
    {
      field: 'overheadTITemp',
      headerName: 'Overhead temperature',
      width: 110,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOverheadTemp(params.row),
      flex: 0,
    },
    {
      field: 'overheadFlashCalcTemp',
      headerName: 'Overhead Flash Calc temperature',
      width: 110,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderOverheadFlashCalcTemp(params.row),
      flex: 0,
    },
    {
      field: 'difference',
      headerName: 'Difference',
      width: 90,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderDiff(params.row),
      flex: 0,
    },
    {
      field: 'statusText',
      headerName: 'Status',
      width: 190,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderStatus(params.row),
      flex: 0,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 195,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderCreatedBy(params.row),
      flex: 0,
    },
    {
      field: 'executedOn',
      headerName: 'Created On',
      width: 225,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderCreatedOn(params.row),
      flex: 0,
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderHorizontalMenu(params.row),
      flex: 1,
    },
  ];

  const getHistoryData = (postApiPayLoad: any) => {
    setIsLoading(true);
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulations/datahistory`;
    apiRequest(encodeURI(apiUrl), postApiPayLoad, APIEnum.POST)
      .then((result) => {
        if (result) {
          //history table data api call to get updated config
          if (result && result.data && result.data.length) {
            result.data.forEach((item: any) => {
              let primaryReport: any = {};
              primaryReport[item.result.inputDate] = item.result;
              const configData = {
                refinery: {
                  refineryId: item.inputConfigDetails.refineryId,
                  refineryName: item.inputConfigDetails.refineryName,
                  refineryType: item.inputConfigDetails.refineryType,
                  customerName: item.inputConfigDetails.customerName,
                  customerId: item.inputConfigDetails.customerId,
                  unit: [
                    {
                      refineryId: item.inputConfigDetails.refineryId,
                      unitId: item.inputConfigDetails.unitId,
                      unitName: item.inputConfigDetails.unitName,
                      unitType: item.inputConfigDetails.unitType,
                      preflashPrefractionator: item.inputConfigDetails.preflash,
                      subUnit: [
                        {
                          unitId: item.inputConfigDetails.unitId,
                          subUnitId: item.inputConfigDetails.subUnitId,
                          configId: item.inputConfigDetails.configId,
                          configType: item.inputConfigDetails.configType,
                          cokerType:item.inputConfigDetails.cokerType,
                          fccType:item.inputConfigDetails.fccType,
                          subUnitName: item.inputConfigDetails.subUnitName,
                          subUnitType: item.inputConfigDetails.subUnitType,
                          numOvhdStages: item.inputConfigDetails.numOvhdStages,
                          numDesalterStages: item.inputConfigDetails.numDesalterStages,
                          idOvhdStream: item.inputConfigDetails.pipeDiameter,
                          numBranchesOvhdStream: item.inputConfigDetails.pipeBranches,
                          velocityUnit: item.inputConfigDetails.velocityUnit,
                          waterWash: item.inputConfigDetails.waterWashUsed,
                          equipmentOvhdWashStream: item.inputConfigDetails.waterWashInjectionEquipment,
                          injectedNeutralizerStream: item.inputConfigDetails.nuetralizerInjectionOrder,
                          washFrequency: item.inputConfigDetails.washFrequency,
                          stageOvhdNeutralizerStream: item.inputConfigDetails.nuetralizerInjectionStage,
                          stageOvhdWashStream: item.inputConfigDetails.waterWashInjectionStage,
                        },
                      ],
                    },
                  ],
                },
              };
              let convergeStatus;
              if (item.result.status === 'success' && item.result.convergenceData && item.result.convergenceData.isModelConverged) {
                convergeStatus = 'Converging';
              }
              else if(item.result.status === 'success' && item.result.convergenceData && !item.result.convergenceData.isModelConverged){
                convergeStatus = 'Non-Converging';
              }
               else if (item.result.status === 'error') {
                if (item.result.convergenceData) {
                  if (!item.result.convergenceData.isModelConverged) {
                    convergeStatus = 'Non-Converging';
                  } else {
                    convergeStatus = 'Error';
                  }
                } else {
                  convergeStatus = 'Error';
                }
              } else if (item.result.status === 'not-started') {
                convergeStatus = 'Error';
              }
              payLoad.data.push({
                id: item.result.simulationId,
                reportData: primaryReport,
                defaultUOM: item.result.defaultUOM,
                configDetails: configData,
                inputDate: new Date(item.result.inputDate),
                overheadTITemp:
                  item.result.convergenceData && item.result.convergenceData.overheadTITemp
                    ? Math.round(item.result.convergenceData.overheadTITemp)
                    : '--',
                overheadFlashCalcTemp:
                  item.result.convergenceData && item.result.convergenceData.overheadFlashCalcTemp
                    ? Math.round(item.result.convergenceData.overheadFlashCalcTemp)
                    : '--',
                difference:
                  item.result.convergenceData && item.result.convergenceData.difference
                    ? Math.round(item.result.convergenceData.difference)
                    : '--',
                isModelConverged: convergeStatus,
                statusText: convergeStatus,
                status: item.result.status,
                executedOn: new Date(item.result.executedOn),
                createdBy: item.result && item.result.createdBy ? item.result.createdBy : '',
                temp_UOM: item.result.defaultUOM && item.result.defaultUOM.temp_UOM,
                isHide: item.result.isHide,
                uviewStatus: item.uviewStatus ? item.uviewStatus : '',
                simName:item.inputConfigDetails.sandBoxSimulationName,
                comments:item.inputConfigDetails.sandBoxSimulationComments,
                modeType:item.inputConfigDetails.modeType
              });
            });
            //payLoad.length = result.data.length;
            if (result && result.metaData && Object.keys(result.metaData).length) {
              setTotalPages(result.metaData.totalPages);
              setTotalRecords(result.metaData.totalRecords);
              payLoad.length = result.metaData.totalPages;
            }
          }
        } else {
          setTotalPages(0);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        setMessage(error.message);
        setTotalPages(0);
        setTotalRecords(0);
      })
      .finally(() => {
        setPayload(payLoad);
        setSimulationData(payLoad);
        setIsLoading(false);
      });
  };

  const selectedTabClick = (event: CustomEvent) => {
    setSelectedTab('All');
    setTabStatusKey('all');
    setSimulationData([]);
    //reset has issue if reset set to true, then it will never show dates
    setPageState((old) => ({ ...old, page: 1, pageSize: 10 }));
    setDatesGiven(false);
    setDateClear(true);
    setTimeout(() => {
      setDateClear(false);
    }, 100);
    setStartDate('');
    setEndDate('');
    // Track which item has been selected
    if (event.detail.key == 'all') {
      setTabModelStatus('All');
      setTabStatus('All');
      setHistoricTableOpen(true);
      setSandboxTableOpen(false);
      let refineryObj: any = {};
    let postApiPayLoad: any;
    refineryObj = localStorage.getItem('defaultConfig');
      postApiPayLoad = {
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        status:'All',
        pageNumber: 1,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType :'Actual'
      };
    getHistoryData(postApiPayLoad);
    } else {
      setHistoricTableOpen(false);
      if(event.detail.key == 'sandbox'){
        setSandboxTableOpen(true);
        setTabModelStatus('Sandbox');
        setTabStatus('All');
        setSelectedTab('All');
        setTabStatusKey('all');
      }
      else{
      setSandboxTableOpen(false);
      setHistoricTableOpen(false);
      setTabModelStatus('Draft');
      }
    }
  };

  const exportButtonClick = async () => {
    setExportButtonLoading(true);
    let refineryObj: any = {};
    refineryObj = localStorage.getItem('defaultConfig');
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulations/exportdatahistory`;
    let requestPayload = {
      subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
      startDate: !startDate ? null : startDate,
      endDate: !endDate ? null : endDate,
      status: selectedTab,
      modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
    };
    let headerObject: any = {
      body: JSON.stringify(requestPayload),
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.accessToken?.accessToken}`,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    };

    await fetch(apiUrl, headerObject)
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            const filename = response.headers.get('Content-Disposition')!.split('filename=')[1];
            response.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = filename;
              a.click();
            });
          }
          if (response.status === 406) {
            setErrorFlag(true);
            setMessage('Row limit exceeded. Please select a shorter range.');
            setTimeout(() => {
              setErrorFlag(false);
            }, 4000);
          }
        } else {
          setErrorFlag(true);
          setMessage('Export failed');
          setTimeout(() => {
            setErrorFlag(false);
          }, 4000);
        }
        setExportButtonLoading(false);
      })
      .catch((error) => {
        setExportButtonLoading(false);
        setErrorFlag(true);
        setMessage('Export failed');
        setTimeout(() => {
          setErrorFlag(false);
        }, 4000);
      });
  };

  const handleDialogueClose = (event: CustomEvent) => {
    setisDeleteDialogueOpen(!isDeleteDialogueOpen);
  };

  const deleteHideApiCall = (resultDataId: any, isDelete: boolean, isHide: boolean, isUnHide: boolean) => {
    const resultDataIdArray = [];
    let subUnitId;
    let resultDataIds: any;
    let apiUrl: any;
    let apiHideStatus: boolean = false;
    resultDataIdArray.push(resultDataId);
    let refineryObj: any = {};
    refineryObj = localStorage.getItem('defaultConfig');
    subUnitId = JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId;
    if (isDelete) {
      resultDataIds = { subUnitId: subUnitId, resultDataId: resultDataIdArray };
      apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/history`;
    } else {
      apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/history/hide`;
      if (isHide) apiHideStatus = true;
      if (isUnHide) apiHideStatus = false;
      resultDataIds = { subUnitId: subUnitId, hideStatus: apiHideStatus, resultDataId: resultDataIdArray };
    }
    apiRequest(encodeURI(apiUrl), resultDataIds, isDelete ? APIEnum.DELETE : APIEnum.PUT)
      .then((result) => {
        if (result && result.responseStaus && result.responseStaus.status === 'SUCCESS') {
          setSuccessFlag(true);
          if (isDelete) setMessage('Simulation deleted successfully !');
          if (isHide) setMessage('Simulation hidden successfully !');
          if (isUnHide) setMessage('Simulation unhidden successfully !');
          setTimeout(() => {
            setSuccessFlag(false);
          }, 2000);
        } else {
          setErrorFlag(true);
          result &&
          result.responseStaus &&
          result.responseStaus.errorResponse &&
          result.responseStaus.errorResponse[0] &&
          result.responseStaus.errorResponse[0].message
            ? setMessage(result.responseStaus.errorResponse[0].message)
            : setMessage('Bad Request');
          setIsLoading(false);
          setTimeout(() => {
            setErrorFlag(false);
          }, 4000);
        }
      })
      .catch((error: { message: any }) => {
        setErrorFlag(true);
        setIsLoading(false);
        setMessage(error.message);
        setTimeout(() => {
          setErrorFlag(false);
        }, 4000);
      })
      .finally(() => {
        let refineryObj: any = {};
        let postApiPayLoad: any;
        refineryObj = localStorage.getItem('defaultConfig');
        isDelete && setPageState((old) => ({ ...old, page: 1 }));
        if (
          startDate &&
          endDate &&
          startDate !== '' &&
          startDate !== 'aN-undefined-NaN' &&
          endDate !== '' &&
          endDate !== 'aN-undefined-NaN'
        ) {
          postApiPayLoad = {
            startDate: startDate,
            endDate: endDate,
            subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
            status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
            pageNumber: isDelete ? 1 : pageState.page,
            pageSize: pageState.pageSize,
            sortBy: sortState.sortBy,
            orderBy: sortState.orderBy,
            modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
          };
        } else {
          postApiPayLoad = {
            subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
            status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
            pageNumber: isDelete ? 1 : pageState.page,
            pageSize: pageState.pageSize,
            sortBy: sortState.sortBy,
            orderBy: sortState.orderBy,
            modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
          };
        }
        getHistoryData(postApiPayLoad);
      });
  };

  const handleSandboxModalClose = () => {
    setSimulationModalOpen(false);
  };

  const handleDialogueCtaClick = (event: any) => {
    if (event.detail === 'btnCancel') {
      setisDeleteDialogueOpen(!isDeleteDialogueOpen);
    } else if (event.detail === 'btnDelete') {
      //delete/hide api call
      setisDeleteDialogueOpen(false);
      setIsLoading(true);
      setSimulationData([]);
      deleteHideApiCall(resultDataId, true, false, false);
    }
  };

  const handleMenuClick = (event: any, details: any) => {
    setAnchorEl(event.currentTarget);
    let key: any;
    if (details?.reportData && Object.keys(details?.reportData).length > 0) {
      key = Object.keys(details?.reportData)[0];
      setResultDataId(details?.reportData[key].resultDataId);
      setHideVisible(details?.reportData[key].isHide);
      setSimulationName(details?.simName);
      setComments(details?.comments);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setisDeleteDialogueOpen(!isDeleteDialogueOpen);
  };

  const handleViewCommentClick =() =>{
    setAnchorEl(null);
    setSimulationModalOpen(true);
  }

  const handleUnHideClick = () => {
    setAnchorEl(null);
    setIsLoading(true);
    deleteHideApiCall(resultDataId, false, false, true);
  };

  const handleHideClick = () => {
    setAnchorEl(null);
    setIsLoading(true);
    deleteHideApiCall(resultDataId, false, true, false);
  };

  const renderHorizontalMenu = (row: any) => {
    return (
      <div style={{ marginLeft: '-5px' }}>
        <IconButton aria-label="settings">
          <MoreHorizIcon onClick={(event) => handleMenuClick(event, row)} />
        </IconButton>
      </div>
    );
  };

  const submitButtonClick = () => {
    if (tabModelStatus !== 'Draft') {
      setPageState((old) => ({ ...old, page: 1 }));
      let refineryObj: any = {};
      refineryObj = localStorage.getItem('defaultConfig');
      let postApiPayLoad = {
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        startDate: startDate,
        endDate: endDate,
        status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
        pageNumber: 1,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
      };
      getHistoryData(postApiPayLoad);
    } else if (tabModelStatus === 'Draft') {
      setSubmitClickCounter(submitClickCounter + 1);
    }
  };

  const clearDatePicker = () => {
    setDatesGiven(false);
    setDateClear(true);
    setTimeout(() => {
      setDateClear(false);
    }, 100);
    setStartDate('');
    setEndDate('');
    setSubmitClickCounter(0);
    let refineryObj: any = {};
    refineryObj = localStorage.getItem('defaultConfig');
    let postApiPayLoad = {
      subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
      status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
      pageNumber: pageState.page,
      pageSize: pageState.pageSize,
      sortBy: sortState.sortBy,
      orderBy: sortState.orderBy,
      modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
    };
    getHistoryData(postApiPayLoad);
  };

  const selectedDateClick = (event: CustomEvent) => {
    // Track which item has been selected
    let elements: any = [];
    if (tabModelStatus != 'Draft') elements = resPayload;
    let data: any = [];
    let length: any;
    let filtered = {
      data,
      length,
    };
    setDatePickerActive(true);

    let startDate =
      event.detail && event.detail.start && event.detail.start !== '' && event.detail.start !== 'aN-undefined-NaN'
        ? changeToShortFormat(new Date(event.detail.start))
        : '';
    let endDate =
      event.detail && event.detail.end && event.detail.end !== '' && event.detail.end !== 'aN-undefined-NaN'
        ? changeToShortFormat(new Date(event.detail.end))
        : '';
    if (startDate && endDate && startDate !== '' && startDate !== 'aN-undefined-NaN' && endDate !== '' && endDate !== 'aN-undefined-NaN') {
      setDatesGiven(true);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const selectedButtonTabClick = (event: CustomEvent) => {
    setSelectedTab(event.detail.fullText === 'Non-Converging' ? 'NonConverging' : event.detail.fullText);
    setTabStatusKey(event.detail.fullText === 'Error Scenarios'? 'error': event.detail.fullText.toLowerCase())
    setTabStatus(event.detail.fullText);
    setSimulationData([]);
    let refineryObj: any = {};
    let postApiPayLoad: any;
    refineryObj = localStorage.getItem('defaultConfig');
    if (startDate && endDate && startDate !== '' && startDate !== 'aN-undefined-NaN' && endDate !== '' && endDate !== 'aN-undefined-NaN') {
      postApiPayLoad = {
        startDate: startDate,
        endDate: endDate,
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        status:
          event.detail.fullText === 'Non-Converging'
            ? 'NonConverging'
            : event.detail.fullText === 'Error Scenarios'
            ? 'Error'
            : event.detail.fullText,
        pageNumber: 1,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
      };
    } else {
      postApiPayLoad = {
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        status:
          event.detail.fullText === 'Non-Converging'
            ? 'NonConverging'
            : event.detail.fullText === 'Error Scenarios'
            ? 'Error'
            : event.detail.fullText,
        pageNumber: 1,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
      };
    }
    getHistoryData(postApiPayLoad);
  };

  const showDetails = (message: string) => {
    setDialogueMessage(message);
    setDialogueOpen(true);
  };

  const renderOverheadTemp = (row: any) => {
    if (!row?.overheadTITemp) {
      return '';
    } else {
      if (row.overheadTITemp !== '--') {
        return <span className={row?.isHide && 'hidden-text-color'}>{row?.overheadTITemp + ' °' + row.temp_UOM}</span>;
      }
      else return <span className={row?.isHide && 'hidden-text-color'}>--</span>
    }
  };

  const renderOverheadFlashCalcTemp = (row: any) => {
    if (!row?.overheadFlashCalcTemp) {
      return '';
    } else {
      if (row.overheadFlashCalcTemp !== '--') {
        return <span className={row?.isHide && 'hidden-text-color'}>{row?.overheadFlashCalcTemp + ' °' + row.temp_UOM}</span>;
      }
      else return <span className={row?.isHide && 'hidden-text-color'}>--</span>
    }
  };

  const renderDiff = (row: any) => {
    if (row.difference !== '--') {
      return <span className={row?.isHide && 'hidden-text-color'}>{row?.difference + ' °' + row.temp_UOM}</span>;
    }
    else return <span className={row?.isHide && 'hidden-text-color'}>--</span>
  };

  const renderCreatedBy = (row: any) =>{
    if (!row?.createdBy) {
      return '';
    } else return <span className={row?.isHide && 'hidden-text-color'}>{row?.createdBy}</span>;
  }

  const renderCreatedOn = (row: any) => {
    if (!row?.executedOn) {
      return '';
    } else return <span className={row?.isHide && 'hidden-text-color'}>{format(new Date(row?.executedOn), 'dd-MMM-yyyy HH:mm z')}</span>;
  };

  const renderStatus = (row: any) => {
    let isModelConverged;
    let isConvergenceData: boolean = false;
    let key: any;
    if (row?.reportData && Object.keys(row?.reportData).length > 0) {
      key = Object.keys(row?.reportData)[0];
      isConvergenceData = row?.reportData[key].convergenceData ? true : false;
      isModelConverged = isConvergenceData && row?.reportData[key].convergenceData.isModelConverged;
    }
    if (row?.status === 'success' && isModelConverged) {
      return (
        <div className="text-link">
          <CheckCircleOutlineIcon style={row?.isHide ? { color: '#65D6AD' } : {color:'#548c69'}} fontSize="small" />
          <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Converging</span>
        </div>
      );
    }
    else if(row?.status === 'success' && !isModelConverged){
      return (
        <div className="text-link">
          <ErrorIcon style={row?.isHide ? { color: '#edb98e' }: { color: '#e87516' }} fontSize="small" />
          <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Non Converging</span>
        </div>
      );
    }
    else if (row?.status === 'error') {
      if (isConvergenceData) {
        if (!isModelConverged) {
          return (
            <div className="text-link-pointer" onClick={() => showDetails(row?.reportData[key].statusReason)}>
              <ErrorIcon style={row?.isHide ? { color: '#edb98e' }: { color: '#e87516' }} fontSize="small" />
              <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Non Converging (click here for details)</span>
            </div>
          );
        } else {
          return (
            <div className="text-link-pointer" onClick={() => showDetails(row?.reportData[key].statusReason)}>
              <WarningIcon style={row?.isHide ? { color: '#d17d85' }:{ color: '#cf2333' }} fontSize="small" />
              <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Error (click here for details)</span>
            </div>
          );
        }
      } else {
        return (
          <div className="text-link-pointer" onClick={() => showDetails(row?.reportData[key].statusReason)}>
            <WarningIcon style={row?.isHide ? { color: '#d17d85' }: { color: '#cf2333' }} fontSize="small" />
            <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Error (click here for details)</span>
          </div>
        );
      }
    } else if (row?.status === 'not-started') {
      return (
        <div className="text-link-pointer" onClick={() => showDetails(row?.reportData[key].statusReason)}>
          <WarningIcon style={row?.isHide ? { color: '#d17d85' }:{ color: '#cf2333' }} fontSize="small" />
          <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color' :'text-margin typography--body-medium'}>Error (click here for details)</span>
        </div>
      );
    }
  };

  const renderUView = (row: any) => {
    return (
      <div>
        <span className={row?.isHide ? 'text-margin typography--body-medium hidden-text-color': 'text-margin typography--body-medium'}>{row.uviewStatus}</span>
      </div>
    );
  };

  const renderDate = (row: any) => {
    if (!row?.inputDate) {
      return '';
    } else {
      if (row?.status === 'success' && row?.reportData && Object.keys(row?.reportData).length > 0) {
          return (
            <a className={row?.isHide ? 'date-link hidden-date-color' : 'date-link'} onClick={() => redirectToReport(row)}>
              {format(new Date(row?.inputDate), 'dd-MMM-yyyy')}
            </a>
          );
      } else return <span className={row?.isHide && 'hidden-text-color'}>{format(new Date(row?.inputDate), 'dd-MMM-yyyy')}</span>;
    }
  };

  const renderSimName = (row: any) => {
    if (!row?.simName) {
      return '';
    } else {
        return <span className='sim-name-span'>{row?.simName}</span>;
      }
    }

  const PaginationForAllSimulation = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    //total pages from api
    const pageCountAllSimulation = totalPages;
    return (
      <MuiPagination
        className={className}
        count={pageCountAllSimulation}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPaginationForAllSimulation = (props: any) => {
    return <GridPagination ActionsComponent={PaginationForAllSimulation} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlayForAllSimulation = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };  
  useLayoutEffect(() => {
    setSubText(localStorage.getItem('subtitle')!);
    let refineryObj: any = {};
    let postApiPayLoad: any;
    refineryObj = localStorage.getItem('defaultConfig');
    if (startDate && endDate && startDate !== '' && startDate !== 'aN-undefined-NaN' && endDate !== '' && endDate !== 'aN-undefined-NaN') {
      postApiPayLoad = {
        startDate: startDate,
        endDate: endDate,
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
        pageNumber: pageState.page,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
      };
    } else {
      postApiPayLoad = {
        subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
        status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
        pageNumber: pageState.page,
        pageSize: pageState.pageSize,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
      };
    }
    getHistoryData(postApiPayLoad);
    if (
      localStorage.getItem('salt') &&
      Object.keys(localStorage.getItem('salt')!).length &&
      decryptData(localStorage.getItem('salt')) &&
      decryptData(localStorage.getItem('salt')).userRole &&
      (decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme' ||
        decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'admin') &&
      decryptData(localStorage.getItem('salt')).userActiveStatus
    ) {
      setDeleteVisible(true);
    } else {
      setDeleteVisible(false);
    }
    return () => {
      setDatesGiven(false);
      setPageState({ page: 1, pageSize: 10 });
      setSortState({ sortBy: 'executedOn', orderBy: 'desc' });
      setSubmitClickCounter(0);
    };
  }, [isSandboxTableOpen]);

  const redirectToReport = (item: any) => {
    localStorage.setItem('primaryReportData', JSON.stringify(item.reportData));
    localStorage.setItem('configDetails', JSON.stringify(item.configDetails));
    localStorage.setItem('pageName', 'All Simulation');
    localStorage.setItem('defaultUOM', JSON.stringify(item.defaultUOM));
    if (item.reportData && Object.keys(item.reportData).length > 0) {
      var key: any = Object.keys(item.reportData)[0];
      let resultDataId = parseInt(item.reportData[key].resultDataId);
      localStorage.setItem('resultDataId', JSON.stringify(resultDataId));
    }
    navigate('/primary-report', {
      state: {
        reportData: item.reportData,
        defaultUOM: item.defaultUOM,
        configDetails: item.configDetails,
        modeType:item.modeType,
        pageName: 'All Simulation',
      },
    });
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  const handleCloseDialogue = (event: any) => {
    setDialogueOpen(false);
  };

  const handleSortChange = (sortModel: any) => {
    if (Object.keys(sortModel).length && sortModel.length) {
      setSortState((old) => ({ ...old, sortBy: sortModel[0].field, orderBy: sortModel[0].sort }));
      let refineryObj: any = {};
      let postApiPayLoad: any;
      refineryObj = localStorage.getItem('defaultConfig');
      if (
        startDate &&
        endDate &&
        startDate !== '' &&
        startDate !== 'aN-undefined-NaN' &&
        endDate !== '' &&
        endDate !== 'aN-undefined-NaN'
      ) {
        postApiPayLoad = {
          startDate: startDate,
          endDate: endDate,
          subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
          status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
          pageNumber: pageState.page,
          pageSize: pageState.pageSize,
          sortBy: sortModel[0].field,
          orderBy: sortModel[0].sort,
          modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
        };
      } else {
        postApiPayLoad = {
          subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
          status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
          pageNumber: pageState.page,
          pageSize: pageState.pageSize,
          sortBy: sortModel[0].field,
          orderBy: sortModel[0].sort,
          modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
        };
      }
      getHistoryData(postApiPayLoad);
    }
  };
  const handlePaginationChange = (paginationModel: any, r: any) => {
    if (Object.keys(paginationModel).length) {
      setPageState((old) => ({ ...old, page: paginationModel.page + 1, pageSize: paginationModel.pageSize }));
      let refineryObj: any = {};
      let postApiPayLoad: any;
      refineryObj = localStorage.getItem('defaultConfig');
      if (
        startDate &&
        endDate &&
        startDate !== '' &&
        startDate !== 'aN-undefined-NaN' &&
        endDate !== '' &&
        endDate !== 'aN-undefined-NaN'
      ) {
        postApiPayLoad = {
          startDate: startDate,
          endDate: endDate,
          subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
          status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          sortBy: sortState.sortBy,
          orderBy: sortState.orderBy,
          modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
        };
      } else {
        postApiPayLoad = {
          subUnitId: JSON.parse(refineryObj).refinery.unit[0].subUnit[0].subUnitId,
          status: tabStatus === 'Non-Converging' ? 'NonConverging' : tabStatus === 'Error Scenarios' ? 'Error' : tabStatus,
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          sortBy: sortState.sortBy,
          orderBy: sortState.orderBy,
          modeType : isSandboxTableOpen ? 'SandBox' : 'Actual'
        };
      }
      getHistoryData(postApiPayLoad);
    }
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '20px', marginTop: '-17px', height: '30px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            position: 'absolute',
          }}
        >
          <Link color="inherit" href="/">
            <PublicIcon />
          </Link>
        </Breadcrumbs>
      </div>
      <div style={{ marginTop: '-15px' }}>
        <BhDivider></BhDivider>
      </div>

      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column'}}>
        <div style={{ height: '50px' }}>
          <BhTitleWrapper data-testid="new-sim-title" type="subtitle" header="All Simulations" subtext={subText}></BhTitleWrapper>
        </div>
      </div>

      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column', marginBottom: '10px' }}>
        <div>
          <BhTabs
            items={JSON.stringify([
              { label: 'All', icon: 'insert_chart', key: 'all' },
              { label: 'Draft', icon: 'description', key: 'draft' },
              { label: 'Sandbox simulations', icon: 'insights', key: 'sandbox' }
            ])}
            isBorder={true}
            menuWidth="fluid"
            activeKey={isHistoricTableOpen ? 'all' : (isSandboxTableOpen ? 'sandbox': 'draft')}
            onBhEventSelected={(event: any) => selectedTabClick(event)}
          ></BhTabs>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isHistoricTableOpen || isSandboxTableOpen ? (
            <div style={{ marginTop: '30px', width: '40%' }}>
              <BhButtonTabs
                items={[
                  { label: 'All', key: 'all' },
                  { label: 'Converging', key: 'converging' },
                  { label: 'Non-Converging', key: 'non-converging' },
                  { label: 'Error Scenarios', key: 'error' },
                ]}
                activeKey={tabStatusKey}
                menuWidth="fluid"
                onBhEventSelected={(event: any) => selectedButtonTabClick(event)}
              ></BhButtonTabs>
            </div>
          ) : (
            <div style={{ marginTop: '30px' }}></div>
          )}
          <div
            style={{ marginTop: '15px', width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'end', marginLeft: '10px' }}
          >
            <div>
              <BhDateRangePicker id="filterDate" isReset={isDateClear} onBhEventChange={(event: any) => selectedDateClick(event)} />
            </div>

            <div style={{ marginLeft: '15px', marginTop: '15px' }}>
              <BhButton type="secondary" label="Clear" onClick={clearDatePicker}></BhButton>
            </div>

            <div style={{ marginLeft: '15px', marginTop: '15px' }}>
              <BhButton type="primary" label="Submit" onClick={submitButtonClick} isDisabled={!isDatesGiven}></BhButton>
            </div>

            <div style={{ marginLeft: '15px', marginTop: '15px' }}>
               {(isHistoricTableOpen && selectedTab !== 'Error Scenarios') || (isSandboxTableOpen && selectedTab !== 'Error Scenarios') ? (
                <BhButton
                  isDisabled={exportButtonLoading || (simulationData.data && simulationData.data.length === 0)}
                  id="btnExport"
                  key="btnExport"
                  leftIcon="file_download"
                  type="primary"
                  label="Export to excel"
                  onClick={() => exportButtonClick()}
                ></BhButton>
                ):null}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px' }}>
        <div style={{ height: 600, width: '100%' }}>
          {isHistoricTableOpen ? (
            Object.keys(simulationData).length > 0 && simulationData.data && simulationData.data.length ? (
              <div style={{ height: 600, width: '100%', paddingTop: 10 }}>
                <DataGrid
                  //pagination
                  slots={{
                    pagination: CustomPaginationForAllSimulation,
                  }}
                  rows={simulationData.data}
                  rowCount={totalRecords}
                  columns={allsimcolumns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                  pageSizeOptions={[5, 10, 15, 20, 25]}
                  paginationMode="server"
                  onPaginationModelChange={(paginationModel: any, r: any) => {
                    handlePaginationChange(paginationModel, r);
                  }}
                  onSortModelChange={(sortModel: any) => {
                    handleSortChange(sortModel);
                  }}
                  columnVisibilityModel={{ id: false }}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  showCellVerticalBorder
                  showColumnVerticalBorder
                  rowHeight={48}
                  columnHeaderHeight={42}
                />
              </div>
            ) : (
              <div style={{ height: 150, width: '100%' }}>
                <DataGrid
                  pagination
                  slots={{
                    pagination: CustomPaginationForAllSimulation,
                    noRowsOverlay: CustomNoRowsOverlayForAllSimulation,
                  }}
                  rows={[]}
                  columns={allsimcolumns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                  pageSizeOptions={[5, 10, 15, 20, 25]}
                  columnVisibilityModel={{ id: false }}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  showCellVerticalBorder
                  showColumnVerticalBorder
                  rowHeight={48}
                  columnHeaderHeight={42}
                />
              </div>
            )
          ) : (
            isSandboxTableOpen ? 
            (
              Object.keys(simulationData).length > 0 && simulationData.data && simulationData.data.length ? (
                <div style={{ height: 600, width: '100%', paddingTop: 10 }}>
                  <DataGrid
                    pagination
                    slots={{
                      pagination: CustomPaginationForAllSimulation,
                    }}
                    rows={simulationData.data}
                    rowCount={totalRecords}
                    columns={allsandboxsimcolumns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    paginationMode="server"
                    onPaginationModelChange={(paginationModel: any, r: any) => {
                      handlePaginationChange(paginationModel, r);
                    }}
                    onSortModelChange={(sortModel: any) => {
                      handleSortChange(sortModel);
                    }}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={48}
                    columnHeaderHeight={42}
                  />
                </div>
              ) : (
                <div style={{ height: 150, width: '100%' }}>
                  <DataGrid
                    pagination
                    slots={{
                      pagination: CustomPaginationForAllSimulation,
                      noRowsOverlay: CustomNoRowsOverlayForAllSimulation,
                    }}
                    rows={[]}
                    columns={allsandboxsimcolumns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={48}
                    columnHeaderHeight={42}
                  />
                </div>
              ))
            :(<DraftListing startDate={startDate} endDate={endDate} submitClickCounter={submitClickCounter}></DraftListing>
          ))}
        </div>
      </div>
      <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose} id="newsim-menu">
        {!isHideVisible && !isSandboxTableOpen && (
          <MenuItem id="btnHide" onClick={handleHideClick}>
            <ListItemIcon>
              <VisibilityOffOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Hide</ListItemText>
          </MenuItem>
        )}
        {isHideVisible && !isSandboxTableOpen && (
          <MenuItem id="btnUnHide" onClick={handleUnHideClick}>
            <ListItemIcon>
              <VisibilityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Unhide</ListItemText>
          </MenuItem>
        )}
        {isDeleteVisible && (
          <MenuItem id="btnHistoryDelete" onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        {isSandboxTableOpen && (
          <MenuItem id="btnViewComments" onClick={handleViewCommentClick}>
            <ListItemIcon>
              <CommentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>View comments</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
          open={isLoading}
          onClick={handleClose}
        >
          {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
        </Backdrop>
      </div>

      <BhDialog
        id="errorDialogAllSim"
        isDismissible={true}
        isOpen={dialogueOpen}
        message={dialogueMessage}
        onBhEventClose={(event: any) => handleCloseDialogue(event)}
      ></BhDialog>
      <BhDialog
        header="Delete simulation"
        message="Are you sure you want to delete this simulation?"
        ctas={[
          {
            type: 'critical',
            label: 'Delete',
            key: 'btnDelete',
          },
          {
            type: 'ghost',
            label: 'Cancel',
            key: 'btnCancel',
          },
        ]}
        isOpen={isDeleteDialogueOpen}
        onBhEventCtaClick={(event: any) => handleDialogueCtaClick(event)}
        onBhEventClose={(event: any) => handleDialogueClose(event)}
      ></BhDialog>
      {isSuccess ? (
        <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success asset-error" id="alert-message">
          {message}
        </Alert>
      ) : isError ? (
        <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error asset-error" id="alert-message">
          {message}
        </Alert>
      ) : (
        <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
      )}
       <BootstrapDialog onClose={()=>handleSandboxModalClose()} aria-labelledby="upload-excel-title" open={isSimulationModalOpen} className="view-comment">
        <BootstrapDialogTitle id="upload-excel-title" onClose={()=>handleSandboxModalClose()}>
          <div className="upload-header">Simulation- Sandbox</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
              <BhTextInput
                            id="txt-simname"
                            type="text"
                            label="Simulation name"
                            value={simulationName}
                            isReadOnly
                          ></BhTextInput>
                          <div className='pd-3'></div>
                               <BhTextArea
            
            fluidHorz={true}
            messageType="count"
            label='Comments'
            placeholder='Write something...'
            value={comments}
            className='txtsimulationcomment'
          ></BhTextArea>
          </div>
        </DialogContent>
        <DialogActions>
          <BhButton type="primary" label="Close" key="btnUploadCancel" onClick={()=>handleSandboxModalClose()} ></BhButton>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
