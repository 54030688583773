/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { Stage, Layer, Wedge, Rect, Circle, Line, Text } from 'react-konva';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './PrimaryReport.scss';
import { corrosionRiskTooltip, getColorIndicators, getIconIndicators, getRiskIndicatorIcons } from './PrimaryReportHelper';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { BEFORE_WASH, AFTER_WASH, MONTHS, REPORT_ACCORDION } from '../../constants/report-constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { convertToFarenheit, getTempSign } from '../../utils/utils';

export default function PrimaryReport(props: any) {
  const svgRef: any = React.createRef();
  const [defaultConfig, setDefaultConfig] = useState<any>({});
  const [configType, setConfigType] = useState('');
  const [waterWash, setWaterWash] = useState<any>();
  const [neutralizerInjected, setNeutralizerInjected] = useState<any>();
  const [colorIndicators, setColorIndicators] = useState<any>();
  const [primaryReportData, setPrimaryReportData] = useState<any>();
  const [ionicInput, setIonicInput] = useState<any>();
  const [defaultUOM, setDefaultUOM] = useState<any>();
  const [neutralizerInjectedStage, setNeutralizerInjectedStage] = useState<any>();
  const [waterWashInjectedStage, setWaterWashInjectedStage] = useState<any>();
  const [reportDate, setReportDate] = useState<any>();
  const [iconIndicators, setIconIndicators] = useState<any>();
  const [subHeadingReport, setSubHeadingReport] = useState<any>();
  const [subUnitName, setSubUnitName] = useState<any>();
  const [unitType, setUnitType] = useState<string>('');

  const [expanded, setExpanded] = useState<any>(REPORT_ACCORDION.TOWER_TOP);

  const handleChange = (panel: any) => (event: any, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  type TooltipProps = {
    className?: string;
    props: {
      title?: any;
      children?: React.ReactNode;
    };
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    // @ts-ignore
    <Tooltip {...props} classes={{ popper: className }} className="corrosion-risk-tooltip">
      <div>
        <InfoOutlinedIcon />
        <p className="risk-label">Corrosion Risk</p>
      </div>
    </Tooltip>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  const getReportData = () => {
    if (props.primaryReportData) {
      setPrimaryReportData(props.primaryReportData);
    } else {
      var temp: any = localStorage.getItem('primaryReportData') ? localStorage.getItem('primaryReportData') : '{}';
      setPrimaryReportData(JSON.parse(temp));
    }
    if (props.inputData) {
      setIonicInput(props.inputData);
    } else {
      var temp: any = localStorage.getItem('ionicInput') ? localStorage.getItem('ionicInput') : '{}';
      setIonicInput(JSON.parse(temp));
    }
    if (props.defaultUOM) {
      setDefaultUOM(props.defaultUOM);
    } else {
      var temp: any = localStorage.getItem('defaultUOM') ? localStorage.getItem('defaultUOM') : '{}';
      setDefaultUOM(JSON.parse(temp));
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (primaryReportData && Object.keys(primaryReportData).length > 0) {
      var key: any = Object.keys(primaryReportData)[0];
      var tempDate: any = `${new Date(key).getDate()} ${MONTHS[new Date(key).getMonth()]} ${new Date(key).getFullYear()} `;
      setSubHeadingReport(tempDate);
      setReportDate(key);
      props.setDateOfReport(key, tempDate);
      var defaultData: any;
      if (props.configData) {
        defaultData = props.configData;
      } else if (localStorage.getItem('pageName') === 'All Simulation') {
        defaultData = localStorage.getItem('configDetails') ? localStorage.getItem('configDetails') : '{}';
        defaultData = JSON.parse(defaultData);
      } else {
        defaultData = localStorage.getItem('defaultConfig') ? localStorage.getItem('defaultConfig') : '';
        defaultData = JSON.parse(defaultData);
      }
      setConfigType(defaultData.refinery.unit[0].subUnit[0].configType);
      setUnitType(defaultData.refinery.unit[0].unitType.trim().toLowerCase());
      let subUnitName =
      ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'atm tower overhead') ||
      (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'atm tower overhead'))
        ? ' Atmospheric Tower Overhead'
        : ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'prefractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'prefractionator overhead') ? 
        ' Prefractionator Overhead':
        ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'fractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'fractionator overhead') ? 
        'Fractionator Overhead':
        ((defaultData.refinery.unit[0].subUnit[0].subUnitType && defaultData.refinery.unit[0].subUnit[0].subUnitType.trim().toLowerCase() === 'main fractionator overhead') ||
        (defaultData.refinery.unit[0].subUnit[0].typeSubunit && defaultData.refinery.unit[0].subUnit[0].typeSubunit.trim().toLowerCase() === 'main fractionator overhead') ? 
        'Main Fractionator Overhead'
        :defaultData.refinery.unit[0].subUnit[0].subUnitType)));
    let unitTypeName = defaultData.refinery.unit[0].unitType.trim().toLowerCase() === 'crude'
    ? ' Crude Unit'
    : defaultData.refinery.unit[0].unitType;
      setSubUnitName(unitTypeName + ' | ' + subUnitName);
      if (defaultData.refinery.unit[0].subUnit[0].configType === '2A' || defaultData.refinery.unit[0].subUnit[0].configType === '2B' || defaultData.refinery.unit[0].subUnit[0].configType === '2C') {
        setNeutralizerInjectedStage(defaultData.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream);
        setWaterWashInjectedStage(defaultData.refinery.unit[0].subUnit[0].stageOvhdWashStream);
      }
      setWaterWash(defaultData.refinery.unit[0].subUnit[0].waterWash);
      setNeutralizerInjected(defaultData.refinery.unit[0].subUnit[0].injectedNeutralizerStream);
      setDefaultConfig(defaultData);
      setColorIndicators(
        getColorIndicators(primaryReportData, key, ionicInput, defaultData.refinery.unit[0].subUnit[0], defaultUOM.temp_UOM)
      );
      setIconIndicators(
        getIconIndicators(
          primaryReportData,
          key,
          defaultData.refinery.unit[0].subUnit[0].configType,
          defaultUOM.temp_UOM,
          defaultUOM.velocity_UOM,
          unitType
        )
      );
    }
  }, [primaryReportData]);

  const getWaterWashLine = () => {
    var waterWashLines: any = '';
    if (waterWash) {
      waterWashLines = (
        <>
          <Circle x={920} y={70} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
          <Text
            x={930}
            y={65}
            text={'Water wash'}
            fontFamily="Poppins"
            fontSize={14}
            width={100}
            fontStyle="bold"
            height={100}
            fill="black"
          />
        </>
      );
    }
    return waterWashLines;
  };
  const getNeutralizerWashLines = () => {
    var neutralizerWashLines: any = '';

    if (waterWash) {
      if (
        (neutralizerInjectedStage === '1' || neutralizerInjectedStage == '2') &&
        waterWashInjectedStage === '1' &&
        neutralizerInjected?.toLowerCase() === BEFORE_WASH
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 190, 120, 190, 240, 235, 240]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 355, 70, 355, 110]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={350} y={110} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[355, 120, 355, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (
        (neutralizerInjectedStage === '1' || neutralizerInjectedStage === '2') &&
        waterWashInjectedStage === '1' &&
        neutralizerInjected?.toLowerCase() === AFTER_WASH
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 355, 120]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 355, 70, 355, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={350} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[355, 125, 355, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (
        neutralizerInjectedStage === '1' &&
        waterWashInjectedStage === '2' &&
        (neutralizerInjected?.toLowerCase() === BEFORE_WASH || neutralizerInjected?.toLowerCase() === AFTER_WASH)
      ) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 190, 120, 190, 240, 235, 240]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 110]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={110} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 120, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (neutralizerInjectedStage === '2' && waterWashInjectedStage === '2' && neutralizerInjected?.toLowerCase() === AFTER_WASH) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 712, 120]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 125, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      } else if (neutralizerInjectedStage === '2' && waterWashInjectedStage === '2' && neutralizerInjected?.toLowerCase() === BEFORE_WASH) {
        neutralizerWashLines = (
          <>
            {/* Neutralizer */}
            <Line points={[100, 120, 580, 120, 580, 255, 630, 255]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
            {/* Water wash lines */}
            <Line points={[900, 70, 715, 70, 715, 115]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
            <Rect x={710} y={115} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
            <Line points={[715, 125, 715, 200]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
          </>
        );
      }
    } else {
      if (neutralizerInjectedStage === '1') {
        neutralizerWashLines = <Line points={[100, 120, 200, 120, 200, 242, 245, 242]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />;
      } else {
        neutralizerWashLines = <Line points={[100, 120, 600, 120, 600, 255, 640, 255]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />;
      }
    }
    return neutralizerWashLines;
  };

  return (
    <div>
      {Object.keys(defaultConfig).length > 0 && (
        <>
          <Accordion elevation={0} classes={{}} expanded={true} className="report-outline">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
              <Typography className="primary-report-header">Primary report</Typography>
              <Typography className="primary-report-subheader">{`${subUnitName}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="report-wrapper">
                <div className="primary-report-container">
                  <div className="report-diagram">
                    <Stage width={1380} height={600} ref={svgRef} id="report">
                      {configType === '1A' || configType === '1B' || configType === '1C' ? (
                        <Layer>
                          {/* Tower Top */}
                          <Circle
                            x={240}
                            y={295}
                            width={80}
                            fill={`rgb(${colorIndicators.towerTop.colorRed}, ${colorIndicators.towerTop.colorGreen}, ${colorIndicators.towerTop.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          {/* Tower */}

                          <Rect
                            x={200}
                            y={300}
                            width={80}
                            height={200}
                            fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            shadowBlur={0}
                            stroke={'rgba(20, 125, 100, 1)'} // border color
                          />
                          <Wedge
                            x={201}
                            y={480}
                            radius={43.5}
                            angle={90}
                            fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            rotation={360}
                            stroke={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            strokeWidth={1}
                          />
                          <Wedge x={280} y={510} radius={41.5} angle={180} fill="white" rotation={-180} stroke={'white'} strokeWidth={1} />

                          {/* Neutralizer namings */}
                          { (unitType !== 'coker' && unitType !== 'fcc') && (
                          <>
                          <Circle x={90} y={107} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={100}
                            y={100}
                            text={'Neutralizer'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                          <Text
                            x={100}
                            y={120}
                            text={ionicInput.overheadWater.neutralizerName}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={200}
                            height={100}
                            fill="rgba(80, 108, 101, 1)"
                          />
                          </>
                           )}

                          {/* Tower Top */}
                          <Circle x={90} y={305} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={100}
                            y={300}
                            text={'Tower Top'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                          {/* Neutralizer with water wash*/}
                          {waterWash ? (
                            <>
                              {/* Neutralizer line */}
                              { (unitType !== 'coker' && unitType !== 'fcc') && (
                              <>
                              {neutralizerInjected?.toLowerCase() === BEFORE_WASH ? (
                                <Line points={[100, 150, 295, 150, 295, 200]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                              ) : (
                                <Line points={[100, 150, 700, 150, 700, 229]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                              )}
                              </>
                              )}

                              {/* Wash lines */}

                              <Line points={[800, 234, 705, 234]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
                              <Line points={[695, 234, 600, 234]} stroke="rgba(47, 192, 207, 1)" strokeWidth={5} />
                              <Rect x={695} y={229} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                              {/* Water wash label */}
                              <Circle x={830} y={230} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />

                              <Text
                                x={840}
                                y={222}
                                text={'Water wash'}
                                fontFamily="Poppins"
                                fontSize={14}
                                width={100}
                                fontStyle="bold"
                                height={100}
                                fill="black"
                              />
                            </>
                          ) : ((unitType !== 'coker' && unitType !== 'fcc') && 
                            <Line points={[100, 150, 295, 150, 295, 200]} stroke="rgba(102, 110, 180, 1)" strokeWidth={5} />
                          )}

                          {/* Reflux */}
                          <Line
                            points={[350, 525, 350, 355, 293, 355]}
                            stroke={`rgb(${colorIndicators.refluxPipe.colorRed}, ${colorIndicators.refluxPipe.colorGreen}, ${colorIndicators.refluxPipe.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[290, 350, 290, 362, 286, 357]}
                            strokeWidth={4}
                            stroke="black"
                            closed={true}
                          />
                          <Text
                            x={300}
                            y={540}
                            text={ionicInput.liquidAssayStreams.isWetReflux ? 'Wet Reflux' : 'Dry Reflux'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />

                          {/* Ovhd 1 */}
                          <Line
                            points={[240, 254, 240, 200, 290, 200]}
                            stroke={`rgb(${colorIndicators.ovhd1.colorRed}, ${colorIndicators.ovhd1.colorGreen}, ${colorIndicators.ovhd1.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={290} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 2 */}
                          <Line
                            points={[300, 200, 600, 200, 600, 230]}
                            stroke={`rgb(${colorIndicators.ovhd2.colorRed}, ${colorIndicators.ovhd2.colorGreen}, ${colorIndicators.ovhd2.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={595} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 3 */}
                          <Line
                            points={[600, 240, 600, 400]}
                            stroke={`rgb(${colorIndicators.ovhd3.colorRed}, ${colorIndicators.ovhd3.colorGreen}, ${colorIndicators.ovhd3.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={595} y={400} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 4 */}
                          <Line
                            points={[600, 410, 600, 450, 630, 450]}
                            stroke={`rgb(${colorIndicators.ovhd4.colorRed}, ${colorIndicators.ovhd4.colorGreen}, ${colorIndicators.ovhd4.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={630} y={445} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />
                          {/* Ovhd 5 */}
                          <Line
                            points={[640, 450, 705, 450]}
                            stroke={`rgb(${colorIndicators.ovhd5.colorRed}, ${colorIndicators.ovhd5.colorGreen}, ${colorIndicators.ovhd5.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[708, 445, 713, 450, 708, 455]}
                            strokeWidth={5}
                            stroke="black"
                            closed={true}
                          />
                          {/* Exchanger */}
                          <Circle
                            x={762}
                            y={450}
                            width={90}
                            fill={`rgb(${colorIndicators.exchanger.colorRed}, ${colorIndicators.exchanger.colorGreen}, ${colorIndicators.exchanger.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          <Circle x={830} y={445} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={840}
                            y={440}
                            text={'Exchanger'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                        </Layer>
                      ) : (
                        <Layer>
                          {/* Tower Top */}
                          <Circle
                            x={240}
                            y={317}
                            width={80}
                            fill={`rgb(${colorIndicators.towerTop.colorRed}, ${colorIndicators.towerTop.colorGreen}, ${colorIndicators.towerTop.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          {/* Tower */}

                          <Rect
                            x={200}
                            y={325}
                            width={80}
                            height={200}
                            fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            shadowBlur={0}
                            stroke={'rgba(20, 125, 100, 1)'} // border color
                          />
                          <Wedge
                            x={201}
                            y={510}
                            radius={43.5}
                            angle={90}
                            fill={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            rotation={360}
                            stroke={`rgb(${colorIndicators.tower.colorRed}, ${colorIndicators.tower.colorGreen}, ${colorIndicators.tower.colorBlue})`}
                            strokeWidth={1}
                          />
                          <Wedge x={280} y={540} radius={41.5} angle={180} fill="white" rotation={-180} stroke={'white'} strokeWidth={1} />

                          {/* Neutralizer namings */}
                          <Circle x={90} y={77} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={100}
                            y={70}
                            text={'Neutralizer'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                          <Text
                            x={100}
                            y={90}
                            text={ionicInput.overheadWater.neutralizerName}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={200}
                            height={100}
                            fill="rgba(80, 108, 101, 1)"
                          />

                          {/* Tower Top */}
                          <Circle x={90} y={305} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={100}
                            y={300}
                            text={'Tower Top'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                          {/* Neutralizer with water wash*/}
                          <>
                            {/* Neutralizer line */}
                            {(unitType !== 'coker' && unitType !== 'fcc') && getNeutralizerWashLines()}
                            {/* Water wash label */}

                            {getWaterWashLine()}
                          </>

                          {/* Reflux */}
                          <Line
                            points={[350, 525, 350, 355, 293, 355]}
                            stroke={`rgb(${colorIndicators.refluxPipe.colorRed}, ${colorIndicators.refluxPipe.colorGreen}, ${colorIndicators.refluxPipe.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[290, 350, 290, 362, 286, 357]}
                            strokeWidth={4}
                            stroke="black"
                            closed={true}
                          />
                          <Text
                            x={300}
                            y={540}
                            text={ionicInput.liquidAssayStreams.isWetReflux ? 'Wet Reflux' : 'Dry Reflux'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />

                          {/* Ovhd 1 */}
                          <Line
                            points={[240, 277, 240, 245]}
                            stroke={`rgb(${colorIndicators.ovhd1.colorRed}, ${colorIndicators.ovhd1.colorGreen}, ${colorIndicators.ovhd1.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={235} y={237} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 2 */}
                          <Line
                            points={[240, 237, 240, 200, 350, 200]}
                            stroke={`rgb(${colorIndicators.ovhd2.colorRed}, ${colorIndicators.ovhd2.colorGreen}, ${colorIndicators.ovhd2.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={350} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 3 */}
                          <Line
                            points={[360, 200, 475, 200]}
                            stroke={`rgb(${colorIndicators.ovhd3.colorRed}, ${colorIndicators.ovhd3.colorGreen}, ${colorIndicators.ovhd3.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={475} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 4 */}
                          <Line
                            points={[485, 200, 550, 200, 550, 230]}
                            stroke={`rgb(${colorIndicators.ovhd4.colorRed}, ${colorIndicators.ovhd4.colorGreen}, ${colorIndicators.ovhd4.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={545} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* Ovhd 5 */}
                          <Line
                            points={[550, 240, 550, 275]}
                            stroke={`rgb(${colorIndicators.ovhd5.colorRed}, ${colorIndicators.ovhd5.colorGreen}, ${colorIndicators.ovhd5.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[545, 275, 555, 275, 550, 280]}
                            strokeWidth={5}
                            stroke="black"
                            closed={true}
                          />
                          <Circle
                            x={552}
                            y={322}
                            width={75}
                            fill={`rgb(${colorIndicators.exchanger.colorRed}, ${colorIndicators.exchanger.colorGreen}, ${colorIndicators.exchanger.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          {/* Exchanger namings*/}

                          <Circle x={410} y={314} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={420}
                            y={306}
                            text={'Exchanger'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />

                          {/* 1Stage 1 */}
                          <Line
                            points={[553, 360, 553, 420]}
                            stroke={`rgb(${colorIndicators.oneStage1.colorRed}, ${colorIndicators.oneStage1.colorGreen}, ${colorIndicators.oneStage1.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[548, 420, 558, 420, 553, 425]}
                            strokeWidth={5}
                            stroke="black"
                            closed={true}
                          />

                          {/* Hot Drum */}
                          <Circle
                            x={540}
                            y={465}
                            width={70}
                            fill={`rgb(${colorIndicators.hotDrumLeft.colorRed}, ${colorIndicators.hotDrumLeft.colorGreen}, ${colorIndicators.hotDrumLeft.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          <Circle
                            x={640}
                            y={465}
                            width={70}
                            fill={`rgb(${colorIndicators.hotDrumRight.colorRed}, ${colorIndicators.hotDrumRight.colorGreen}, ${colorIndicators.hotDrumRight.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                          <Rect
                            x={545}
                            y={430}
                            width={90}
                            height={70}
                            fill={`rgb(${colorIndicators.hotDrumCenter.colorRed}, ${colorIndicators.hotDrumCenter.colorGreen}, ${colorIndicators.hotDrumCenter.colorBlue})`}
                            shadowBlur={0}
                            stroke={'rgba(20, 125, 100, 1)'} // border color
                          />
                          {/* Hot drum namings */}
                          <Circle x={705} y={465} width={10} fill={'white'} stroke={'rgba(2, 167, 131, 1)'} />
                          <Text
                            x={715}
                            y={460}
                            text={'Hot drum'}
                            fontFamily="Poppins"
                            fontSize={14}
                            width={80}
                            fontStyle="bold"
                            height={100}
                            fill="black"
                          />
                          {/* 2Stage 1 */}
                          <Line
                            points={[635, 429, 635, 260]}
                            stroke={`rgb(${colorIndicators.twoStage1.colorRed}, ${colorIndicators.twoStage1.colorGreen}, ${colorIndicators.twoStage1.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={630} y={250} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* 2Stage 2 */}
                          <Line
                            points={[635, 250, 635, 200, 720, 200]}
                            stroke={`rgb(${colorIndicators.twoStage2.colorRed}, ${colorIndicators.twoStage2.colorGreen}, ${colorIndicators.twoStage2.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={710} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* 2Stage 3 */}
                          <Line
                            points={[720, 200, 800, 200, 820, 200]}
                            stroke={`rgb(${colorIndicators.twoStage3.colorRed}, ${colorIndicators.twoStage3.colorGreen}, ${colorIndicators.twoStage3.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={820} y={195} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* 2Stage 4 */}
                          <Line
                            points={[830, 200, 900, 200, 900, 230]}
                            stroke={`rgb(${colorIndicators.twoStage4.colorRed}, ${colorIndicators.twoStage4.colorGreen}, ${colorIndicators.twoStage4.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Rect x={895} y={230} width={10} height={10} fill={'rgba(173, 189, 185, 1)'} shadowBlur={0} />

                          {/* 2Stage 5 */}
                          <Line
                            points={[900, 240, 900, 280]}
                            stroke={`rgb(${colorIndicators.twoStage5.colorRed}, ${colorIndicators.twoStage5.colorGreen}, ${colorIndicators.twoStage5.colorBlue})`}
                            strokeWidth={5}
                          />
                          <Line //arrow
                            points={[895, 280, 905, 280, 900, 285]}
                            strokeWidth={5}
                            stroke="black"
                            closed={true}
                          />
                          {/* Exchanger 2 */}
                          <Circle
                            x={900}
                            y={327}
                            width={75}
                            fill={`rgb(${colorIndicators.exchanger2.colorRed}, ${colorIndicators.exchanger2.colorGreen}, ${colorIndicators.exchanger2.colorBlue})`}
                            stroke={'rgba(20, 125, 100, 1)'}
                          />
                        </Layer>
                      )}
                    </Stage>
                  </div>
                  <div className="report-accordion-container">
                    <Accordion
                      elevation={0}
                      classes={{}}
                      expanded={expanded === REPORT_ACCORDION.TOWER_TOP}
                      onChange={handleChange(REPORT_ACCORDION.TOWER_TOP)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
                        {getRiskIndicatorIcons(iconIndicators.towerTopTempDiff)}
                        <Typography className="report-accordion-header">Tower top T / ΔT</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Water dew point</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.waterDewPointTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.waterDewPointTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].towerTop.waterDewPointTempDiff)}
                              {Math.round(primaryReportData[reportDate].towerTop.waterDewPointTempDiff)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Ammonia salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.ammoniaSaltTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.ammoniaSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].towerTop.ammoniaSaltTempDiff)}
                              {Math.round(primaryReportData[reportDate].towerTop.ammoniaSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        {(unitType !== 'coker' && unitType !== 'fcc') &&
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Neutralizer salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.neutralizerSaltTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.neutralizerSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].towerTop.neutralizerSaltTempDiff)}
                              {Math.round(primaryReportData[reportDate].towerTop.neutralizerSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        }
                        {(unitType === 'coker' || unitType === 'fcc') &&
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Relative Humidity</span>                     
                            <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].towerTop.relativeHumidity)}%
                            </span>
                        </div>
                        }
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Tramp amine salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.trampAmineSaltTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.trampAmineSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].towerTop.trampAmineSaltTempDiff)}
                              {Math.round(primaryReportData[reportDate].towerTop.trampAmineSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Steam amine salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.steamAmineSaltTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.steamAmineSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].towerTop.steamAmineSaltTempDiff)}
                              {Math.round(primaryReportData[reportDate].towerTop.steamAmineSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      elevation={0}
                      classes={{}}
                      expanded={expanded === REPORT_ACCORDION.EXCHANGER}
                      onChange={handleChange(REPORT_ACCORDION.EXCHANGER)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
                        {getRiskIndicatorIcons(iconIndicators.exchangerTempDiff)}
                        <Typography className="report-accordion-header">Exchanger T / ΔT</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Water dew point</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].towerTop.waterDewPointTemp,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].towerTop.waterDewPointTemp)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Ammonia salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].dewPoint.ammoniaSaltTempDP,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].dewPoint.ammoniaSaltTempDP)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].dewPoint.ammoniaSaltTempDiffDP)}
                              {Math.round(primaryReportData[reportDate].dewPoint.ammoniaSaltTempDiffDP)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        {(unitType !== 'coker' && unitType !== 'fcc') &&
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Neutralizer salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].dewPoint.neutralizerSaltTempDP,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].dewPoint.neutralizerSaltTempDP)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].dewPoint.neutralizerSaltTempDiffDP)}
                              {Math.round(primaryReportData[reportDate].dewPoint.neutralizerSaltTempDiffDP)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        }

                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Tramp amine salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].dewPoint.trampAmineSaltTempDP,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].dewPoint.trampAmineSaltTempDP)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].dewPoint.trampAmineSaltTempDiffDP)}
                              {Math.round(primaryReportData[reportDate].dewPoint.trampAmineSaltTempDiffDP)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label">Steam amine salt</span>
                          {Math.round(
                            convertToFarenheit(
                              ionicInput.overheadConditions.OHTemp_UOM,
                              primaryReportData[reportDate].dewPoint.steamAmineSaltTempDP,
                              false
                            )
                          ) < -40 ? (
                            <span className="accordion-body-val">N/A</span>
                          ) : (
                            <span className="accordion-body-val">
                              {Math.round(primaryReportData[reportDate].dewPoint.steamAmineSaltTempDP)} {'°' + defaultUOM.temp_UOM} /{' '}
                              {getTempSign(primaryReportData[reportDate].dewPoint.steamAmineSaltTempDiffDP)}
                              {Math.round(primaryReportData[reportDate].dewPoint.steamAmineSaltTempDiffDP)} {'°' + defaultUOM.temp_UOM}
                            </span>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    {configType === '2A' || configType === '2B' || configType === '2C' ? (
                      <>
                        <Accordion
                          elevation={0}
                          classes={{}}
                          expanded={expanded === REPORT_ACCORDION.HOT_DRUM}
                          onChange={handleChange(REPORT_ACCORDION.HOT_DRUM)}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
                            {getRiskIndicatorIcons(iconIndicators.hotDrumTempDiff)}
                            <Typography className="report-accordion-header">Hot Drum T / ΔT</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="report-accordion-body">
                              <span className="accordion-body-label">Water dew point</span>
                              {Math.round(
                                convertToFarenheit(
                                  ionicInput.overheadConditions.OHTemp_UOM,
                                  primaryReportData[reportDate].hotDrum.waterDewPointTemp,
                                  false
                                )
                              ) < -40 ? (
                                <span className="accordion-body-val">N/A</span>
                              ) : (
                                <span className="accordion-body-val">
                                  {Math.round(primaryReportData[reportDate].hotDrum.waterDewPointTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                                  {getTempSign(primaryReportData[reportDate].hotDrum.waterDewPointTempDiff)}
                                  {Math.round(primaryReportData[reportDate].hotDrum.waterDewPointTempDiff)} {'°' + defaultUOM.temp_UOM}
                                </span>
                              )}
                            </div>
                            <div className="report-accordion-body">
                              <span className="accordion-body-label">Ammonia salt</span>
                              {Math.round(
                                convertToFarenheit(
                                  ionicInput.overheadConditions.OHTemp_UOM,
                                  primaryReportData[reportDate].hotDrum.ammoniaSaltTemp,
                                  false
                                )
                              ) < -40 ? (
                                <span className="accordion-body-val">N/A</span>
                              ) : (
                                <span className="accordion-body-val">
                                  {Math.round(primaryReportData[reportDate].hotDrum.ammoniaSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                                  {getTempSign(primaryReportData[reportDate].hotDrum.ammoniaSaltTempDiff)}
                                  {Math.round(primaryReportData[reportDate].hotDrum.ammoniaSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                                </span>
                              )}
                            </div>
                            {(unitType !== 'coker' && unitType !== 'fcc') &&
                            <div className="report-accordion-body">
                              <span className="accordion-body-label">Neutralizer salt</span>
                              {Math.round(
                                convertToFarenheit(
                                  ionicInput.overheadConditions.OHTemp_UOM,
                                  primaryReportData[reportDate].hotDrum.neutralizerSaltTemp,
                                  false
                                )
                              ) < -40 ? (
                                <span className="accordion-body-val">N/A</span>
                              ) : (
                                <span className="accordion-body-val">
                                  {Math.round(primaryReportData[reportDate].hotDrum.neutralizerSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                                  {getTempSign(primaryReportData[reportDate].hotDrum.neutralizerSaltTempDiff)}
                                  {Math.round(primaryReportData[reportDate].hotDrum.neutralizerSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                                </span>
                              )}
                            </div>
                            }

                            <div className="report-accordion-body">
                              <span className="accordion-body-label">Tramp amine salt</span>
                              {Math.round(
                                convertToFarenheit(
                                  ionicInput.overheadConditions.OHTemp_UOM,
                                  primaryReportData[reportDate].hotDrum.trampAmineSaltTemp,
                                  false
                                )
                              ) < -40 ? (
                                <span className="accordion-body-val">N/A</span>
                              ) : (
                                <span className="accordion-body-val">
                                  {Math.round(primaryReportData[reportDate].hotDrum.trampAmineSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                                  {getTempSign(primaryReportData[reportDate].hotDrum.trampAmineSaltTempDiff)}
                                  {Math.round(primaryReportData[reportDate].hotDrum.trampAmineSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                                </span>
                              )}
                            </div>
                            <div className="report-accordion-body">
                              <span className="accordion-body-label">Steam amine salt</span>
                              {Math.round(
                                convertToFarenheit(
                                  ionicInput.overheadConditions.OHTemp_UOM,
                                  primaryReportData[reportDate].hotDrum.steamAmineSaltTemp,
                                  false
                                )
                              ) < -40 ? (
                                <span className="accordion-body-val">N/A</span>
                              ) : (
                                <span className="accordion-body-val">
                                  {Math.round(primaryReportData[reportDate].hotDrum.steamAmineSaltTemp)} {'°' + defaultUOM.temp_UOM} /{' '}
                                  {getTempSign(primaryReportData[reportDate].hotDrum.steamAmineSaltTempDiff)}
                                  {Math.round(primaryReportData[reportDate].hotDrum.steamAmineSaltTempDiff)} {'°' + defaultUOM.temp_UOM}
                                </span>
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ) : null}
                    <Accordion
                      elevation={0}
                      classes={{}}
                      expanded={expanded === REPORT_ACCORDION.OVERHEAD_LINE}
                      onChange={handleChange(REPORT_ACCORDION.OVERHEAD_LINE)}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
                        {getRiskIndicatorIcons(iconIndicators.overheadLine)}

                        <Typography className="report-accordion-header">Overhead Line</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">Required Wash Rate</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].overHeadLine.requiredWashRate)} {defaultUOM.washRate_UOM}
                          </span>
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">Wash Rate Excess Over Saturation</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].overHeadLine.washRateCondensedPerc * 100)}%
                          </span>
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">Velocity</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].overHeadLine.velocity)} {defaultUOM.velocity_UOM}
                          </span>
                        </div>
                        {(unitType === 'coker' || unitType === 'fcc') &&
                        <>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">% of Wash Rate Condensed</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].dewPoint.nh4HSInDrumWaterPercentage * 100)}%
                          </span>
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">% NH4HS after wash</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].overHeadLine.nh4HSAfterWashPerc * 100) / 100}%
                          </span>
                        </div>
                        </>
                        }
                        {(unitType !== 'coker' && unitType !== 'fcc') &&
                        <>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">Neutralizer Salt Formation Temp (After Injection)</span>
                          <span className="accordion-body-val">
                            {Math.round(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempOL)} {'°' + defaultUOM.temp_UOM}
                          </span>
                        </div>
                        <div className="report-accordion-body">
                          <span className="accordion-body-label-overhead">Neutralizer Salt ΔT (After Injection)</span>
                          <span className="accordion-body-val">
                            {getTempSign(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempDiffOL)}
                            {Math.round(primaryReportData[reportDate].overHeadLine.neutralizerSaltTempDiffOL)} {'°' + defaultUOM.temp_UOM}{' '}
                          </span>
                        </div>
                        </>
                        }
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="risk-indicator-container">
                  {/* @ts-ignore */}
                  <LightTooltip title={corrosionRiskTooltip()}></LightTooltip>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
