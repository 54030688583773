import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  summary: [],
};

const createFavoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    createSummaryResponse(state, action) {
      state.summary = action.payload;
    },
  },
});
export const createFavoriteResponseAction = createFavoriteSlice.actions;
export default createFavoriteSlice;
