import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  facility: {},
};

const createConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    createConfig(state, action) {
      state.facility = action.payload;
    },
  },
});
export const createConfigAction = createConfigSlice.actions;
export default createConfigSlice;
