/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { BhButton, BhSpinner } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridRowParams,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import React, { useLayoutEffect, useState } from 'react';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import WarningIcon from '@mui/icons-material/Warning';
import './NewSimulation.scss';
import { format } from 'date-fns-tz';
import { Alert, Backdrop, Stack, TablePaginationProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
declare const window: any;

export default function DraftListing(props: any) {
  const navigate = useNavigate();
  const [simulationDraftData, setSimulationDraftData] = useState<any>([]);
  const [draftHeader, setDraftHeader] = useState<string>('');
  const [isSubmitDraft, setSubmitDraft] = useState<boolean>(false);
  const [isResultData, setResultData] = useState<any>([]);
  const [isNewSimulationData, setNewSimulationData] = useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalLimitExceed, setTotalLimitExceed] = useState<boolean>(false);
  const [unitType, setUnitType] = useState<string>('');
  const [isWithDesalter, setWithDesalter] = useState(false);

  const ROW_WIDTH = 195;
  const ROW_FLEX = 1;
  const ROW_FLEX_ZERO = 0;
  const ROW_CLASS = 'grid-header';

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: ROW_CLASS, flex: ROW_FLEX },
    {
      field: 'date',
      headerName: 'Date',
      width: ROW_WIDTH,
      headerClassName: ROW_CLASS,
      type: 'date',
      renderCell: (params) => renderDate(params.row?.date),
      flex: ROW_FLEX_ZERO,
    },
    {
      field: 'crudecharge',
      headerName: (unitType !== 'coker' && unitType !== 'fcc') ? 'Crude Charge' : 'Unit Charge',
      width: ROW_WIDTH,
      sortable: true,
      headerClassName: ROW_CLASS,
      renderCell: (params) => renderCrudeCharge(params.row),
      flex: ROW_FLEX,
    },
    { field: 'brineph', headerName: 'Brine pH', width: 195, sortable: true, headerClassName: 'grid-header', flex: 1 },
    {
      field: 'overheadtemp',
      headerName: 'OH Temp',
      width: ROW_WIDTH,
      sortable: true,
      headerClassName: ROW_CLASS,
      renderCell: (params) => renderOhTemp(params.row),
      flex: ROW_FLEX,
    },
    {
      field: 'overheadpress',
      headerName: 'OH Press',
      width: ROW_WIDTH,
      sortable: true,
      headerClassName: ROW_CLASS,
      renderCell: (params) => renderOhPress(params.row),
      flex: ROW_FLEX,
    },
    {
      field: 'neutrorate',
      headerName: 'Neutralizer Rate',
      width: ROW_WIDTH,
      sortable: true,
      headerClassName: 'grid-header',
      renderCell: (params) => renderNeutroRate(params.row),
      flex: ROW_FLEX,
    },
    {
      field: 'chloride',
      headerName: 'Chloride',
      width: 185,
      sortable: true,
      headerClassName: ROW_CLASS,
      flex: ROW_FLEX,
    },
    {
      field: 'ammonia',
      headerName: 'Ammonia',
      width: 185,
      sortable: true,
      headerClassName: ROW_CLASS,
      flex: ROW_FLEX,
    },
    {
      field: 'sulfide',
      headerName: 'Sulfide',
      width: ROW_WIDTH,
      sortable: true,
      headerClassName: ROW_CLASS,
      flex: 1,
    },
    { field: 'trampamine', headerName: 'Tramp Amine', width: ROW_WIDTH, sortable: true, headerClassName: ROW_CLASS, flex: ROW_FLEX },
  ];

  const { startDate, endDate, submitClickCounter } = props;

  useLayoutEffect(() => {
    let unitType;
    let isWithDesalter:any;
      //set unit type from local storage
      let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
      if (configFromStorage && configFromStorage.refinery) {
        if (configFromStorage.refinery.unit && configFromStorage.refinery.unit[0]) {
          unitType = configFromStorage.refinery.unit[0].unitType.trim().toLowerCase();
          if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
            isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false;
          }
          else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
            isWithDesalter = configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false;
          else isWithDesalter = false;
          setUnitType(unitType);
          setWithDesalter(isWithDesalter);
        }
      }
    setIsLoading(true);
    if (submitClickCounter > 0) {
      if (startDate && endDate && startDate !== '' && endDate !== '' && startDate !== 'aN-undefined-NaN' && endDate !== 'aN-undefined-NaN')
        filterDraftData(unitType, isWithDesalter);
      else draftTableAPI(unitType, isWithDesalter);
    } else draftTableAPI(unitType, isWithDesalter);
  }, [submitClickCounter,localStorage.getItem('configId')]);

  const renderCrudeCharge = (rowCrude: any) => {
    if (!rowCrude?.crudecharge) {
      return 'NA';
    } else return rowCrude?.crudecharge + ' ' + rowCrude?.crudechargeunit;
  };
  const renderOhTemp = (rowTemp: any) => {
    if (!rowTemp?.overheadtemp) {
      return '';
    } else return rowTemp?.overheadtemp + ' ' + rowTemp?.overheadtempunit;
  };
  const renderOhPress = (rowHead: any) => {
    if (!rowHead?.overheadpress) {
      return '';
    } else return rowHead?.overheadpress + ' ' + rowHead?.overheadpressunit;
  };
  const renderNeutroRate = (rowRate: any) => {
    if (!rowRate?.neutrorate) {
      return '';
    } else return rowRate?.neutrorate + ' ' + rowRate?.neutrorateunit;
  };

  const renderDate = (date: any) => {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'dd-MMM-yyyy');
  };
  const filterDraftData = (unitType : string, isWithDesalter:boolean) => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    if (Object.keys(isResultData).length > 0) {
      isResultData.forEach((item: any) => {
        let formattedDate = format(new Date(item.ionicInput[0].inputDate), 'dd-MMM-yyyy');
        if (new Date(formattedDate) >= new Date(startDate) && new Date(formattedDate) <= new Date(endDate)) {
          payLoad.data.push({
            id: item.ionicInput[0].simulationId,
            date: new Date(item.ionicInput[0].inputDate),
            crudecharge: (unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? item.ionicInput[0].desalter.crudeCharge.toFixed(3): null,
            crudechargeunit: (unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? item.ionicInput[0].desalter.crudeCharge_UOM: null,
            brineph: (unitType !=='coker' && unitType !=='fcc') ? item.ionicInput[0].desalter.brinepHData.brinepH.toFixed(1): null,
            overheadtemp: item.ionicInput[0].overheadConditions.OHTemp ? item.ionicInput[0].overheadConditions.OHTemp.toFixed() : '',
            overheadtempunit: item.ionicInput[0].overheadConditions.OHTemp
              ? item.ionicInput[0].overheadConditions.OHTemp_UOM === 'C'
                ? '°C'
                : '°F'
              : '',
            overheadpress: item.ionicInput[0].overheadConditions.OHPress ? item.ionicInput[0].overheadConditions.OHPress.toFixed(3) : '',
            overheadpressunit: item.ionicInput[0].overheadConditions.OHPress ? item.ionicInput[0].overheadConditions.OHPress_UOM : '',
            neutrorate: (unitType !=='coker' && unitType !=='fcc') ? (item.ionicInput[0].overheadWater.neutralizerRate ? item.ionicInput[0].overheadWater.neutralizerRate.toFixed(1) : '') : null,
            neutrorateunit: (unitType !=='coker' && unitType !=='fcc') ? (item.ionicInput[0].overheadWater.neutralizerRate ? item.ionicInput[0].overheadWater.neutralizerRate_UOM : '') : null,
            chloride:item.overheadWater.clData && item.overheadWater.clData.cl ? item.overheadWater.clData.cl.toFixed(1) : '',
            ammonia:item.overheadWater.NH3Data && item.overheadWater.NH3Data.NH3 ? item.overheadWater.NH3Data.NH3.toFixed(1) : '',
            sulfide:item.overheadWater.sulfide ? item.overheadWater.sulfide.toFixed(1) : '',
            trampamine: item.ionicInput[0].overheadWater.trampAmineData.trampAmine,
          });
        }
      });
      payLoad.length = payLoad.data.length;
      setSimulationDraftData(payLoad);
    }
    let tableHeader = payLoad.data.length + ' Input(s)';
    setDraftHeader(tableHeader);
    setIsLoading(false);
  };

  const disableCheckBox: (params: GridRowParams) => boolean = (params) => {
    let simulationDataFromStorage = localStorage.getItem('simulationData');
    let isStorageEmpty: boolean = false;
    let resultFlag: boolean = false;
    if (typeof simulationDataFromStorage === 'undefined' || simulationDataFromStorage === null) isStorageEmpty = true;
    if (isStorageEmpty === false) {
      //disable checkboxes if simulation input already there in local storage
      JSON.parse(simulationDataFromStorage!).forEach((item: any) => {
        if (parseInt(item.simulationId) === params.id) resultFlag = true;
      });
    }
      //disable simulation input based on the user sleected mode. if sandbox mode is on, 
      //then sandbox simulation inputs should be enabled and vice versa
      if(simulationDraftData && simulationDraftData.data && simulationDraftData.data.length){
        simulationDraftData.data.forEach((draftItem:any) => {
          if(draftItem.sandBoxModelDetails){
            if(draftItem.sandBoxModelDetails.modeType === 'Actual' && localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' && parseInt(draftItem.id) === params.id){
              resultFlag = true;
            }
            if(draftItem.sandBoxModelDetails.modeType === 'SandBox' && (!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && parseInt(draftItem.id) === params.id){
              resultFlag = true;
            }
          }
        });
      }
    return resultFlag;
  };

  const draftTableAPI = (unitType : string, isWithDesalter:boolean) => {
    let subUnitIdAPI: any;
    let refineryObj: any = {};
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    refineryObj = localStorage.getItem('defaultConfig');
    subUnitIdAPI = JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].subUnitId;
    let simulationDataFromStorage = localStorage.getItem('simulationData');
    let isStorageEmpty: boolean = false;
    if (typeof simulationDataFromStorage === 'undefined' || simulationDataFromStorage === null) isStorageEmpty = true;
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/draft/` + subUnitIdAPI + `/` + localStorage.getItem('configId')!;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result && result.data && result.data.length) {
          setResultData(result.data);
          result.data.forEach((tableItem: any) => {
            //find if the input is already there in localstorage, if present disable the checkbox
            let index;
            if (isStorageEmpty === false)
              index = JSON.parse(simulationDataFromStorage!).findIndex(
                (storageItem: any) => parseInt(storageItem.simulationId) === parseInt(tableItem.ionicInput[0].simulationId)
              );
            payLoad.data.push({
              id: tableItem.ionicInput[0].simulationId,
              //index -1 means items is not present
              checked: isStorageEmpty ? false : index === -1 ? false : true,
              date: new Date(tableItem.ionicInput[0].inputDate),
              crudecharge: (unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? tableItem.ionicInput[0].desalter.crudeCharge.toFixed(3): null,
              crudechargeunit: (unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? tableItem.ionicInput[0].desalter.crudeCharge_UOM: null,
              brineph: (unitType !=='coker' && unitType !=='fcc') ? tableItem.ionicInput[0].desalter.brinepHData?.brinepH?.toFixed(1): null,
              overheadtemp: tableItem.ionicInput[0].overheadConditions.OHTemp ? tableItem.ionicInput[0].overheadConditions.OHTemp.toFixed() : '',
              overheadtempunit: tableItem.ionicInput[0].overheadConditions.OHTemp
                ? tableItem.ionicInput[0].overheadConditions.OHTemp_UOM === 'C'
                  ? '°C'
                  : '°F'
                : '',
              overheadpress: tableItem.ionicInput[0].overheadConditions.OHPress ? tableItem.ionicInput[0].overheadConditions.OHPress.toFixed(3) : '',
              overheadpressunit: tableItem.ionicInput[0].overheadConditions.OHPress ? tableItem.ionicInput[0].overheadConditions.OHPress_UOM : '',
              neutrorate: (unitType !=='coker' && unitType !=='fcc') ? (tableItem.ionicInput[0].overheadWater.neutralizerRate
                ? tableItem.ionicInput[0].overheadWater.neutralizerRate.toFixed(1)
                : '') : null,
              neutrorateunit: (unitType !=='coker' && unitType !=='fcc') ? (tableItem.ionicInput[0].overheadWater.neutralizerRate ? tableItem.ionicInput[0].overheadWater.neutralizerRate_UOM : ''): null,
              chloride:tableItem.ionicInput[0].overheadWater.clData && tableItem.ionicInput[0].overheadWater.clData.cl ? tableItem.ionicInput[0].overheadWater.clData.cl.toFixed(1) : '',
              ammonia:tableItem.ionicInput[0].overheadWater.NH3Data && tableItem.ionicInput[0].overheadWater.NH3Data.NH3 ? tableItem.ionicInput[0].overheadWater.NH3Data.NH3.toFixed(1) : '',
              sulfide:tableItem.ionicInput[0].overheadWater.sulfide ? tableItem.ionicInput[0].overheadWater.sulfide.toFixed(1) : '',
              trampamine: tableItem.ionicInput[0].overheadWater.trampAmineData.trampAmine,
              sandBoxModelDetails:tableItem.sandBoxModelDetails
            });
          });
          payLoad.length = result.data.length;
        }
      })
      .finally(() => {
        setSimulationDraftData(payLoad);
        let tableHeader = payLoad.data.length + ' Input(s)';
        setDraftHeader(tableHeader);
        setIsLoading(false);
      });
  };
  const handleChecked = (ids: any) => {
    if (ids.length > 100) {
      setTotalLimitExceed(true);
      setTimeout(() => {
        setTotalLimitExceed(false);
      }, 2000);
      return;
    }
    //if any item selected
    if (ids && ids.length) {
      setTotalLimitExceed(false);
      let newSimulationArray: any = [];
      setSubmitDraft(true);
      ids.forEach((arrayItem: any) => {
        isResultData.forEach((element: any) => {
          if (parseInt(element.ionicInput[0].simulationId) === parseInt(arrayItem)) {
            newSimulationArray.push(element.ionicInput[0]);
            if(element.sandBoxModelDetails && element.sandBoxModelDetails.modeType === 'SandBox'){
            let simulationDetails={'name':element.sandBoxModelDetails.sandBoxSimulationName, 'comment':element.sandBoxModelDetails.sandBoxSimulationComments};
            localStorage.setItem('sandboxsimulation',JSON.stringify(simulationDetails));
            }
          }
        });
      });
      setNewSimulationData(newSimulationArray);
      setRowSelectionModel(ids);
    } else {
      setSubmitDraft(false);
      setRowSelectionModel(ids);
    }
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  const PaginationForDraft = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPaginationForDraft = (props: any) => {
    return <GridPagination ActionsComponent={PaginationForDraft} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlayForDraft = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };

  return (
    <React.Fragment>
      {isSubmitDraft ? (
        <div>
          <BhButton
            type="Primary"
            className="bhSubmitButton"
            label="Move to New Simulation"
            onClick={() => {
              let checkLocal = JSON.parse(localStorage.getItem('simulationData')!);
              if (checkLocal === null) {
                localStorage.setItem('simulationData', JSON.stringify(isNewSimulationData));
                navigate('/new-simulation');
              } else {
                localStorage.setItem('simulationData', JSON.stringify([...checkLocal, ...isNewSimulationData]));
                navigate('/new-simulation');
              }
            }}
          ></BhButton>
        </div>
      ) : (
        ''
      )}
      <div style={{ width: '100%' }} data-testid="data-grid-list">
          <div style={{ height: simulationDraftData?.data?.length ? 500 : 150, width: '100%' }} data-testid="data-grid-items">
            <DataGrid
              pagination
              slots={{
                pagination: CustomPaginationForDraft,
                noRowsOverlay: CustomNoRowsOverlayForDraft,
              }}
              rows={simulationDraftData?.data?.length ? simulationDraftData?.data : []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: 'date', sort: 'desc' }],
                },
              }}
              pageSizeOptions={[5, 10, 15, 20, 25]}
              columnVisibilityModel={{ id: false, brineph:(unitType === 'coker' || unitType === 'fcc') ? false:true, neutrorate:(unitType === 'coker' || unitType === 'fcc') ? false:true, chloride:(unitType !=='coker' && unitType !=='fcc') ? false:true, ammonia:(unitType !=='coker' && unitType !=='fcc') ? false:true, sulfide:(unitType !=='coker' && unitType !=='fcc') ? false:true }}
              disableColumnMenu
              isRowSelectable={(params: GridRowParams) => !disableCheckBox(params)}
              checkboxSelection
              disableRowSelectionOnClick
              showCellVerticalBorder
              showColumnVerticalBorder
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(ids) => {
                handleChecked(ids);
              }}
              rowHeight={44}
              columnHeaderHeight={42}
            />
          </div>
      </div>
      {totalLimitExceed && (
        <Alert icon={<WarningIcon />} severity="error" className="alert-box-error total-limit" id="alert-message">
          Maximum 100 inputs only allowed
        </Alert>
      )}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
          open={isLoading}
          onClick={handleClose}
        >
          {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
        </Backdrop>
      </div>
    </React.Fragment>
  );
}
