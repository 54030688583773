export const COLOR_MAX = 159;
export const BEFORE_WASH = 'before wash water';
export const AFTER_WASH = 'with or after wash water';
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const REPORT_ACCORDION = {
  TOWER_TOP: 'Tower Top',
  TOWER_TOP_DELTA: 'Tower Top ΔT',
  HOT_DRUM: 'Hot drum',
  HOT_DRUM_DELTA: 'Hot drum ΔT',
  EXCHANGER: 'Exchanger',
  EXCHANGER_DELTA: 'Exchanger ΔT',
  OVERHEAD_LINE: 'Overhead Line',
};

export const TWO_STAGE_FROM_TECH = {
  TEXT_TUBE_SKIN: 30,
};

export const WASH_RATE_UNITS = {
  M3HRTOGPM: 4.402881245487,
  M3DAYTOGPM: 0.183453,
  LPMTOGPM: 264172874,
};
