/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useLayoutEffect, useState } from 'react';
import './ManageUserModal.scss';
import {
  BhButton,
  BhDialog,
  BhDropdown,
  BhFormMessage,
  BhModal,
  BhSpinner,
  BhTextInput,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import SearchIcon from '@mui/icons-material/Search';
import apiRequest from '../../../services/api-helper';
import { APIEnum } from '../../../constants/api-enum';
import { sortWithKey } from '../../../utils/utils';
declare const window: any;

export default function ManageUserModal(props: any) {
  const [ctaData, setCtaData] = useState<any>({});
  const { isAddUser, isOpen, closeModal, getUserData, roles, allCustomers, userInfo } = props;
  const [customers, setCustomers] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [refineries, setRefineries] = useState<any>([]);
  const [customersFromApi, setCustomersFromApi] = useState<any>([]);
  const [refineriesFromApi, setRefineriesFromApi] = useState<any>([]);
  const [userData, setUserData] = useState<any>({});
  const [editedUserData, setEditedUserData] = useState<any>({});
  const [userEmail, setUserEmail] = useState<string>('');
  const [isVerifiedUser, setVerifiedUser] = useState<boolean>(false);
  const [isAccountManager, setAccountManager] = useState<boolean>(false);
  const [isCustomerAdded, setCustomerAdded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [isValid, setValidFlag] = useState(false);
  const [fullName, setFullName] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [statusItems, setStatusItems] = useState<any>();
  const [isRemoveEntitlement, setRemoveEntitlement] = useState<boolean>(false);
  const [isNoEntitlementFound, setNoEntitlementFound] = useState<boolean>(false);

  const StyledList = styled(List)({
    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
      backgroundColor: '#c6f7e2',
      '&, & .MuiListItemIcon-root': {
        color: '#c6f7e2',
      },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
      backgroundColor: '#ebefee',
      '&, & .MuiListItemIcon-root': {
        color: '#ebefee',
      },
    },
    '&& .MuiList-padding, && .MuiList-root': {
      backgroundColor: 'white',
      '&, & .MuiListItemIcon-root': {
        color: 'white',
      },
    },
  });

  useLayoutEffect(() => {
    let statusItems = [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ];
    setStatusItems(statusItems);
    let ctaArray = [];
    let btnLabelName = isAddUser ? 'Add new user' : 'Apply';
    ctaArray.push(
      {
        type: 'ghost',
        label: 'Back',
        key: 'btnBack',
      },
      {
        type: 'primary',
        label: btnLabelName,
        key: 'btnUserSave',
        id: 'btnUserSave',
        isDisabled: isAddUser && !isVerifiedUser ? true : false,
      }
    );
    setCtaData(JSON.stringify(ctaArray));
    //create user
    if (isAddUser) {
      //in case of admin role need to display messgae instead of showing cusotmers
      let customers: any = [{ id: 1, label: 'All the entitlements will be mapped by default for Admin and SME', selected: false }];
      setCustomers(customers);
      setCustomersFromApi(customers);
      setRefineries(customers);
      setRefineriesFromApi(customers);
    }
    //manage user
    else {
      setEditedUserData(userInfo);
      if (userInfo && userInfo.userRole && userInfo.userRole.roleName) {
        userInfo.userRole.roleName.toLowerCase() === 'account manager' ? setAccountManager(true) : setAccountManager(false);
      }
      //take the entitlements mapped
      if (userInfo && userInfo.userEntitlements && userInfo.userEntitlements.length) {
        mapEntitlementsForManageUser();
      }
      //in case if no entitlements found for the user
      else {
        if (userInfo && userInfo.userRole && userInfo.userRole.roleName) {
          if (userInfo.userRole.roleName.toLowerCase() === 'admin' || userInfo.userRole.roleName.toLowerCase() === 'sme') {
            //in case of admin role need to display messgae instead of showing cusotmers
            let customers: any = [{ id: 1, label: 'All the entitlements will be mapped by default for Admin and SME', selected: false }];
            setCustomers(customers);
            setCustomersFromApi(customers);
            setRefineries(customers);
            setRefineriesFromApi(customers);
          }
        }
      }
    }
    //set error and success flags to false in component unmount
    return () => {
      setVerifiedUser(false);
      setAccountManager(false);
      setSuccessFlag(false);
      setErrorFlag(false);
      setValidFlag(false);
      setFullNameError(false);
      let cusotmerArray = customers;
      cusotmerArray.forEach((item: any) => {
        item.selected = false;
      });
      setEditedUserData({});
      setEmailError(false);
      setEmailValidError(false);
      setCustomers([]);
      setCustomersFromApi([]);
      setRefineries([]);
      setRefineriesFromApi([]);
      setRemoveEntitlement(false);
      setNoEntitlementFound(false);
      setSelectedCustomer({});
    };
  }, [props]);

  const mapEntitlementsForManageUser = () => {
    let customers: any = [];
    let refineries: any = [];
    let mappedRefineries: any = [];
    userInfo.userEntitlements.forEach((entitlementItem: any, index: number) => {
      if (customers.length === 0 || (customers.length && customers.findIndex((m: any) => m.id === entitlementItem.companyId) === -1)) {
        customers.push({
          label: entitlementItem.companyName,
          value: entitlementItem.companyId,
          id: entitlementItem.companyId,
          selected: index === 0 ? true : false,
        });
      }
      if (entitlementItem.refinery && entitlementItem.refinery.length) {
        entitlementItem.refinery.forEach((refineryItem: any) => {
          if (refineries.length === 0 || (refineries.length && refineries.findIndex((m: any) => m.id === refineryItem.refineryId) === -1)) {
            if (refineryItem.isEntitled) {
              mappedRefineries.push({
                label: refineryItem.refineryName,
                value: refineryItem.refineryId,
                id: refineryItem.refineryId,
                custId: entitlementItem.companyId,
                checked: true,
              });
            }
            if (refineries.length === 0 || entitlementItem.companyId === customers[0].id) {
              refineries.push({
                label: refineryItem.refineryName,
                value: refineryItem.refineryId,
                id: refineryItem.refineryId,
                custId: entitlementItem.companyId,
                checked: refineryItem.isEntitled,
              });
            }
          }
        });
      }
    });
    let sortedCustomers = sortWithKey(customers, 'label', 'asc');
    setCustomers(sortedCustomers);
    setCustomersFromApi(sortedCustomers);
    let sortedRefineries = sortWithKey(refineries, 'checked', 'dsc');
    let checkedRefineries=sortedRefineries.filter((m:any)=>m.checked);
    let uncheckedRefineries=sortedRefineries.filter((m:any)=>!m.checked);
    let unchekcedSorted=sortWithKey(uncheckedRefineries, 'label', 'asc');
    let finalSortedRefineries=checkedRefineries.concat(unchekcedSorted);
    setRefineries(finalSortedRefineries);
    setRefineriesFromApi(finalSortedRefineries);
    //setMappedRefineries(mappedRefineries);
  };
  const validateEmail = (emailField: string) => {
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
      setEmailValidError(true);
      return false;
    } else {
      if (emailField.indexOf('@bakerhughes.com', emailField.length - '@bakerhughes.com'.length) !== -1) {
        setEmailValidError(false);
        return true;
      } else {
        setEmailValidError(true);
        return false;
      }
    }
  };
  const handleEmailChange = (event: any) => {
    if (!event.detail) {
      setEmailError(true);
      setEmailValidError(false);
      setUserEmail('');
    } else {
      setEmailError(false);
      validateEmail(event.detail);
      setUserEmail(event.detail);
    }
  };

  const loadCustomers = () => {
    setAccountManager(true);
    let sortedCustomers = sortWithKey(allCustomers, 'label', 'asc');
    setCustomers(sortedCustomers);
    setCustomersFromApi(sortedCustomers);
    setRefineries([]);
    setRefineriesFromApi([]);
  };

  const handleVerifyUserClick = () => {
    if (userEmail && !emailError && !emailValidError) {
      let newUserObj;
      let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/user/${userEmail}`;
      apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
        .then((response) => {
          if (response) {
            setVerifiedUser(false);
            document.querySelector(`bh-button[data-key="btnUserSave"]`)!.setAttribute('disabled', 'true');
            //user already mappped with roles
            if (response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
              setValidFlag(true);
              setMessage('User already exists. To edit Profile, go to Manage User!');
            } else if (response.responseStatus && response.responseStatus.status.toLowerCase() === 'error') {
              setErrorFlag(true);
              if (response.responseStatus.errorResponse && response.responseStatus.errorResponse.length) {
                setMessage(response.responseStatus.errorResponse[0].message);
              }
            }
            setTimeout(() => {
              closeModal();
              setUserData({});
              setVerifiedUser(false);
              setUserEmail('');
              setFullName('');
            }, 4000);
          }
          //case when user not found in db, need to enable full name textbox and other input controls
          else {
            document.querySelector(`bh-button[data-key="btnUserSave"]`)!.setAttribute('disabled', 'false');
            setVerifiedUser(true);
            newUserObj = {
              userDetails: {
                firstName: '',
                lastName: '',
                email: userEmail,
              },
              userRole: {
                roleId: roles[0].id,
                roleName: roles[0].label,
              },
            };
            setUserData(newUserObj);
            setValidFlag(true);
            setMessage('User does not exists, please proceed with Add new user !');
            setTimeout(() => {
              setValidFlag(false);
              setMessage('');
            }, 3000);
            if (isAddUser) {
              loadCustomers();
            }
          }
        })
        .catch((error) => {
          setErrorFlag(true);
          setMessage(error.message);
          setTimeout(() => {
            closeModal();
            setUserData({});
            setVerifiedUser(false);
            setUserEmail('');
            setFullName('');
          }, 4000);
        });
    } else {
      if (!userEmail) setEmailError(true);
    }
  };

  const handleStatusDropDownChange = (event: any) => {
    if (event.target.value) {
      let newUserObj;
      if (editedUserData && Object.keys(editedUserData).length > 0) {
        newUserObj = {
          ...editedUserData,
          userDetails: { ...editedUserData.userDetails },
          userRole: { ...editedUserData.userRole },
        };
        if (newUserObj && Object.keys(newUserObj.userDetails).length) {
          newUserObj.userDetails.isActive = event.target.selectedValue;
        }
        setEditedUserData(newUserObj);
      }
    }
  };

  const handleRoleDropDownChange = (event: any) => {
    if (event.target.selectedValue && event.target.value) {
      //create user
      if (isAddUser) {
        let newUserObj;
        if (userData && Object.keys(userData).length > 0) {
          newUserObj = {
            ...userData,
            userDetails: { ...userData.userDetails },
            userRole: { ...userData.userRole },
          };
          if (event.target.value.toLowerCase() === 'account manager') {
            setAccountManager(true);
            let sortedCustomers = sortWithKey(allCustomers, 'label', 'asc');
            setCustomers(sortedCustomers);
            setCustomersFromApi(sortedCustomers);
            setRefineries([]);
            setRefineriesFromApi([]);
          } else {
            //in case of admin role need to display messgae instead of showing cusotmers
            let customers: any = [{ id: 1, label: 'All the entitlements will be mapped by default for Admin and SME', selected: false }];
            setCustomers(customers);
            setCustomersFromApi(customers);
            setRefineries(customers);
            setRefineriesFromApi(customers);
            setAccountManager(false);
            //need to remove userentitlement in case any entitlements mapped
            if (newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
              newUserObj.userEntitlements = [];
            }
          }
          if (newUserObj && newUserObj.userRole && newUserObj.userRole.roleId && newUserObj.userRole.roleName) {
            newUserObj.userRole.roleId = event.target.selectedValue;
            newUserObj.userRole.roleName = event.target.value;
          } else {
            let selectedRole = { roleId: event.target.selectedValue, roleName: event.target.value };
            newUserObj.userRole = selectedRole;
          }
        } else {
          newUserObj = {
            userRole: {
              roleId: event.target.selectedValue,
              roleName: event.target.value,
            },
          };
        }
        setUserData(newUserObj);
      }
      //manage user
      else {
        if (event.target.value.toLowerCase() === 'account manager') {
          setAccountManager(true);
          if (userInfo && userInfo.userEntitlements && userInfo.userEntitlements.length) {
            mapEntitlementsForManageUser();
          } else {
            setCustomers([]);
            setCustomersFromApi([]);
            setRefineries([]);
            setRefineriesFromApi([]);
          }
        } else {
          //in case of admin role need to display messgae instead of showing cusotmers
          let customers: any = [{ id: 1, label: 'All the entitlements will be mapped by default for Admin and SME', selected: false }];
          setCustomers(customers);
          setCustomersFromApi(customers);
          setRefineries(customers);
          setRefineriesFromApi(customers);
          setAccountManager(false);
        }
        //edit user role
        let newUserObj;
        if (editedUserData && Object.keys(editedUserData).length > 0) {
          newUserObj = {
            ...editedUserData,
            userDetails: { ...editedUserData.userDetails },
            userRole: { ...editedUserData.userRole },
          };
          if (newUserObj && newUserObj.userRole && newUserObj.userRole.roleId && newUserObj.userRole.roleName) {
            newUserObj.userRole.roleId = event.target.selectedValue;
            newUserObj.userRole.roleName = event.target.value;
          } else {
            let selectedRole = { roleId: event.target.selectedValue, roleName: event.target.value };
            newUserObj.userRole = selectedRole;
          }
        }
        setEditedUserData(newUserObj);
      }
    }
  };
  const fetchRefineryApi = async (id: any, isEdit: boolean) => {
    let refineries: any = [];
    let newRefineries: any = [];
    let status: boolean = false;
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/customers/${id}/refineries`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        if (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
          if (response.data && response.data.length) {
            response.data.forEach((refineryItem: any) => {
              //change status for the refineries already mapped for that customer for add user
              if (!isEdit) {
                let newUserObj;
                if (userData && Object.keys(userData).length > 0) {
                  newUserObj = {
                    ...userData,
                    userDetails: { ...userData.userDetails },
                    userRole: { ...userData.userRole },
                  };
                  if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
                    let index = newUserObj.userEntitlements.findIndex((m: any) => m.companyId === id);
                    if (index !== -1) {
                      let mappedRefineryIds = newUserObj.userEntitlements[index].refineryIds;
                      if (mappedRefineryIds.includes(refineryItem.refineryId)) status = true;
                      else status = false;
                    }
                  }
                }
              }
              refineries.push({
                label: refineryItem.refineryName,
                value: refineryItem.refineryId,
                id: refineryItem.refineryId,
                custId: id,
                checked: status,
              });
              isEdit &&
                newRefineries.push({ refineryId: refineryItem.refineryId, refineryName: refineryItem.refineryName, isEntitled: false });
            });
          }
          let sortedRefineries = sortWithKey(refineries, 'label', 'asc');
          setRefineries(sortedRefineries);
          setRefineriesFromApi(sortedRefineries);
        } else {
        }
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage(error.message);
      });

    return newRefineries;
  };

  //handle customer name change in the listbox
  const handleCustomerChange = (id: any, selected: boolean) => {
    if (id) {
      //change selected status in customers
      let cusotmerArray = customers;
      cusotmerArray.forEach((item: any) => {
        item.selected = false;
      });
      let custItem = cusotmerArray.filter((item: any) => item.id === id)[0];
      custItem.selected = !selected;
      setCustomers(cusotmerArray);
      setCustomersFromApi(cusotmerArray);
      setCustomerAdded((isCustomerAdded) => !isCustomerAdded);
      if (isAddUser) {
        let newUserObj;
        if (userData && Object.keys(userData).length > 0) {
          newUserObj = {
            ...userData,
            userDetails: { ...userData.userDetails },
            userRole: { ...userData.userRole },
          };
          if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
            if (!newUserObj.userEntitlements[0].companyId || newUserObj.userEntitlements[0].companyId === '') {
              newUserObj.userEntitlements[0].companyId = id;
            } else {
              let index = newUserObj.userEntitlements.findIndex((m: any) => m.companyId === id);
              if (index === -1) newUserObj.userEntitlements.push({ companyId: id, refineryIds: [] });
            }
          } else {
            newUserObj.userEntitlements = [
              {
                companyId: id,
                refineryIds: [],
              },
            ];
          }
        } else {
          newUserObj = {
            userEntitlements: [
              {
                companyId: id,
                refineryIds: [],
              },
            ],
          };
        }
        setUserData(newUserObj);
        fetchRefineryApi(id, false);
      }
      //manage user
      else {
        setRefineries([]);
        setRefineriesFromApi([]);
        let newUserObj;
        if (editedUserData && Object.keys(editedUserData).length > 0) {
          newUserObj = {
            ...editedUserData,
            userDetails: { ...editedUserData.userDetails },
            userRole: { ...editedUserData.userRole },
          };
          //filter refineries based ont eh selected customer from already mapped refineries
          if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
            let mappedRefineries: any = [];
            newUserObj.userEntitlements.forEach((entitlementItem: any) => {
              if (entitlementItem.refinery && entitlementItem.refinery.length) {
                entitlementItem.refinery.forEach((refineryItem: any) => {
                  if (entitlementItem.companyId === id) {
                    mappedRefineries.push({
                      label: refineryItem.refineryName,
                      value: refineryItem.refineryId,
                      id: refineryItem.refineryId,
                      custId: entitlementItem.companyId,
                      checked: refineryItem.isEntitled,
                    });
                  }
                });
              }
            });
            //sort refineries with checked first and then sort by ascending order
            let sortedRefineries = sortWithKey(mappedRefineries, 'checked', 'dsc');
            let checkedRefineries=sortedRefineries.filter((m:any)=>m.checked);
            let uncheckedRefineries=sortedRefineries.filter((m:any)=>!m.checked);
            let unchekcedSorted=sortWithKey(uncheckedRefineries, 'label', 'asc');
            let finalSortedRefineries=checkedRefineries.concat(unchekcedSorted);
            setRefineries(finalSortedRefineries);
            setRefineriesFromApi(finalSortedRefineries);
            setRefineries(sortedRefineries);
            setRefineriesFromApi(sortedRefineries);
            setCustomerAdded((isCustomerAdded) => !isCustomerAdded);
          }
          setEditedUserData(newUserObj);
        }
      }
    }
  };
  const requestCustomerSearch = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      const filteredCustomers = customersFromApi.filter((row: any) => {
        return row.label.toLowerCase().includes(event.target.value.trim().toLowerCase());
      });
      setCustomers(filteredCustomers);
    } else {
      setCustomers(customersFromApi);
    }
  };

  const requestRefinerySearch = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      const filteredRefineries = refineriesFromApi.filter((row: any) => {
        return row.label.toLowerCase().includes(event.target.value.trim().toLowerCase());
      });
      setRefineries(filteredRefineries);
    } else {
      setRefineries(refineriesFromApi);
    }
  };

  const handleRefinerySelection = (id: number, customerId: number, event: any) => {
    let newUserObj: any;
    if (id) {
      //change checked status in refineries
      let refineryArray = refineries;
      let refineryItem = refineryArray.filter((item: any) => item.id === id)[0];
      refineryItem.checked = event.target.checked;
      setRefineries(refineryArray);
      setRefineriesFromApi(refineryArray);
      if (isAddUser) {
        if (userData && Object.keys(userData).length > 0) {
          newUserObj = {
            ...userData,
            userDetails: { ...userData.userDetails },
            userRole: { ...userData.userRole },
            userEntitlements: [...userData.userEntitlements],
          };
          if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
            let index = newUserObj.userEntitlements.findIndex((m: any) => m.companyId === customerId);
            if (index === -1) {
              if (event.target.checked) newUserObj.userEntitlements.push({ companyId: customerId, refineryIds: [id] });
            } else {
              if (event.target.checked) newUserObj.userEntitlements[index].refineryIds.push(id);
              else {
                newUserObj.userEntitlements[index].refineryIds.pop(id);
              }
            }
          } else {
            if (event.target.checked)
              newUserObj = {
                userEntitlements: [
                  {
                    companyId: customerId,
                    refineryIds: [id],
                  },
                ],
              };
          }
        } else {
          if (event.target.checked)
            newUserObj = {
              userEntitlements: [
                {
                  companyId: customerId,
                  refineryIds: [id],
                },
              ],
            };
        }
        setUserData(newUserObj);
      } else {
        if (editedUserData && Object.keys(editedUserData).length > 0) {
          if (editedUserData.userEntitlements && editedUserData.userEntitlements.length) {
            newUserObj = {
              ...editedUserData,
              userDetails: { ...editedUserData.userDetails },
              userRole: { ...editedUserData.userRole },
              userEntitlements: [...editedUserData.userEntitlements],
            };
          } else {
            newUserObj = {
              ...editedUserData,
              userDetails: { ...editedUserData.userDetails },
              userRole: { ...editedUserData.userRole },
            };
          }
          if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
            let noEntitlementCount: number = 0;
            newUserObj.userEntitlements.forEach((element: any) => {
              let refineryObj = element.refinery && element.refinery.length && element.refinery.filter((m: any) => m.refineryId === id)[0];
              if (refineryObj && Object.keys(refineryObj).length) refineryObj.isEntitled = event.target.checked;
              if (element.refinery.findIndex((m: any) => m.isEntitled) === -1 && !event.target.checked) noEntitlementCount++;
            });
            if (noEntitlementCount === newUserObj.userEntitlements.length) setNoEntitlementFound(true);
            else setNoEntitlementFound(false);
          } else {
            setNoEntitlementFound(true);
          }
        }
        setEditedUserData(newUserObj);
      }
    }
  };
  const mapEditUserPayLoad = () => {
    let editUserPayLoad: any;
    if (editedUserData && Object.keys(editedUserData).length > 0) {
      let entitlement: any = [];
      if (editedUserData.userEntitlements && editedUserData.userEntitlements.length) {
        editedUserData.userEntitlements.forEach((entitleItem: any) => {
          let refineryIds: any = [];
          if (entitleItem.refinery && entitleItem.refinery.length) {
            entitleItem.refinery.forEach((refineryItem: any) => {
              refineryItem.isEntitled && refineryIds.push(refineryItem.refineryId);
            });
          }
          if (refineryIds && refineryIds.length) entitlement.push({ companyId: entitleItem.companyId, refineryIds: refineryIds });
        });
      }
      editUserPayLoad = { userDetails: editedUserData.userDetails, userRole: editedUserData.userRole, userEntitlements: entitlement };
    }
    return editUserPayLoad;
  };

  const checkRefineriesMapped = () => {
    let newUserObj: any;
    if (userData && Object.keys(userData).length > 0) {
      newUserObj = {
        ...userData,
        userDetails: { ...userData.userDetails },
        userRole: { ...userData.userRole },
        //userEntitlements: [...userData.userEntitlements],
      };
      let newEntitlementArray: any = [];
      if (newUserObj && newUserObj.userEntitlements && newUserObj.userEntitlements.length) {
        newUserObj.userEntitlements.forEach((element: any) => {
          if (element.refineryIds && element.refineryIds.length > 0) {
            newEntitlementArray.push(element);
          }
        });
        newUserObj.userEntitlements = newEntitlementArray;
      }
    }
    return newUserObj;
  };

  const manageUserUpdateApi = (payLoad: any) => {
    let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/usermanagement`;
    apiRequest(encodeURI(apiUrl), payLoad, APIEnum.PUT)
      .then((response) => {
        //user saved successfully
        if (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
          setSuccessFlag(true);
          setMessage('User details saved successfully');
          setTimeout(() => {
            closeModal();
            setEditedUserData({});
            getUserData();
          }, 1000);
        } else if (
          response &&
          response.responseStatus &&
          (response.responseStatus.status.toLowerCase() === 'error' ||
            response.responseStatus.status.toLowerCase() === 'bad request' ||
            response.responseStatus.status.toLowerCase() === 'no data')
        ) {
          setErrorFlag(true);
          if (response.responseStatus.errorResponse && response.responseStatus.errorResponse.length) {
            setMessage(response.responseStatus.errorResponse[0].message);
          }
        } else {
          setErrorFlag(true);
          setMessage('Oops! Something went wrong!');
        }
      })
      .catch((error: { message: any }) => {
        setErrorFlag(true);
        setMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setRemoveEntitlement(false);
      });
  };
  const handleCtaClick = (event: any) => {
    if (event.target.innerText === 'Back') {
      closeModal();
      setUserData({});
      setVerifiedUser(false);
      setUserEmail('');
      setFullName('');
      getUserData();
    } else if (event.target.innerText === 'Add new user') {
      let isDisabled = document.querySelector(`bh-button[data-key="btnUserSave"]`)!.getAttribute('disabled');
      if (isDisabled === 'false') {
        if (!emailError && !fullNameError && !emailValidError) {
          //remove entitlements if refineris not mapped
          let payLoadData = checkRefineriesMapped();
          let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/usermanagement`;
          apiRequest(encodeURI(apiUrl), payLoadData, APIEnum.POST)
            .then((response) => {
              //user saved successfully
              if (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
                setSuccessFlag(true);
                setMessage('User added successfully');
                setTimeout(() => {
                  closeModal();
                  setUserData({});
                  setVerifiedUser(false);
                  setUserEmail('');
                  setFullName('');
                  getUserData();
                }, 1000);
              } else if (
                (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'error') ||
                response.responseStatus.status.toLowerCase() === 'bad request' ||
                response.responseStatus.status.toLowerCase() === 'no data'
              ) {
                setErrorFlag(true);
                if (response.responseStatus.errorResponse && response.responseStatus.errorResponse.length) {
                  setMessage(response.responseStatus.errorResponse[0].message);
                }
              } else {
                setErrorFlag(true);
                setMessage('Oops! Something went wrong!');
              }
            })
            .catch((error: { message: any }) => {
              setErrorFlag(true);
              setMessage(error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          if (!fullName) setFullNameError(true);
        }
      }
    } else if (event.target.innerText === 'Apply') {
      if (editedUserData && Object.keys(editedUserData).length > 0) {
        let payLoad = mapEditUserPayLoad();
        if (!isNoEntitlementFound) {
          manageUserUpdateApi(payLoad);
        } else {
          setRemoveEntitlement(true);
        }
      }
    }
  };

  const handleBackDropClose = () => {
    setIsLoading(false);
  };

  const handleModalClose = (event: any) => {
    if (event.detail && event.detail.type === 'modal') {
      closeModal();
      setUserData({});
      setEditedUserData({});
      setVerifiedUser(false);
      setUserEmail('');
      setFullName('');
      getUserData();
    }
  };

  const handleSelectedCustomer = (event: any, newCustomer: any) => {
    if (newCustomer && Object.keys(newCustomer).length) {
      let newCustomerModified={ ...newCustomer, selected: true };
      setSelectedCustomer(newCustomerModified);
    } else {
      setSelectedCustomer({});
    }
    setErrorFlag(false);
    setMessage('');
  };
  const handleAddCustomerClick = () => {
    if (Object.keys(selectedCustomer).length) {
      let index = customers.findIndex((m: any) => m.id === selectedCustomer.id);
      if (index === -1) {
        let mappedCustomers = customers.map((obj:any) => {
          return { ...obj, selected: false };
        });
        mappedCustomers.unshift(selectedCustomer);
        setCustomers(mappedCustomers);
        setCustomersFromApi(mappedCustomers);
        setCustomerAdded((isCustomerAdded) => !isCustomerAdded);
        //refineries api call
        fetchRefineryApi(selectedCustomer.id, true).then(function (value) {
          let userObj;
          if (editedUserData && Object.keys(editedUserData).length > 0) {
            if (editedUserData.userEntitlements && editedUserData.userEntitlements.length) {
              userObj = {
                ...editedUserData,
                userDetails: { ...editedUserData.userDetails },
                userRole: { ...editedUserData.userRole },
                userEntitlements: [...editedUserData.userEntitlements],
              };
            } else {
              userObj = {
                ...editedUserData,
                userDetails: { ...editedUserData.userDetails },
                userRole: { ...editedUserData.userRole },
              };
            }
            if (userObj && userObj.userEntitlements && userObj.userEntitlements.length) {
              userObj.userEntitlements.push({
                companyId: selectedCustomer.id,
                refinery: value,
                companyName: selectedCustomer.label,
              });
            } else {
              userObj.userEntitlements = [
                {
                  companyId: selectedCustomer.id,
                  refinery: value,
                  companyName: selectedCustomer.label,
                },
              ];
            }
          }
          setEditedUserData(userObj);
          setErrorFlag(false);
          setMessage('');
        });
      } else {
        setCustomerAdded(false);
        setErrorFlag(true);
        setMessage('Entitlement already assigned, Please select any other Customer');
      }
    }
  };
  const handleNameChange = (event: any) => {
    if (event.detail) {
      setFullNameError(false);
      let newUserObj;
      if (userData && Object.keys(userData).length > 0) {
          newUserObj = {
            ...userData,
            userDetails: { ...userData.userDetails },
            userRole: { ...userData.userRole },
          };
        const names = event.detail.split(' ');
        let lastName = '';
        if (names.length) {
          newUserObj.userDetails.firstName = names[0];
          for (let i = 1; i < names.length; i++) {
            lastName = lastName + ' ' + names[i];
          }
          newUserObj.userDetails.lastName = lastName.trimStart();
        }
        setFullName(names[0] + lastName);
      }
      setUserData(newUserObj);
    } else {
      setFullNameError(true);
      setFullName('');
    }
  };

  const handleDialogueCtaClick = (event: any) => {
    if (event.detail === 'btnCancelRemove') setRemoveEntitlement(false);
    else if (event.detail === 'btnProceed') {
      //save user manamgent
      let payLoad = mapEditUserPayLoad();
      manageUserUpdateApi(payLoad);
    }
  };

  const handleClose = () => {
    setRemoveEntitlement(false);
    getUserData();
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <BhModal
          isOpen={isOpen}
          header={isAddUser ? 'Add new user' : 'Manage users'}
          ctas={ctaData}
          onClick={(event: any) => {
            handleCtaClick(event);
          }}
          onBhEventClose={(event) => handleModalClose(event)}
          width="large"
          data-testid="bh-manage-user"
          id="manage-user-modal"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '35%', overflowX: 'scroll' }}>
              {isAddUser && (
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                  <div>
                    <BhTextInput
                      label="Email ID"
                      type="email"
                      id="txt-email"
                      isRequired
                      isError={emailError || emailValidError}
                      isReadOnly={isVerifiedUser}
                      value={userEmail}
                      onBhEventChange={(event) => {
                        handleEmailChange(event);
                      }}
                      onBlurCapture={(event: any) => handleEmailChange(event)}
                      maxLength={100}
                    ></BhTextInput>
                    <BhFormMessage isError message={emailError ? 'This field is mandatory' : ''} id="email-error"></BhFormMessage>
                    <BhFormMessage isError message={emailValidError ? 'Invalid Email Address' : ''} id="email-error"></BhFormMessage>
                  </div>
                  <div style={{ marginLeft: '10px', marginTop: '20px' }}>
                    <BhButton
                      id="btnVerifyUser"
                      key="btnVerifyUser"
                      isDisabled={!userEmail || isVerifiedUser || emailError || emailValidError}
                      type="primary"
                      label="Verify"
                      onClick={handleVerifyUserClick}
                      hidden={!isAddUser}
                    ></BhButton>
                  </div>
                </div>
              )}

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
                <BhTextInput
                  label="Full Name"
                  type="text"
                  id="txt-name"
                  isRequired
                  isError={fullNameError}
                  isDisabled={isAddUser && !isVerifiedUser}
                  isReadOnly={!isAddUser}
                  value={isAddUser ? fullName : userInfo && userInfo.userDetails && userInfo.userDetails.fullName}
                  onBhEventInput={(event) => handleNameChange(event)}
                  onKeyPress={(e) => /[0-9`~.!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(e.key) && e.preventDefault()}
                  //onBhEventBlur={(event) => handleNameChange(event)}
                  maxLength={100}
                ></BhTextInput>
                <BhFormMessage isError message={fullNameError ? 'This field is mandatory' : ''} id="fullname-error"></BhFormMessage>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
                <BhDropdown
                  label="Role"
                  menuItems={JSON.stringify(roles)}
                  value={
                    isAddUser
                      ? userData && userData.userRole && userData.userRole.roleName
                        ? userData.userRole.roleName
                        : roles[0].label
                      : editedUserData && editedUserData.userRole && editedUserData.userRole.roleName
                  }
                  id="role"
                  class="role-drd"
                  onBhEventChange={(event) => handleRoleDropDownChange(event)}
                  isDisabled={isAddUser && !isVerifiedUser}
                ></BhDropdown>
              </div>
              {!isAddUser && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
                  <BhDropdown
                    id="status-drd"
                    class="role-drd"
                    label="Status"
                    value={editedUserData && editedUserData.userDetails && editedUserData.userDetails.isActive ? 'Active' : 'Inactive'}
                    menuItems={statusItems}
                    onBhEventChange={(event) => handleStatusDropDownChange(event)}
                  ></BhDropdown>
                </div>
              )}
              {!isAddUser && (
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <label>Customer</label>
                  <Autocomplete
                    disablePortal={false}
                    disabled={!isAccountManager}
                    id="combo-box-customer"
                    className="combo-box-customer"
                    options={allCustomers}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder="Search" />}
                    onChange={(event, newValue) => handleSelectedCustomer(event, newValue)}
                  />
                </div>
              )}

              {!isAddUser && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
                  <BhButton
                    id="btnAddCustomer"
                    key="btnAddCustomer"
                    type="primary"
                    label="Add Customer"
                    className="add-customer"
                    onClick={handleAddCustomerClick}
                    isDisabled={!isAccountManager}
                  ></BhButton>
                </div>
              )}
            </div>
            <div style={{ background: 'gray', width: '65%' }}>
              <div>
                <div className="entitle-box">
                  <Box sx={{ width: '50%', bgcolor: 'background.paper', height: '414px' }} border={1} borderColor={'#d2dad8'}>
                    <Typography height={25} paddingLeft={2} paddingTop={1} paddingBottom={1} className="customer-label">
                      Customer
                    </Typography>
                    <Divider />
                    <TextField
                      id="txt-admin-search"
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 1, width: 330 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => requestCustomerSearch(event)}
                      disabled={(isAddUser && !isVerifiedUser) || !isAccountManager}
                    />
                    <Divider />
                    <List component="nav" className="customer-list" sx={{ overflowY: 'scroll', height: 200 }} id="customer-list">
                      {customers.map((customerItem: any, index: any) => (
                        <StyledList key={customerItem.id}>
                          <ListItemButton
                            id="customer-item-button"
                            selected={customerItem.selected}
                            key={index}
                            disabled={(isAddUser && !isVerifiedUser) || !isAccountManager}
                            onClick={() => handleCustomerChange(customerItem.id, customerItem.selected)}
                          >
                            <ListItemText
                              primary={customerItem.label}
                              key={customerItem.id}
                              className="cust-item"
                              id="customer-item-text"
                            />
                          </ListItemButton>
                        </StyledList>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ width: '50%', bgcolor: 'background.paper', height: '414px' }} border={1} borderColor={'#d2dad8'}>
                    <Typography height={25} paddingLeft={2} paddingTop={1} paddingBottom={1} className="customer-label">
                      Refinery
                    </Typography>
                    <Divider />
                    <TextField
                      id="txt-admin-search"
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 1, width: 330 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => requestRefinerySearch(event)}
                      disabled={(isAddUser && !isVerifiedUser) || !isAccountManager}
                    />
                    <Divider />
                    <List component="nav" className="refinery-list" sx={{ overflowY: 'scroll', height: 140 }} id="refinery-list">
                      {refineries.map((refineryItem: any, index: any) => (
                        <ListItemButton
                          key={index}
                          disabled={(isAddUser && !isVerifiedUser) || !isAccountManager}
                          onClick={(event) => handleRefinerySelection(refineryItem.id, refineryItem.custId, event)}
                        >
                          <FormControlLabel
                            control={
                              !isAddUser &&
                              Object.keys(editedUserData).length &&
                              editedUserData.userRole &&
                              editedUserData.userRole.roleName &&
                              editedUserData.userRole.roleName.toLowerCase() === 'account manager' ? (
                                <Checkbox checked={refineryItem.checked} />
                              ) : isAccountManager ? (
                                <Checkbox checked={refineryItem.checked} />
                              ) : (
                                <div></div>
                              )
                            }
                            label={refineryItem.label}
                            key={refineryItem.id}
                            className="cust-item"
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Box>
                </div>
              </div>
            </div>

            <div style={{ height: '40px', width: '97%', position: 'absolute', bottom: '112px' }}>
              {isSuccess ? (
                <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success user-error" id="alert-message">
                  {message}
                </Alert>
              ) : isError ? (
                <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error user-error" id="alert-message">
                  {message}
                </Alert>
              ) : isValid ? (
                <Alert icon={<InfoOutlinedIcon />} severity="info" className="alert-box-info user-error" id="alert-message">
                  {message}
                </Alert>
              ) : (
                <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
              )}
            </div>
          </div>
          <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
              open={isLoading}
              onClick={handleBackDropClose}
            >
              {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
            </Backdrop>
            <BhDialog
              header="Remove entitlements"
              message="It seems you have removed all the entitlements, Do you want to proceed?"
              ctas={[
                {
                  type: 'critical',
                  label: 'Proceed',
                  key: 'btnProceed',
                },
                {
                  type: 'ghost',
                  label: 'Cancel',
                  key: 'btnCancelRemove',
                },
              ]}
              isOpen={isRemoveEntitlement}
              onBhEventCtaClick={(event: any) => handleDialogueCtaClick(event)}
              onBhEventClose={handleClose}
            ></BhDialog>
          </div>
        </BhModal>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
