import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  user: {},
  userFlag : null,
};

const createUserRoleSlice = createSlice({
  name: 'userrole',
  initialState,
  reducers: {
    createUserRole(state, action) {
      state.user = action.payload;
    },
    createFlag(state, action) {
      state.userFlag = action.payload;
    },
  },
});
export const createUserRoleAction = createUserRoleSlice.actions;
export const createFlag = createUserRoleSlice.actions;
export default createUserRoleSlice;
