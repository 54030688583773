import { configureStore } from '@reduxjs/toolkit';
import createConfigSlice from './config-slice';
import createRefineryResponseSlice from './refineryresponse-slice';
import createRefineryTreeSlice from './refinerytree-slice';
import createUserRoleSlice from './userrole-slice';
import createNavigationSlice from './navigation-slice';
import createFavoriteSlice from './favoritelist-slice';

const store = configureStore({
  reducer: {
    createConfig: createConfigSlice.reducer,
    createRefineryTree: createRefineryTreeSlice.reducer,
    createRefineryResponse: createRefineryResponseSlice.reducer,
    createUserRole: createUserRoleSlice.reducer,
    createNavigation: createNavigationSlice.reducer,
    createSummaryResponse: createFavoriteSlice.reducer,
  },
});

export default store;
