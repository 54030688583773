/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import { BhDivider } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './SaltTemperatureGraph.scss';
import { MONTHS } from '../../constants/report-constants';
import {  mapSaltGraphData, setDewPointDiffGraphConfig, setDewPointGraphConfig, setTowerTopDiffGraphConfig, setTowerTopGraphConfig } from './helper';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale);


export default function SaltTemperatureGraph(props: any) {
  const [towerTopData, setTowerTopData] = useState({ datasets: [] });
  const [towerTopDiffData, setTowerTopDiffData] = useState({ datasets: [] });
  const [dewPointData, setDewPointData] = useState({ datasets: [] });
  const [dewPointDiffData, setDewPointDiffData] = useState({ datasets: [] });
  const [towerTopOption, setTowerTopOption] = useState();
  const [towerTopDiffOption, setTowerTopDiffOption] = useState();
  const [dewPointOption, setDewPointOption] = useState();
  const [dewPointDiffOption, setDewPointDiffOption] = useState();
  const [corrosionHighRiskLine, setCorrosionHighRiskLine] = useState<any>({});
  const [corrosionLowRiskLine, setCorrosionLowRiskLine] = useState<any>({});
  const [corrosionLowRiskLineDewPoint, setCorrosionLowRiskLineDewPoint] = useState<any>({});
  const [corrosionHighRiskLineDewPoint, setCorrosionHighRiskLineDewPoint] = useState<any>({});
  const [primaryReportData, setPrimaryReportData] = useState<any>();
  const [subHeadingReport, setSubHeadingReport] = useState<any>();
  const [showTowerTopDiffGraph, setShowTowerTopDiffGraph] = useState(false);
  const [showDewPointDiffGraph, setShowDewPointDiffGraph] = useState(false);
  const {subText, mainText} = props;

  ChartJS.defaults.font.size = 14;
  ChartJS.defaults.font.family = 'Poppins';

  const getReportData = () => {
    if (props.primaryReportData) {
      setPrimaryReportData(props.primaryReportData);
    } else {
      var temp: any = localStorage.getItem('primaryReportData') ? localStorage.getItem('primaryReportData') : '{}';
      setPrimaryReportData(JSON.parse(temp));
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (primaryReportData && Object.keys(primaryReportData).length > 0) {
      var key: any = Object.keys(primaryReportData)[0];
      var tempDate: any = `${new Date(key).getDate()} ${MONTHS[new Date(key).getMonth()]} ${new Date(key).getFullYear()} `;
      setSubHeadingReport(tempDate);
    }
  }, [primaryReportData]);


  useEffect(() => {
    setTowerTopGraphConfig(props.defaultUOM, setTowerTopOption);
    setTowerTopDiffGraphConfig(setTowerTopDiffOption, props.defaultUOM, setCorrosionLowRiskLine, setCorrosionHighRiskLine, setShowTowerTopDiffGraph);
    setDewPointGraphConfig(props.defaultUOM, setDewPointOption);
    setDewPointDiffGraphConfig(setCorrosionHighRiskLineDewPoint, setCorrosionLowRiskLineDewPoint, props.defaultUOM, setDewPointDiffOption,  setShowDewPointDiffGraph);
    mapSaltGraphData(props.saltGraphApiResult, props.isCokerOrFcc, setTowerTopData, setTowerTopDiffData, setDewPointData, setDewPointDiffData, true);
  }, [props]);

  return (
    <React.Fragment>
       <div className="corrosion-risk-div">
            <label className="corrosion-risk-refinery">{mainText}</label>
       </div>
       <div className="corrosion-risk-div margin-top-22">
            <label className="corrosion-risk-refinery font-size-30">{subText}</label>
          </div>
      <div className="corrosion-risk-div margin-top-22">
        <label className="corrosion-risk-label">Corrosion risk report &nbsp; </label>
        <label className="primary-subheading">{subHeadingReport}</label>
      </div>
      <div style={{ marginLeft: '5%' }}>
        <p className="salt-temp-header">Salt temperature graphs</p>

        <div className="heading-wrapper">
          <h5 className="salt-temp-heading">Salt temperatures at tower top</h5>
        </div>
        <div className="salt-graph-container-pdf">
          <Scatter id="tower-top" options={towerTopOption} data={towerTopData}></Scatter>
        </div>
        <div className="heading-wrapper">
          <h5 className="salt-temp-heading"> ΔT with tower top</h5>
        </div>
        {showTowerTopDiffGraph && (
          <div className="salt-graph-container-pdf">
            <Scatter
              id="tower-top-diff"
              options={towerTopDiffOption}
              data={towerTopDiffData}
              plugins={[corrosionHighRiskLine, corrosionLowRiskLine]}
            ></Scatter>
          </div>
        )}
        <div>
          <BhDivider></BhDivider>
        </div>
        <div className="heading-wrapper">
          <h5 className="salt-temp-heading">Salt Temperature at water dew point</h5>
        </div>
        <div className="dewpoint-graph-container-pdf">
          <Scatter id="dew-point" options={dewPointOption} data={dewPointData}></Scatter>
        </div>
        <div className="heading-wrapper">
          <h5 className="salt-temp-heading">ΔT with water dew point</h5>
        </div>
        {showDewPointDiffGraph && (
          <div className="salt-graph-container-pdf">
            <Scatter
              id="diff-dewpoint"
              options={dewPointDiffOption}
              data={dewPointDiffData}
              plugins={[corrosionHighRiskLineDewPoint, corrosionLowRiskLineDewPoint]}
            ></Scatter>
          </div>
        )}
      </div>
      <hr className="seperator-line" style={{ marginTop: '10%' }} />
      <div style={{ marginLeft: '5%' }}>
        <p className="footer-bh-pdf">bakerhughes.com</p>
        <div style={{ marginTop: '0.5rem', display: 'flex' }}>
          <p className="footer-copyright-pdf">Copyright 2024 Baker Hughes Company. All rights reserved.</p>
          <p className="footer-page-num-pdf">Page 03</p>
        </div>
      </div>
    </React.Fragment>
  );
}
