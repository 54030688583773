/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React from 'react';
import { BhAlert } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';

function ErrorFallback({ error }: { error: Error }) {
  return (
    <BhAlert
      alerts={[
        {
          status: 'critical',
          message: error.message,
        },
      ]}
      type="context"
    ></BhAlert>
    //   <div role="alert">
    //     <p>An error occurred:</p>
    //     <pre>{error.message}</pre>
    //   </div>
  );
}
export default ErrorFallback;
