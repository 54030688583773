/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useLayoutEffect, useState } from 'react';
import './ImportAssetsModal.scss';
import {
  BhButton,
  BhDivider,
  BhDropdown,
  BhFormMessage,
  BhIllustration,
  BhSpinner,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import {
  Alert,
  Backdrop,
  DialogActions,
  DialogContent,
  InputAdornment,
  TablePaginationProps,
  TextField,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import SearchIcon from '@mui/icons-material/Search';
import {
  DataGrid,
  GridRowId,
  GridColDef,
  GridPagination,
  GridRowParams,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import apiRequest from '../../../services/api-helper';
import { APIEnum } from '../../../constants/api-enum';
import { BootstrapDialog, BootstrapDialogTitle } from '../../Dashboard/helper';
declare const window: any;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function ImportAssetsModal(props: any) {
  const { isOpen, closeModal, getAssetsData } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [isSearchDone, setSearchDone] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [refineryName, setRefineryName] = useState('');
  const [customerError, setCustomerError] = useState(false);
  const [customerValidError, setCustomerValidError] = useState(false);
  const [isAssetSelected, setAssetSelected] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<any>([]);
  const [onBoardedRefineries, setOnBoardedRefineries] = useState<any>({});
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [paginationModelCustomer, setPaginationModelCustomer] = React.useState({
    pageSize: 5,
    page: 0,
  });

  const searchResultColumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 250,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'refinery',
      headerName: 'Refinery',
      width: 250,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 144,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderStatus(params.row),
      flex: 0,
    },
  ];

  useLayoutEffect(() => {
    return () => {
      setSearchResult([]);
      setCustomerError(false);
      setCustomerValidError(false);
      setSearchDone(false);
      setRefineryName('');
      setAssetSelected(false);
      setSuccessFlag(false);
      setErrorFlag(false);
      setMessage('');
      setCustomerName('');
    };
  }, [props]);

  const renderStatus = (row: any) => {
    return (
      <div className="asset-status">
        {row.status === 'Pending' && (
          <span className="text-margin typography--body-medium status-text status-text-pending">{row.status}</span>
        )}
        {row.status === 'Onboarded' && (
          <span className="text-margin typography--body-medium status-text status-text-onboarded">{row.status}</span>
        )}
      </div>
    );
  };

  const onBoardRefineriesApi = () => {
    if (
      onBoardedRefineries &&
      Object.keys(onBoardedRefineries).length > 0 &&
      onBoardedRefineries.refineries &&
      onBoardedRefineries.refineries
    ) {
      setIsLoading(true);
      let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries`;
      apiRequest(encodeURI(apiUrl), onBoardedRefineries, APIEnum.POST)
        .then((result) => {
          if (result.data && result.data.length) {
            if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'success') {
              //partial success
              if (result.responseStatus.errorResponse && result.responseStatus.errorResponse.length) {
                setErrorFlag(true);
                let errorDetails: string = '';
                let counter = 1;
                for (let index = 0; index < result.responseStatus.errorResponse.length; index++) {
                  errorDetails += counter + '. ' + result.responseStatus.errorResponse[index].message + ' ';
                }
                setMessage(errorDetails);
                setSelectionModel([]);
                setAssetSelected(false);
                document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'true');
                setOnBoardedRefineries({});
              }
              //success
              else {
                setErrorFlag(false);
                setSuccessFlag(true);
                setMessage('Assets onboarded successfully');
                document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'true');
                setOnBoardedRefineries({});
                setSelectionModel([]);
                searchAssetApiCall();
              }
            }
          } else {
            if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'error') {
              setSuccessFlag(false);
              if (result.responseStatus.errorResponse && result.responseStatus.errorResponse.length) {
                setErrorFlag(true);
                let errorDetails: string = '';
                let counter = 1;
                for (let index = 0; index < result.responseStatus.errorResponse.length; index++) {
                  errorDetails += counter + '. ' + result.responseStatus.errorResponse[index].message + ` `;
                  counter++;
                }
                setMessage(errorDetails);
                setSelectionModel([]);
                setAssetSelected(false);
                document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'true');
                setOnBoardedRefineries({});
              }
            }
          }
        })
        .catch((error: any) => {
          setErrorFlag(true);
          setMessage(error.message);
          setSelectionModel([]);
          setAssetSelected(false);
          document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'true');
          setOnBoardedRefineries({});
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleOnBoardClick = () => {
    if (customerName && !customerError && !customerValidError) {
      let isDisabled = document.querySelector(`bh-button[id="btnOnboard"]`)!.getAttribute('disabled');
      if (isDisabled === 'false') {
        //api call to onboard selected assets
        onBoardRefineriesApi();
      }
    } else {
      if (!customerName) setCustomerError(true);
    }
  };

  const handleCustomerChange = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setCustomerError(false);
      if (event.target.value.trim().length > 1) {
        setCustomerName(event.target.value.trim());
        setCustomerValidError(false);
      } else setCustomerValidError(true);
    } else {
      setCustomerError(true);
      setCustomerName('');
      setRefineryName('');
    }
  };

  const handleRefineryChange = (event: any) => {
    setRefineryName(event.target.value);
  };

  const searchAssetApiCall =()=>{
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/ids/refineries?name=${refineryName}&customer=${customerName}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result.data && result.data.length) {
          mapSearchResult(result.data);
        } else {
          setSearchDone(true);
          setSearchResult([]);
        }
      })
      .catch((error) => {
        setSearchResult([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  const handleSearchClick = () => {
    setPaginationModelCustomer({pageSize:5, page:0});
    if (customerName && !customerError && !customerValidError) {
      setOnBoardedRefineries({});
      setSelectionModel([]);
      setCustomerError(false);
      setIsLoading(true);
      setErrorFlag(false);
      setMessage('');
      //api call to fetch data from IDS
      searchAssetApiCall();
    } else {
      if (!customerName) setCustomerError(true);
    }
  };

  const handleModalClose = () => {
    closeModal();
    getAssetsData();
    setAssetSelected(false);
  };

  const handleBackDropClose = () => {
    setIsLoading(false);
  };

  const Pagination = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    return (
      <MuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event: any, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPagination = (props: any) => {
    return <GridPagination ActionsComponent={Pagination} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const mapSearchResult = (responseData: any) => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    responseData.forEach((item: any, index: number) => {
      payLoad.data.push({
        id: index + 1,
        customer: item.customer,
        refinery: item.refinery,
        source: 'IDS',
        status: item.onboarded ? 'Onboarded' : 'Pending',
        customerIDSName:item.customerIDSName,
        refineryIDSName:item.refineryIDSName
      });
    });
    payLoad.length = responseData.length;
    setSearchResult(payLoad);
  };

  const disableCheckBox: (params: GridRowParams) => boolean = (params: any) => {
    let resultFlag: boolean = false;
    if (params.row.status === 'Onboarded') resultFlag = true;
    else resultFlag = false;
    return resultFlag;
  };

  const handleChecked = (ids: any) => {
    setSelectionModel(ids);
    //if any item selected
    if (ids && ids.length) {
      setAssetSelected(true);
      document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'false');
      let selectedItems: any = [];
      ids.forEach((arrayItem: any) => {
        searchResult.data.forEach((searchItem: any) => {
          if (arrayItem === searchItem.id && !searchItem.onboarded)
            selectedItems.push({
              customer: searchItem.customerIDSName,
              refinery: searchItem.refineryIDSName,
            });
        });
      });
      let itemsToOnboard = {
        refineries: selectedItems,
      };
      setOnBoardedRefineries(itemsToOnboard);
    } else {
      setAssetSelected(false);
      document.querySelector(`bh-button[id="btnOnboard"]`)!.setAttribute('disabled', 'true');
      setOnBoardedRefineries({});
    }
  };

  const handleCancelClick = () => {
    closeModal();
    getAssetsData();
  };

  return (
    <React.Fragment>
      {isOpen ? (
        <BootstrapDialog
          onClose={handleModalClose}
          aria-labelledby="import-asset-title"
          open={isOpen}
          id="import-assets-modal"
          className="import-modal"
          style={{zIndex:40000}}
        >
          <BootstrapDialogTitle id="import-asset-title" onClose={handleModalClose}>
            <div className="upload-header">{'Import assets'}</div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div>
              <div className="import-asset-wrapper">
                <BhDropdown
                  label="Source"
                  className="source-drd"
                  isRequired
                  menuItems={{
                    itemGroups: [
                      {
                        items: [
                          {
                            label: 'IDS',
                            value: 'IDS',
                          },
                        ],
                        divider: false,
                      },
                    ],
                  }}
                  value="IDS"
                  id="source"
                ></BhDropdown>
                <div className="customer-div">
                  <label className="bh-dropdown__label typography--label-small typography--color-primary bh-dropdown__required">
                    Customer
                  </label>
                  <TextField
                    error={customerError || customerValidError}
                    id="txt-customer"
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    sx={{ paddingTop: 0.3, width: 245 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => handleCustomerChange(event)}
                  />
                  <BhFormMessage isError message={customerError ? 'This field is mandatory' : ''} id="customer-error"></BhFormMessage>
                  <BhFormMessage
                    isError
                    message={customerValidError ? 'Provide atleast two characters' : ''}
                    id="customer-invalid-error"
                  ></BhFormMessage>
                </div>
                <div className="refinery-div">
                  <label className="bh-dropdown__label typography--label-small typography--color-primary">Refinery</label>
                  <TextField
                    id="txt-refinery"
                    variant="outlined"
                    value={refineryName}
                    size="small"
                    placeholder="Search"
                    sx={{ paddingTop: 0.3, width: 245 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => handleRefineryChange(event)}
                    disabled={!customerName}
                  />
                </div>
                <BhButton
                  id="btnSearch"
                  key="btnSearch"
                  type="secondary"
                  label="Search"
                  onClick={handleSearchClick}
                  className="btn-asset-search"
                  //   isDisabled={!isAccountManager}
                ></BhButton>
              </div>
              <BhDivider marginTop="large"></BhDivider>
              <div className="search-list">
                {Object.keys(searchResult).length > 0 && searchResult.data && searchResult.data.length ? (
                  <DataGrid
                    pagination
                    paginationModel={paginationModelCustomer}
                    slots={{
                      pagination: CustomPagination,
                    }}
                    rows={searchResult.data}
                    columns={searchResultColumns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'customer', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    onPaginationModelChange={setPaginationModelCustomer}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    isRowSelectable={(params: GridRowParams) => !disableCheckBox(params)}
                    checkboxSelection
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    onRowSelectionModelChange={(ids) => {
                      handleChecked(ids);
                    }}
                    rowSelectionModel={selectionModel}
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                ) : (
                  isSearchDone && (
                    <center>
                      <BhIllustration
                        id="empty-box"
                        source={`${process.env.PUBLIC_URL}/assets/images//EmptyBox.svg`}
                        description="No data found!"
                      ></BhIllustration>
                    </center>
                  )
                )}
              </div>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
                open={isLoading}
                onClick={handleBackDropClose}
              >
                {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
              </Backdrop>
              {isSuccess ? (
                <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success user-error" id="alert-message-import">
                  {message}
                </Alert>
              ) : isError ? (
                <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error user-error" id="alert-message-import">
                  {message}
                </Alert>
              ) : (
                <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
              )}
            </div>
          </DialogContent>
          <DialogActions className="footer-cta-wrapper">
            <div className="import-cta-container bh-modal__footer">
              <BhButton type="ghost" label="Cancel" key="btnCancel" onClick={handleCancelClick}></BhButton>
              <BhButton
                type="primary"
                label="Onboard"
                key="btnOnboard"
                id="btnOnboard"
                isDisabled={!isAssetSelected}
                onClick={handleOnBoardClick}
              ></BhButton>
            </div>
          </DialogActions>
        </BootstrapDialog>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
