import React, { useEffect, useState } from 'react';
import { BhButton, BhTextArea } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import './ReportComments.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Alert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
declare const window: any;

export default function ReportComments(props: any) {
  const [comments, setComments] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { resultDataId } = props;
  const [savedComments, setSavedComments] = useState('');
  const [disableSave, setDisableSave] = useState(true);
  const [clearBtnClicked, setClearBtnClicked] = useState(false);
  const [lastModifiedBy, setLastModifiedBy] = useState('');

  useEffect(() => {
    if (clearBtnClicked) {
      handleSaveClick();
    }
  }, [clearBtnClicked]);

  const handleClearClick = () => {
    setComments('');
    setClearBtnClicked(true);
  };

  useEffect(() => {
    setComments(props.recommendations);
    setSavedComments(props.recommendations);
    setLastModifiedBy(props.modifiedBy);
  }, []);

  const handleSaveClick = () => {
    let commentsPayLoad = {
      resultDataId: resultDataId ? resultDataId : localStorage.getItem('resultDataId') && JSON.parse(localStorage.getItem('resultDataId')!),
      comments: comments,
    };

    //api call to save comments
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/results`;
    apiRequest(encodeURI(apiUrl), commentsPayLoad, APIEnum.POST)
      .then((result) => {
        if (result && result.responseStaus && result.responseStaus.status && result.responseStaus.status === 'SUCCESS') {
          setIsSuccess(true);
          setMessage('Comments saved successfully');
          props.setReportComments(comments);
          setDisableSave(true);
          setSavedComments(comments);
          setLastModifiedBy(result.data.lastUpdatedBy);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
        } else if (result.responseStatus.status === 'ERROR') {
          setIsError(true);
          if (result.responseStatus.errorResponse[0] && result.responseStatus.errorResponse[0].message) {
            setMessage(result.responseStatus.errorResponse[0].message);
          }
        }
      })
      .catch((error) => {
        setIsError(true);
        setMessage(error.message);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      });
  };
  const handleTextChange = (event: CustomEvent) => {
    setComments(event.detail);
    setIsSuccess(false);
    if (event.detail !== savedComments) {
      setDisableSave(false);
    }
  };
  const saveComments = (event: CustomEvent) => {
    if (comments !== savedComments) {
      setIsSuccess(false);
      handleSaveClick();
    }
  };

  return (
    <React.Fragment>
      <Accordion elevation={0} classes={{}} className="report-outline" sx={{ marginTop: 2 }} id="report-comment">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
          <Typography className="primary-report-header">Report comments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BhTextArea
            fluidHorz={true}
            maxChar={2000}
            messageType="count"
            onBhEventInput={(event: any) => handleTextChange(event)}
            onBhEventChange={(event: any) => saveComments(event)}
            value={comments}
          ></BhTextArea>
          {lastModifiedBy && <p className="typography--body-small modified-by">Last modified by: {lastModifiedBy}</p>}
          <div className="btn-comment-wrapper">
            <BhButton
              key="btnClear"
              type="secondary"
              label="Clear"
              leftIcon="delete"
              className="btn-clear"
              isSmall
              onClick={handleClearClick}
              isDisabled={comments && comments.length ? false : true}
            ></BhButton>
            <BhButton
              key="btnSaveComment"
              type="primary"
              label="Save comment"
              className="btn-save"
              isSmall
              onClick={handleSaveClick}
              isDisabled={disableSave}
            ></BhButton>
          </div>
          {isSuccess && (
            <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success alert-box-wd" id="alert-message">
              {message}
            </Alert>
          )}
          {isError && (
            <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error" id="alert-message">
              {message}
            </Alert>
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
