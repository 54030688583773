export const getStatus = (status: string) => {
  let color: string = '';
  switch (status) {
    case 'Approved':
      color = '#548c69';
      break;
    case 'Renewal Approved':
      color = '#548c69';
      break;
      case 'Renewed':
      color = '#548c69';
      break;
    case 'Rejected':
      color = '#cf2333';
      break;
    case 'Renewal Rejected':
      color = '#cf2333';
      break;
    case 'Partially Approved':
      color = '#f0b429';
      break;
    case 'Partially approved':
      color = '#f0b429';
      break;
    case 'Pending':
      color = '#cb6e17';
      break;
    case 'Renewal Requested':
      color = '#cb6e17';
      break;
    case 'Pending for renewal':
      color = '#cb6e17';
      break;
    case 'Expiring Soon':
      color = '#cb6e17';
      break;
    default:
  }

  return color;
};

export const renderStatus = (row: any) => {
  return <div style={{ color: getStatus(row.status) }} data-testid='row-status'>{row.status === 'ExpiringSoon' ? 'Expiring soon' : row.status}</div>;
};

export const requestTakenBy = (row: any) => {
  return <div style={{ color: '#02a783' }} data-testid='row-requestedby'>{row.requestBy}</div>;
};

export const requestApprovedBy = (row: any) => {
  return <div style={{ color: '#02a783' }} data-testid='row-approve'>{row.approve}</div>;
};

