/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { BhButton, BhButtonDropdown, BhCard, BhFormMessage, BhIcon, BhSpinner, BhTextArea, BhTextInput } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { FileUploader } from 'react-drag-drop-files';
import * as XLSX from 'xlsx';
import './ExcelUploadModal.scss';
import { Backdrop, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { changeToShortFormat } from '../../../utils/utils';

import { ionicInputFor1A  } from '../../Simulation/helper/InputForOneA';
import { ionicInputFor1B  } from '../../Simulation/helper/InputForOneB';
import { ionicInputFor2A  } from '../../Simulation/helper/InputForTwoA';
import { ionicInputFor2B  } from '../../Simulation/helper/InputForTwoB';
import apiRequest from '../../../services/api-helper';
import { APIEnum } from '../../../constants/api-enum';
import { BootstrapDialog, BootstrapDialogTitle } from '../../Dashboard/helper';
declare const window: any;

export default function ExcelUploadModal(props: any) {
  const { header, isSimulationOpen, closeSimulationModal, configType, bindSimulationData, openDropDownMenu, otherInputClicked, isSandbox } = props;
  const [ctaData, setCtaData] = useState<any>({});
  const [isUploadSuccess, setUploadSuccess] = useState<boolean>();
  const [isUploadError, setUploadError] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const [file, setFile] = useState<any>(null);
  const [excelData, setExcelData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrorDialogueOpen, setIsErrorDialogueOpen] = useState<boolean>(false);
  const [errorHeader, setErrorHeader] = useState('');
  const [errorMessage, setErrorMessage] = useState<any>();
  const navigate = useNavigate();

  //UOM
  const [IsUomWarningDialogueOpen, setUomWarningDialogueOpen] = useState<boolean>(false);
  const [defaultUnitWarningObj, setDefaultUnitWarningObj] = useState<any>([]);
  const [simulationInput, setSimulationInput] = useState<any>([]);

  const [isDataGiven, setDataGiven] = useState<boolean>(false);
  const [showNewSimEdit, setNewSimEdit] = useState<boolean>(false);
  const [simulationName, setSimulationName] = useState('');
  const [isSimNameError,setSimNameError] = useState(false);
  const [comments, setComments] = useState('');
  const [isCommentError,setCommentError] = useState(false);
  const [unitType, setUnitType] = useState<string>('');
  const [isWithDesalter, setWithDesalter] = useState(false);
  const EXCEL_VERSION='2';
  const COKER_EXCEL_VERSION = '2';

  const handleCtaClick = (event: CustomEvent) => {
    if (event.detail.value === 'manual-input-item') {
      localStorage.removeItem('manualInput');
      navigate('/simulation-data');
      window.location.reload();
    }
  };

  const handleClose = () => {
    setIsLoading(false);
  };

  const downloadExcel = () => {
    //api to download excel template
    let typeConfig;
    if(unitType === 'coker')
      typeConfig = isWithDesalter ? 'CD':'CK';
    else if(unitType === 'fcc')
      typeConfig = isWithDesalter ? 'FD':'FC';
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/template?configType=${(unitType !== 'coker' && unitType !== 'fcc') ? configType :typeConfig}`;
    let headerObject: any = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.accessToken?.accessToken}`,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    fetch(apiUrl, headerObject).then((response) => {
      const filename = response.headers.get('Content-Disposition')!.split('filename=')[1];
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  const getOffGasUnit = (index: number) => {
    let offGasunits = require('../../../constants/offgas-units.json');
    return offGasunits.filter((item: any) => item.value === index)[0].label;
  };

  const getTempUnit = (index: number) => {
    let tempunits = require('../../../constants/temperature-units.json');
    return tempunits.filter((item: any) => item.value === index)[0].label;
  };

  const getUnit = (index: number) => {
    let crudechargeunits = require('../../../constants/crudecharge-units.json');
    return crudechargeunits.filter((item: any) => item.value === index)[0].label;
  };

  const getPressureUnit = (index: number) => {
    let pressureunits = require('../../../constants/pressure-units.json');
    return pressureunits.filter((item: any) => item.value === index)[0].label;
  };

  const getWashRateUnit = (index: number) => {
    let washRateUnits = require('../../../constants/washrate-units.json');
    return washRateUnits.filter((item: any) => item.value === index)[0].label;
  };

  const getNeutralizerRateUnit = (index: number) => {
    let neutralizerRateUnits = require('../../../constants/neutralizer-units.json');
    return neutralizerRateUnits.filter((item: any) => item.value === index)[0].label;
  };

  const getCombinedReflux = (index: number) => {
    let combinedReflux = require('../../../constants/reflux-units.json');
    return combinedReflux.filter((item: any) => item.value === index)[0].label;
  };

  const getSteamUnit = (index: number) => {
    let steamUnits = require('../../../constants/steam-tower-units.json');
    return steamUnits.filter((item: any) => item.value === index)[0].label;
  };

  const checkUploadedExcel = (data: any, fileName:any) => {
    let isSuccess = false;
    //check for config type
    if (data && data.length) {
      if (data[1]['__EMPTY_1'] && data[1]['__EMPTY_2']) {
        let configType = localStorage.getItem('configType')!;
        //check whether uploaded config type is same as that of current config and excel version is the latest
        if ((configType === data[1]['__EMPTY_2']) && (((unitType === 'coker' || unitType === 'fcc') && COKER_EXCEL_VERSION === data[0]['__EMPTY_2']) || ((unitType !== 'coker' && unitType !== 'fcc') && EXCEL_VERSION === data[0]['__EMPTY_2']))) {
          //case when the current configuration is 1A and user uploaded coker/fcc excel eventhough current unit is not coker/fcc
          if((((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) && (fileName.toLowerCase().includes('desalter'))) || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter) && ((fileName.toLowerCase().includes('coker') || fileName.toLowerCase().includes('fcc')) && !fileName.toLowerCase().includes('desalter')) || ((unitType !== 'coker' && unitType !== 'fcc') && (!fileName.toLowerCase().includes('coker') && !fileName.toLowerCase().includes('fcc')))){
          //check if input count exceeds 100 (12 rows will be unit,volume, temp etc. hence total length will be 100 + 12)
          if (data.length > 112) {
            setUploadSuccess(false);
            setUploadError(true);
            setUploadErrorMessage(' Maximum 100 inputs are allowed!');
            isSuccess = false;
          } else {
            //check if total number of records + newly uploaded records exceed 112
            let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
            //if already data uploaded, then need to update the newly uploaded data
            if (dataFromStorage && dataFromStorage.length && data.length + dataFromStorage.length > 112) {
              setUploadSuccess(false);
              setUploadError(true);
              setUploadErrorMessage(' Maximum 100 inputs are allowed!');
              isSuccess = false;
            } else {
              //check for empty row
              for (let j = 11; j < data.length; j++) {
                if (data[j]['Date'] || data[j]['Date (mm/dd/yyyy)']) {
                  setUploadSuccess(true);
                  setUploadError(false);
                  isSuccess = true;
                } else {
                  setUploadSuccess(false);
                  setUploadError(true);
                  setUploadErrorMessage(' Enter atleast one data set!');
                  isSuccess = false;
                }
              }
            }
          }
        }
        else{
          setUploadSuccess(false);
          setUploadError(true);
          setUploadErrorMessage(' Configuration type does not match! Please upload the correct one.');
          isSuccess = false;
        }
        //if user uploaded wrong configuration excel 
        } else if(configType !== data[1]['__EMPTY_2']) {
          setUploadSuccess(false);
          setUploadError(true);
          setUploadErrorMessage(' Configuration type does not match! Please upload the correct one.');
          isSuccess = false;
        }
        //if user uploaded old excel which doesnt have latest inputs
        else{
          setUploadSuccess(false);
          setUploadError(true);
          setUploadErrorMessage(' It seems you have uploaded old excel. Please download latest template and upload the same');
          isSuccess = false;
        }
      }
    }
    return isSuccess;
  };

  const processExcelData = async (excelData: any) => {
    let success: boolean = false;
    let configType = localStorage.getItem('configType')!;
    let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
    let simulationData: any = [];
    let crudeUnit: any, crudeTime, crudeVolume, crudeMin, crudeMax;
    let crudegravityMin, crudegravityMax;
    let washPercentageMin, washPercentageMax, wash1PercentageMin, wash1PercentageMax, wash2PercentageMin, wash2PercentageMax;
    let brineRecyclePercentageMin, brineRecyclePercentageMax;
    let percentageAtmH2OtoDesalterMin, percentageAtmH2OtoDesalterMax;
    let percentageAtmH2OtoSWSMin, percentageAtmH2OtoSWSMax, percentageSSWtoDesalterMin, percentageSSWtoDesalterMax;
    let desalterTemperatureMin,
      desalterTemperatureMax,
      desalterTempUnit: any,
      brinepHMin,
      brinepHMax,
      brine1pHMin,
      brine1pHMax,
      brine2pHMin,
      brine2pHMax,
      brineSampleTemperatureMin,
      brineSampleTemperatureMax;
    let desCrudeBSWMin,
      desCrudeBSWMax,
      desCrudeBSWMin1,
      desCrudeBSWMin2,
      desCrudeBSWMax1,
      desCrudeBSWMax2,
      refluxUnit: any,
      refluxUnit1: any,
      refluxMin,
      refluxMin1,
      refluxMax,
      refluxMax1,
      productUnit: any,
      productUnit1: any,
      productMin,
      productMin1,
      productMax,
      productMax1,
      gravityMin,
      gravityMin1,
      gravityMax,
      gravityMax1;
    let distillationunit: any, distillationMin, distillationMax, distillationunit1, distillationMin1, distillationMax1;
    let offGasUnit: any, offGasMin, offGasMax, offGasTime, offGasMass, offGasVolume, offGastStdVol;
    let brMin, brMax, br2Min, br2Max;
    let H2Min,
      H2Max,
      H2SMin,
      H2SMax,
      CO2Min,
      CO2Max,
      C1Min,
      C1Max,
      C2Min,
      C2Max,
      C3Min,
      C3Max,
      iC4Min,
      iC4Max,
      nC4Min,
      nC4Max,
      iC5Min,
      iC5Max,
      nC5Min,
      nC5Max,
      nC6Min,
      nC6Max;
    let ohTempUnit: any, ohTempMin, ohTempMax, ohPressureUnit: any, ohPressMin, ohPressMax, ohPressPressure;
    let totalExternalWashRateMin, totalExternalWashRateMax, totalExternalWashRate_Unit;
    let washRateUnit: any,
      washRateTime,
      washRateVolume,
      washRateMin,
      washRateMax,
      washTempUnit: any,
      washTempMin,
      washTempMax,
      washProcessTempMin,
      washProcessTempMax,
      washProcessPressMin,
      washProcessPressMax,
      washProcessPressUnit: any,
      washProcessPressPressure;
    let velocityTemMin,
      velocityTemMax,
      velocityPressUnit: any,
      velocityPressMin,
      velocityPressMax,
      velocityPressPressure,
      drumTempUnit: any,
      drumTempMin,
      drumTempMax,
      drumPressUnit: any,
      drumPressPressure,
      drumPressMin,
      drumPressMax;
      let combinedRefluxMin, combinedRefluxMax, combinedRefluxTempMin, combinedRefluxTempMax, combinedRefluxFlow_UOM, combinedRefluxUnit, combinedUnit :any;
    let pHMin,
      pHMin1,
      pHMin2,
      pHMax,
      pHMax1,
      pHMax2,
      clMin,
      clMin1,
      clMin2,
      clMax,
      clMax1,
      clMax2,
      NH3Min,
      NH3Min1,
      NH3Min2,
      NH3Max,
      NH3Max1,
      NH3Max2,
      neutralizerRateUnit: any,
      neutralizerRateMin,
      neutralizerRateMax,
      neutralizerRateTime,
      neutralizerRateVolume;
    //for crude unit
    let outsideNeutralizerMin:any, outsideNeutralizerMax, outsideNeutralizerTime, outsideNeutralizerVolume;
    let trampAmineppmMin,
      trampAmineppmMin1,
      trampAmineppmMin2,
      trampAmineppmMax,
      trampAmineppmMax1,
      trampAmineppmMax2,
      steamAmineppmMin,
      steamAmineppmMin1,
      steamAmineppmMin2,
      steamAmineppmMax,
      steamAmineppmMax1,
      steamAmineppmMax2,
      steamTowerUnit: any,
      steamTowerTime,
      steamTowerMass,
      steamTowerMin,
      steamTowerMax;
    let steam1SSUnit: any, steam2SSUnit: any, steam3SSUnit: any, steam4SSUnit: any, steam5SSUnit: any, steam6SSUnit: any;
    let steam1SSTime, steam2SSTime, steam3SSTime, steam4SSTime, steam5SSTime, steam6SSTime;
    let steam1SSMass, steam2SSMass, steam3SSMass, steam4SSMass, steam5SSMass, steam6SSMass;
    let steam1SSMin, steam2SSMin, steam3SSMin, steam4SSMin, steam5SSMin, steam6SSMin;
    let steam1SSMax, steam2SSMax, steam3SSMax, steam4SSMax, steam5SSMax, steam6SSMax;
    //for coker
    let totalTowerSteamUnit:any, totalTowerSteamTime, totalTowerSteamMass, totalTowerSteamMin,totalTowerSteamMax;
    let totalSSSteamUnit:any, totalSSSteamTime, totalSSSteamMass, totalSSSteamMin, totalSSSteamMax;
    let totalFurnaceSteamUnit:any, totalFurnaceSteamTime, totalFurnaceSteamMass, totalFurnaceSteamMin, totalFurnaceSteamMax;
    let totalOtherSteamUnit:any, totalOtherSteamTime, totalOtherSteamMass, totalOtherSteamMin, totalOtherSteamMax;
    let sulphideMin,sulphideMax;
    let messageArray: any = [];
    let isAnyDefaultUnitChanged: boolean = false;
    for (let i = 0; i < excelData.length; i++) {
      //index values are stored here as hidden columns
      switch (i) {
        //config type
        case 1:
          break;
        //unit
        case 2:
          crudeUnit = (unitType !== 'coker' && unitType !== 'fcc') ? getUnit(parseInt(excelData[i]['Crude Charge'])) : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? getUnit(parseInt(excelData[i]['Unit Charge'])):null );
          desalterTempUnit = (unitType !== 'coker' && unitType !== 'fcc' && configType !== '1C' && configType !=='2C') ? getTempUnit(parseInt(excelData[i]['Desalter Temp'])): getTempUnit(parseInt(excelData[i]['OH Temp']));
          refluxUnit = getUnit(parseInt(excelData[i]['Reflux']));
          productUnit = getUnit(parseInt(excelData[i]['Product']));
          combinedUnit = (unitType !== 'coker' && unitType !== 'fcc') ? getUnit(parseInt(excelData[i]['Total Combined Reflux Flow'])) : null;
          if (configType === '2A' || configType === '2B' || configType === '2C') {
            refluxUnit1 = getUnit(parseInt(excelData[i]['Reflux_1']));
            productUnit1 = getUnit(parseInt(excelData[i]['Product_1']));
          }
          offGasUnit = getOffGasUnit(parseInt(excelData[i]['Off Gas']));
          ohPressureUnit = getPressureUnit(parseInt(excelData[i]['OH Press']));
          washRateUnit =  getWashRateUnit(parseInt(excelData[i]['Primary Wash Injection Rate']));
          totalExternalWashRate_Unit = (unitType !== 'coker' && unitType !== 'fcc') &&  getWashRateUnit(parseInt(excelData[i]['Primary Wash Injection Rate']));
          washProcessPressUnit = getPressureUnit(parseInt(excelData[i]['Wash Process Press']));
          velocityPressUnit = getPressureUnit(parseInt(excelData[i]['Velocity Press']));
          if (configType === '1A' || configType === '1B' || configType === '1C') {
            drumPressUnit = getPressureUnit(parseInt(excelData[i]['Drum Press']));
          } else if (configType === '2A' || configType === '2B' || configType === '2C') {
            drumPressUnit = getPressureUnit(parseInt(excelData[i]['Hot Drum Press']));
          }
          neutralizerRateUnit = (unitType !== 'coker' && unitType !== 'fcc') && getNeutralizerRateUnit(parseInt(excelData[i]['Neutralizer Rate']));
          combinedRefluxFlow_UOM = (unitType !== 'coker' && unitType !== 'fcc') && getCombinedReflux(parseInt(excelData[i]['Total Combined Reflux Flow']));
          if((unitType !== 'coker' && unitType !== 'fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)){
          steamTowerUnit = getSteamUnit(parseInt(excelData[i]['Steam Tower']));
          steam1SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 1° SS']));
          steam2SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 2° SS']));
          steam3SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 3° SS']));
          steam4SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 4° SS']));
          steam5SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 5° SS']));
          steam6SSUnit = getSteamUnit(parseInt(excelData[i]['Steam 6° SS']));
          }
          else if((unitType === 'coker' || unitType === 'fcc') && isWithDesalter){
            totalTowerSteamUnit = getSteamUnit(parseInt(excelData[i]['Total Tower Steam']));
            totalSSSteamUnit = getSteamUnit(parseInt(excelData[i]['Total SS Steam']));
            totalFurnaceSteamUnit = getSteamUnit(parseInt(excelData[i]['Total Furnace Steam']));
            totalOtherSteamUnit = getSteamUnit(parseInt(excelData[i]['Total Other Steam']));
          }

          //check each unit with the subcategory input mappping object
          if (Object.keys(categoryInputMapping).length) {
            if (
              categoryInputMapping.Crude_Charge_Rate && Object.keys(categoryInputMapping.Crude_Charge_Rate).length &&
              categoryInputMapping.Crude_Charge_Rate[0] &&
              categoryInputMapping.Crude_Charge_Rate[0].unitSymbol
            ) {
              if (crudeUnit && categoryInputMapping.Crude_Charge_Rate[0].unitSymbol !== crudeUnit) {
                isAnyDefaultUnitChanged = true;
                if((unitType ==='coker' || unitType ==='fcc') && isWithDesalter)
                messageArray.push({
                  'Unit Charge Rate':
                    'You have selected Unit Charge unit as ' +
                    crudeUnit +
                    '. Default unit for Unit Charge is ' +
                    categoryInputMapping.Crude_Charge_Rate[0].unitSymbol,
                });
                else
                messageArray.push({
                  'Crude Charge Rate':
                    'You have selected Crude Charge unit as ' +
                    crudeUnit +
                    '. Default unit for Crude Charge is ' +
                    categoryInputMapping.Crude_Charge_Rate[0].unitSymbol,
                });
              }
            } if (Object.keys(categoryInputMapping.Overhead_Liquid_Flow).length) {
              categoryInputMapping.Overhead_Liquid_Flow.forEach((liquidItem: any) => {
                if(unitType !=='coker' && unitType !=='fcc') {
                if (liquidItem.input === 'Combined_Reflux' && liquidItem.unitSymbol !== combinedUnit) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    'Total Combined Reflux Flow':
                      'You have selected Total Combined Reflux Flow unit as ' + combinedUnit + '. Default unit for Total Combined Reflux Flow is ' + liquidItem.unitSymbol,
                  });
                }
                }
                if (configType === '1A' || configType === '1B' || configType === '1C') {
                  if (liquidItem.input === 'Reflux' && liquidItem.unitSymbol !== refluxUnit) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Reflux unit as ' + refluxUnit + '. Default unit for Reflux is ' + liquidItem.unitSymbol,
                    });
                  }
                  if (liquidItem.input === 'Product' && liquidItem.unitSymbol !== productUnit) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Product unit as ' + productUnit + '. Default unit for Product is ' + liquidItem.unitSymbol,
                    });
                  }
                } else if (configType === '2A' || configType === '2B' || configType === '2C') {
                  if (liquidItem.input === 'Hot Reflux' && liquidItem.unitSymbol !== refluxUnit) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Hot Reflux unit as ' + refluxUnit + '. Default unit for Hot Reflux is ' + liquidItem.unitSymbol,
                    });
                  }
                  if (liquidItem.input === 'Hot Product' && liquidItem.unitSymbol !== productUnit) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Hot Product unit as ' +
                        productUnit +
                        '. Default unit for Hot Product is ' +
                        liquidItem.unitSymbol,
                    });
                  }
                  if (liquidItem.input === 'Cold Reflux' && liquidItem.unitSymbol !== refluxUnit1) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Cold Reflux unit as ' +
                        refluxUnit1 +
                        '. Default unit for Cold Reflux is ' +
                        liquidItem.unitSymbol,
                    });
                  }
                  if (liquidItem.input === 'Cold Product' && liquidItem.unitSymbol !== productUnit1) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      'Overhead Liquid Flow':
                        'You have selected Cold Product unit as ' +
                        productUnit1 +
                        '. Default unit for Cold Product is ' +
                        liquidItem.unitSymbol,
                    });
                  }
                }
              });
            }
            if (
              categoryInputMapping.Overhead_Neutralizer_Rate && Object.keys(categoryInputMapping.Overhead_Neutralizer_Rate).length &&
              categoryInputMapping.Overhead_Neutralizer_Rate[0] &&
              categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol && (unitType !== 'coker' && unitType !== 'fcc')
            ) {
              if (categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol !== neutralizerRateUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Overhead Neutralizer Rate':
                    'You have selected Neutralizer Rate unit as ' +
                    neutralizerRateUnit +
                    '. Default unit for Neutralizer Rate is ' +
                    categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol,
                });
              }
              if (categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol !== neutralizerRateUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Overhead Neutralizer Rate':
                    'You have selected Total External Neutralizer Flow unit as ' +
                    neutralizerRateUnit +
                    '. Default unit for Total External Neutralizer Flow is ' +
                    categoryInputMapping.Overhead_Neutralizer_Rate[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Overhead_Water_Wash_Rate).length &&
              categoryInputMapping.Overhead_Water_Wash_Rate[0] &&
              categoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol
            ) {
              if (categoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol !== washRateUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Overhead Water Primary Wash Injection Rate':
                    'You have selected Primary Wash Injection Rate unit as ' +
                    washRateUnit +
                    '. Default unit for Primary Wash Injection Rate is ' +
                    categoryInputMapping.Overhead_Water_Wash_Rate[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Overhead_Gas_Flow).length &&
              categoryInputMapping.Overhead_Gas_Flow[0] &&
              categoryInputMapping.Overhead_Gas_Flow[0].unitSymbol
            ) {
              if (categoryInputMapping.Overhead_Gas_Flow[0].unitSymbol !== offGasUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Overhead Gas Flow':
                    'You have selected Off Gas unit as ' +
                    offGasUnit +
                    '. Default unit for Off Gas is ' +
                    categoryInputMapping.Overhead_Gas_Flow[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.First_Side_Stripper_Steam && Object.keys(categoryInputMapping.First_Side_Stripper_Steam).length &&
              categoryInputMapping.First_Side_Stripper_Steam[0] &&
              categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol 
            ) {
              if ((steam1SSUnit && categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol !== steam1SSUnit) || (totalSSSteamUnit && categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol !== totalSSSteamUnit)) {
                isAnyDefaultUnitChanged = true;
                if((unitType ==='coker' || unitType ==='fcc') && isWithDesalter)
                  messageArray.push({
                    'Total SS Steam':
                      'You have selected Total SS Steam unit as ' +
                      totalSSSteamUnit +
                      '. Default unit for Total SS Steam is ' +
                      categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol,
                  });
                  else
                messageArray.push({
                  '1st Side Stripper Steam':
                    'You have selected Steam 1° SS unit as ' +
                    steam1SSUnit +
                    '. Default unit for Steam 1° SS is ' +
                    categoryInputMapping.First_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Second_Side_Stripper_Steam && Object.keys(categoryInputMapping.Second_Side_Stripper_Steam).length &&
              categoryInputMapping.Second_Side_Stripper_Steam[0] &&
              categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol 
            ) {
              if ((steam2SSUnit && categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol !== steam2SSUnit) || (totalFurnaceSteamUnit && categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol !== totalFurnaceSteamUnit)) {
                isAnyDefaultUnitChanged = true;
                if((unitType ==='coker' || unitType ==='fcc') && isWithDesalter)
                messageArray.push({
                    'Total Furnace Steam':
                      'You have selected Total Furnace Steam SS unit as ' +
                      totalFurnaceSteamUnit +
                      '. Default unit for Total Furnace Steam SS is ' +
                      categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol,
                  });
                else
                messageArray.push({
                  '2nd Side Stripper Steam':
                    'You have selected Steam 2° SS unit as ' +
                    steam2SSUnit +
                    '. Default unit for Steam 2° SS is ' +
                    categoryInputMapping.Second_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Third_Side_Stripper_Steam && Object.keys(categoryInputMapping.Third_Side_Stripper_Steam).length &&
              categoryInputMapping.Third_Side_Stripper_Steam[0] &&
              categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol 
            ) {
              if ((steam3SSUnit && categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol !== steam3SSUnit) || (totalOtherSteamUnit && categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol !== totalOtherSteamUnit)) {
                isAnyDefaultUnitChanged = true;
                if((unitType ==='coker' || unitType ==='fcc') && isWithDesalter)
                messageArray.push({
                    'Total Other Steam':
                      'You have selected Total Other Steam unit as ' +
                      totalOtherSteamUnit +
                      '. Default unit for Total Other Steam is ' +
                      categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol,
                  });
                else
                messageArray.push({
                  '3rd Side Stripper Steam':
                    'You have selected Steam 3° SS unit as ' +
                    steam3SSUnit +
                    '. Default unit for Steam 3° SS is ' +
                    categoryInputMapping.Third_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Fourth_Side_Stripper_Steam && Object.keys(categoryInputMapping.Fourth_Side_Stripper_Steam).length &&
              categoryInputMapping.Fourth_Side_Stripper_Steam[0] &&
              categoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol 
              && ((unitType !=='coker' && unitType !=='fcc') || ((unitType ==='coker' || unitType ==='fcc') && !isWithDesalter))
            ) {
              if (categoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol !== steam4SSUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  '4th Side Stripper Steam':
                    'You have selected Steam 4° SS unit as ' +
                    steam4SSUnit +
                    '. Default unit for Steam 4° SS is ' +
                    categoryInputMapping.Fourth_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Fifth_Side_Stripper_Steam && Object.keys(categoryInputMapping.Fifth_Side_Stripper_Steam).length &&
              categoryInputMapping.Fifth_Side_Stripper_Steam[0] &&
              categoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol && ((unitType !=='coker' && unitType !=='fcc') || ((unitType ==='coker' || unitType ==='fcc') && !isWithDesalter))
            ) {
              if (categoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol !== steam5SSUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  '5th Side Stripper Steam':
                    'You have selected Steam 5° SS unit as ' +
                    steam5SSUnit +
                    '. Default unit for Steam 5° SS is ' +
                    categoryInputMapping.Fifth_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Sixth_Side_Stripper_Steam && Object.keys(categoryInputMapping.Sixth_Side_Stripper_Steam).length &&
              categoryInputMapping.Sixth_Side_Stripper_Steam[0] &&
              categoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol && ((unitType !=='coker' && unitType !=='fcc') || ((unitType ==='coker' || unitType ==='fcc') && !isWithDesalter))
            ) {
              if (categoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol !== steam6SSUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  '6th Side Stripper Steam':
                    'You have selected Steam 6° SS unit as ' +
                    steam6SSUnit +
                    '. Default unit for Steam 6° SS is ' +
                    categoryInputMapping.Sixth_Side_Stripper_Steam[0].unitSymbol,
                });
              }
            }
            if (
              categoryInputMapping.Stripping_Steam_to_Tower && Object.keys(categoryInputMapping.Stripping_Steam_to_Tower).length &&
              categoryInputMapping.Stripping_Steam_to_Tower[0] &&
              categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol && ((unitType !=='coker' && unitType !=='fcc') || ((unitType ==='coker' || unitType ==='fcc') && !isWithDesalter))
            ) {
              if ((steamTowerUnit && categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol !== steamTowerUnit) || (totalTowerSteamUnit && categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol !== totalTowerSteamUnit)) {
                isAnyDefaultUnitChanged = true;
                if((unitType ==='coker' || unitType ==='fcc') && isWithDesalter)
                  messageArray.push({
                      'Total Tower Steam':
                        'You have selected Total Tower Steam unit as ' +
                        totalOtherSteamUnit +
                        '. Default unit for Total Tower Steam is ' +
                        categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol,
                    });
                  else
                messageArray.push({
                  'Stripping Steam to Tower':
                    'You have selected Steam Tower unit as ' +
                    steamTowerUnit +
                    '. Default unit for Steam Tower is ' +
                    categoryInputMapping.Stripping_Steam_to_Tower[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Atm_Tower_Top_Pressure).length &&
              categoryInputMapping.Atm_Tower_Top_Pressure[0] &&
              categoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol
            ) {
              if (categoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol !== ohPressureUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Atm Tower Top Pressure':
                    'You have selected OH Press unit as ' +
                    ohPressureUnit +
                    '. Default unit for OH Press is ' +
                    categoryInputMapping.Atm_Tower_Top_Pressure[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Hot_Drum_Pressure).length &&
              categoryInputMapping.Hot_Drum_Pressure[0] &&
              categoryInputMapping.Hot_Drum_Pressure[0].unitSymbol
            ) {
              if (categoryInputMapping.Hot_Drum_Pressure[0].unitSymbol !== drumPressUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Hot Drum Pressure':
                    'You have selected Drum Press unit as ' +
                    drumPressUnit +
                    '. Default unit for Drum Press is ' +
                    categoryInputMapping.Hot_Drum_Pressure[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Pressure_at_Velocity_Calc).length &&
              categoryInputMapping.Pressure_at_Velocity_Calc[0] &&
              categoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol
            ) {
              if (categoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol !== velocityPressUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Pressure at Velocity Calc':
                    'You have selected Velocity Press unit as ' +
                    velocityPressUnit +
                    '. Default unit for Velocity Press is ' +
                    categoryInputMapping.Pressure_at_Velocity_Calc[0].unitSymbol,
                });
              }
            }
            if (
              Object.keys(categoryInputMapping.Pressure_before_Water_Wash).length &&
              categoryInputMapping.Pressure_before_Water_Wash[0] &&
              categoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol
            ) {
              if (categoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol !== washProcessPressUnit) {
                isAnyDefaultUnitChanged = true;
                messageArray.push({
                  'Pressure before Water Wash':
                    'You have selected Wash Process Press unit as ' +
                    washProcessPressUnit +
                    '. Default unit for Wash Process Press is ' +
                    categoryInputMapping.Pressure_before_Water_Wash[0].unitSymbol,
                });
              }
            }
            //Temperature
            if (Object.keys(categoryInputMapping.Temperature).length) {
              let tempUnit = desalterTempUnit.split('°');
              categoryInputMapping.Temperature.forEach((tempItem: any) => {
                if (tempItem.input === 'Brine Sample Temperature' && tempItem.unitSymbol !== tempUnit[1] && (unitType !=='coker' && unitType !=='fcc')) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Brine Sample Temperature unit as ' +
                      desalterTempUnit +
                      '. Default unit for Brine Sample Temperature is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'Desalter Temperature' && tempItem.unitSymbol !== tempUnit[1] && (unitType !=='coker' && unitType !=='fcc')) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Desalter Temperature unit as ' +
                      desalterTempUnit +
                      '. Default unit for Desalter Temperature is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'OH Temp' && tempItem.unitSymbol !== tempUnit[1]) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected OH Temp unit as ' +
                      desalterTempUnit +
                      '. Default unit for OH Temp is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'Wash Temp' && tempItem.unitSymbol !== tempUnit[1]) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Wash Temp unit as ' +
                      desalterTempUnit +
                      '. Default unit for Wash Temp is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'Wash Process Temp' && tempItem.unitSymbol !== tempUnit[1]) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Wash Process Temp unit as ' +
                      desalterTempUnit +
                      '. Default unit for Wash Process Temp is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'Velocity Temp' && tempItem.unitSymbol !== tempUnit[1]) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Velocity Temp unit as ' +
                      desalterTempUnit +
                      '. Default unit for Velocity Temp is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                } else if (tempItem.input === 'Drum Temp' && tempItem.unitSymbol !== tempUnit[1]) {
                  isAnyDefaultUnitChanged = true;
                  messageArray.push({
                    Temperature:
                      'You have selected Drum Temp unit as ' +
                      desalterTempUnit +
                      '. Default unit for Drum Temp is ' +
                      '°' +
                      tempItem.unitSymbol +
                      '.',
                  });
                }
                if (configType === '1A' || configType === '1B' || configType === '1C') {
                  if (tempItem.input === 'Distillation Temp0' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 0 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 0 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp5' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 5 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 5 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp10' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 10 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 10 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp20' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 20 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 20 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp30' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 30 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 30 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp50' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 50 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 50 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp70' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 70 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 70 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp80' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 80 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 80 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp90' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 90 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 90 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp95' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 95 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 95 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp100' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 100 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Distillation 100 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  }
                } else if (configType === '2A' || configType === '2B' || configType === '2C') {
                  if (tempItem.input === 'Distillation Temp_0' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 0 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 0 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_5' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 5 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 5 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_10' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 10 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 10 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_20' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 20 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 20 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_30' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 30 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 30 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_50' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 50 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 50 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_70' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 70 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 70 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_80' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 80 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 80 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_90' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 90 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 90 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_95' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 95 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 95 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  } else if (tempItem.input === 'Distillation Temp_100' && tempItem.unitSymbol !== tempUnit[1]) {
                    isAnyDefaultUnitChanged = true;
                    messageArray.push({
                      Temperature:
                        'You have selected Cold Distillation 100 unit as ' +
                        desalterTempUnit +
                        '. Default unit for Cold Distillation 100 is ' +
                        '°' +
                        tempItem.unitSymbol +
                        '.',
                    });
                  }
                }
              });
            }
          }
          setDefaultUnitWarningObj(messageArray);
          break;
        //pressure
        case 3:
          ohPressPressure = excelData[i]['OH Press'];
          washProcessPressPressure = excelData[i]['Wash Process Press'];
          velocityPressPressure = excelData[i]['Velocity Press'];
          if (configType === '1A' || configType === '1B' || configType === '1C') {
            drumPressPressure = excelData[i]['Drum Press'];
          } else if (configType === '2A' || configType === '2B' || configType === '2C') {
            drumPressPressure = excelData[i]['Hot Drum Press'];
          }
          break;
        //time
        case 4:
          crudeTime = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Crude Charge'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Unit Charge']:null );
          offGasTime = excelData[i]['Off Gas'];
          washRateTime =  excelData[i]['Primary Wash Injection Rate'];
          neutralizerRateTime = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Neutralizer Rate'];
          outsideNeutralizerTime = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total External Neutralizer Flow'];
          if((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)){
          steamTowerTime = excelData[i]['Steam Tower'];
          steam1SSTime = excelData[i]['Steam 1° SS'];
          steam2SSTime = excelData[i]['Steam 2° SS'];
          steam3SSTime = excelData[i]['Steam 3° SS'];
          steam4SSTime = excelData[i]['Steam 4° SS'];
          steam5SSTime = excelData[i]['Steam 5° SS'];
          steam6SSTime = excelData[i]['Steam 6° SS'];
          }
          else if((unitType === 'coker' || unitType === 'fcc') && isWithDesalter){
          totalTowerSteamTime = excelData[i]['Total Tower Steam'];
          totalSSSteamTime = excelData[i]['Total SS Steam'];
          totalFurnaceSteamTime = excelData[i]['Total Furnace Steam'];
          totalOtherSteamTime = excelData[i]['Total Other Steam'];
          }
          break;
        //mass
        case 5:
          offGasMass = excelData[i]['Off Gas'];
          if((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)){
          steamTowerMass = excelData[i]['Steam Tower'];
          steam1SSMass = excelData[i]['Steam 1° SS'];
          steam2SSMass = excelData[i]['Steam 2° SS'];
          steam3SSMass = excelData[i]['Steam 3° SS'];
          steam4SSMass = excelData[i]['Steam 4° SS'];
          steam5SSMass = excelData[i]['Steam 5° SS'];
          steam6SSMass = excelData[i]['Steam 6° SS'];
          }
          else if((unitType === 'coker' || unitType === 'fcc') && isWithDesalter){
            totalTowerSteamMass = excelData[i]['Total Tower Steam'];
            totalSSSteamMass = excelData[i]['Total SS Steam'];
            totalFurnaceSteamMass = excelData[i]['Total Furnace Steam'];
            totalOtherSteamMass = excelData[i]['Total Other Steam'];
            }
          break;
        //volume
        case 6:
          crudeVolume = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Crude Charge'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Unit Charge']:null );
          offGasVolume = excelData[i]['Off Gas'];
          washRateVolume =  excelData[i]['Primary Wash Injection Rate'];
          neutralizerRateVolume = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Neutralizer Rate'];
          outsideNeutralizerVolume = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total External Neutralizer Flow'];
          break;
        //Std Vol
        case 7:
          offGastStdVol = excelData[i]['Off Gas'];
          break;
        //min values
        case 9:
          crudeMin = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Crude Charge'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Unit Charge']:null );
          crudegravityMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Crude Gravity'];
          if (configType === '1A' || configType === '2A' || configType === '1C' || configType === '2C') {
            if(configType === '1C' || configType === '2C') desCrudeBSWMin = excelData[i]['Crude BS&W'];
            else desCrudeBSWMin = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Des Crude BS&W'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Des charge BS&W']:null );
          }
            if (configType === '1A' || configType === '2A') {
            washPercentageMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Wash %'];
            brinepHMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Brine pH'];
          } else if (configType === '1B' || configType === '2B') {
            wash1PercentageMin = excelData[i]['1° Wash %'];
            wash2PercentageMin = excelData[i]['2° Wash %'];
            brine1pHMin = excelData[i]['1° Brine pH'];
            brine2pHMin = excelData[i]['2° Brine pH'];
            desCrudeBSWMin1 = excelData[i]['1° Desalter BS&W Out'];
            desCrudeBSWMin2 = excelData[i]['2° Desalter BS&W Out'];
          }
          brineRecyclePercentageMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['Brine Recycle %'];
          percentageAtmH2OtoDesalterMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['%Atm H2O to Desalter'];
          percentageAtmH2OtoSWSMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['%Atm H2O to SWS'];
          percentageSSWtoDesalterMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['%SSW to Desalter'];
          desalterTemperatureMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['Desalter Temp'];
          brineSampleTemperatureMin = (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') && excelData[i]['Brine Sample Temp'];
          gravityMin = excelData[i]['Gravity'];
          distillationMin = excelData[i]['0'];
          offGasMin = excelData[i]['Off Gas'];
          H2Min = excelData[i]['H2'];
          H2SMin = excelData[i]['H2S'];
          CO2Min = excelData[i]['CO2'];
          C1Min = excelData[i]['C1'];
          C2Min = excelData[i]['C2'];
          C3Min = excelData[i]['C3'];
          iC4Min = excelData[i]['iC4'];
          nC4Min = excelData[i]['nC4'];
          iC5Min = excelData[i]['iC5'];
          nC5Min = excelData[i]['nC5'];
          nC6Min = excelData[i]['nC6'];
          ohTempMin = excelData[i]['OH Temp'];
          ohPressMin = excelData[i]['OH Press'];
          washRateMin =  excelData[i]['Primary Wash Injection Rate'];
          totalExternalWashRateMin =  excelData[i]['Total External Wash Rate'];
          washTempMin = excelData[i]['Wash Temp'];
          washProcessTempMin = excelData[i]['Wash Process Temp'];
          washProcessPressMin = excelData[i]['Wash Process Press'];
          velocityTemMin = excelData[i]['Velocity Temp'];
          velocityPressMin = excelData[i]['Velocity Press'];
          combinedRefluxMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total Combined Reflux Flow'];
          combinedRefluxTempMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total Combined Reflux Temperature'];
          if (configType === '1A' || configType === '1B' || configType === '1C') {
            //brMin = excelData[i]['Br'];
            drumPressMin = excelData[i]['Drum Press'];
            drumTempMin = excelData[i]['Drum Temp'];
            pHMin = excelData[i]['pH'];
            clMin = excelData[i]['Cl'];
            NH3Min = excelData[i]['NH3'];
            trampAmineppmMin = excelData[i]['Tramp Amine ppm'];
            steamAmineppmMin = excelData[i]['Steam Amine ppm'];
          } else if (configType === '2A' || configType === '2B' || configType === '2C') {
            //brMin = excelData[i]['1° Br'];
            //br2Min = excelData[i]['2 ° Br'];
            drumPressMin = excelData[i]['Hot Drum Press'];
            drumTempMin = excelData[i]['Hot Drum Temp'];
            refluxMin1 = excelData[i]['Reflux_1'];
            productMin1 = excelData[i]['Product_1'];
            gravityMin1 = excelData[i]['Gravity_1'];
            distillationMin1 = excelData[i]['0_1'];
            pHMin1 = excelData[i]['1° pH'];
            pHMin2 = excelData[i]['2° pH'];
            clMin1 = excelData[i]['1° Cl'];
            clMin2 = excelData[i]['2° Cl'];
            NH3Min1 = excelData[i]['1° NH3'];
            NH3Min2 = excelData[i]['2 ° NH3'];
            trampAmineppmMin1 = excelData[i]['1° Tramp Amine ppm'];
            trampAmineppmMin2 = excelData[i]['2° Tramp Amine ppm'];
            steamAmineppmMin1 = excelData[i]['1° Steam Amine ppm'];
            steamAmineppmMin2 = excelData[i]['2° Steam Amine ppm'];
          }
          refluxMin = excelData[i]['Reflux'];
          productMin = excelData[i]['Product'];
          neutralizerRateMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Neutralizer Rate'];
          outsideNeutralizerMin = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total External Neutralizer Flow'];
          if((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)){
            steamTowerMin = excelData[i]['Steam Tower'];
            steam1SSMin = excelData[i]['Steam 1° SS'];
            steam2SSMin = excelData[i]['Steam 2° SS'];
            steam3SSMin = excelData[i]['Steam 3° SS'];
            steam4SSMin = excelData[i]['Steam 4° SS'];
            steam5SSMin = excelData[i]['Steam 5° SS'];
            steam6SSMin = excelData[i]['Steam 6° SS'];
          }
          else if((unitType === 'coker' || unitType === 'fcc') && isWithDesalter){
            totalTowerSteamMin = excelData[i]['Total Tower Steam'];
            totalSSSteamMin = excelData[i]['Total SS Steam'];
            totalFurnaceSteamMin = excelData[i]['Total Furnace Steam'];
            totalOtherSteamMin = excelData[i]['Total Other Steam'];
          }
          sulphideMin = (unitType === 'coker' || unitType === 'fcc') && excelData[i]['Sulfide'];
          break;
        //max values
        case 10:
          crudeMax = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Crude Charge'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Unit Charge']:null );
          crudegravityMax =(unitType !=='coker' && unitType !=='fcc') && excelData[i]['Crude Gravity'];
          if (configType === '1A' || configType === '2A') {
            //brMax = excelData[i]['Br'];
            washPercentageMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Wash %'];
            brinepHMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Brine pH'];
          } else if (configType === '1B' || configType === '2B') {
            //brMax = excelData[i]['1° Br'];
            //br2Max = excelData[i]['2 ° Br'];
            wash1PercentageMax = excelData[i]['1° Wash %'];
            wash2PercentageMax = excelData[i]['2° Wash %'];
            brine1pHMax = excelData[i]['1° Brine pH'];
            brine2pHMax = excelData[i]['2° Brine pH'];
            desCrudeBSWMax1 = excelData[i]['1° Desalter BS&W Out'];
            desCrudeBSWMax2 = excelData[i]['2° Desalter BS&W Out'];
          }
          brineRecyclePercentageMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['Brine Recycle %'];
          percentageAtmH2OtoDesalterMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['%Atm H2O to Desalter'];
          percentageAtmH2OtoSWSMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['%Atm H2O to SWS'];
          percentageSSWtoDesalterMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['%SSW to Desalter'];
          desalterTemperatureMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['Desalter Temp'];
          brineSampleTemperatureMax = (unitType !=='coker' && unitType !=='fcc'&& configType !== '1C' && configType !=='2C') && excelData[i]['Brine Sample Temp'];
          if(configType === '1C' || configType === '2C') desCrudeBSWMax = excelData[i]['Crude BS&W'];
            else desCrudeBSWMax = (unitType !=='coker' && unitType !=='fcc') ? excelData[i]['Des Crude BS&W'] : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[i]['Des charge BS&W']:null );
          gravityMax = excelData[i]['Gravity'];
          distillationMax = excelData[i]['0'];
          offGasMax = excelData[i]['Off Gas'];
          H2Max = excelData[i]['H2'];
          H2SMax = excelData[i]['H2S'];
          CO2Max = excelData[i]['CO2'];
          C1Max = excelData[i]['C1'];
          C2Max = excelData[i]['C2'];
          C3Max = excelData[i]['C3'];
          iC4Max = excelData[i]['iC4'];
          nC4Max = excelData[i]['nC4'];
          iC5Max = excelData[i]['iC5'];
          nC5Max = excelData[i]['nC5'];
          nC6Max = excelData[i]['nC6'];
          ohTempMax = excelData[i]['OH Temp'];
          ohPressMax = Math.round((parseFloat(excelData[i]['OH Press']) + Number.EPSILON) * 100) / 100;
          washRateMax =  Math.round((parseFloat(excelData[i]['Primary Wash Injection Rate']) + Number.EPSILON) * 100000) / 100000;
          totalExternalWashRateMax = Math.round((parseFloat(excelData[i]['Total External Wash Rate']) + Number.EPSILON) * 100000) / 100000;
          washTempMax = excelData[i]['Wash Temp'];
          washProcessTempMax = excelData[i]['Wash Process Temp'];
          washProcessPressMax = Math.round((parseFloat(excelData[i]['Wash Process Press']) + Number.EPSILON) * 100) / 100;
          velocityTemMax = excelData[i]['Velocity Temp'];
          velocityPressMax = Math.round((parseFloat(excelData[i]['Velocity Press']) + Number.EPSILON) * 100) / 100;
          combinedRefluxMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total Combined Reflux Flow']
          combinedRefluxTempMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total Combined Reflux Temperature'];
          if (configType === '1A' || configType === '1B' || configType === '1C') {
            drumPressMax = Math.round((parseFloat(excelData[i]['Drum Press']) + Number.EPSILON) * 100) / 100;
            drumTempMax = excelData[i]['Drum Temp'];
            pHMax = excelData[i]['pH'];
            clMax = excelData[i]['Cl'];
            NH3Max = excelData[i]['NH3'];
            trampAmineppmMax = excelData[i]['Tramp Amine ppm'];
            steamAmineppmMax = excelData[i]['Steam Amine ppm'];
          } else if (configType === '2A' || configType === '2B' || configType === '2C') {
            drumPressMax = Math.round((parseFloat(excelData[i]['Hot Drum Press']) + Number.EPSILON) * 100) / 100;
            drumTempMax = excelData[i]['Hot Drum Temp'];
            refluxMax1 = excelData[i]['Reflux_1'];
            productMax1 = excelData[i]['Product_1'];
            gravityMax1 = excelData[i]['Gravity_1'];
            distillationMax1 = excelData[i]['0_1'];
            pHMax1 = excelData[i]['1° pH'];
            pHMax2 = excelData[i]['2° pH'];
            clMax1 = excelData[i]['1° Cl'];
            clMax2 = excelData[i]['2° Cl'];
            NH3Max1 = excelData[i]['1° NH3'];
            NH3Max2 = excelData[i]['2 ° NH3'];
            trampAmineppmMax1 = excelData[i]['1° Tramp Amine ppm'];
            trampAmineppmMax2 = excelData[i]['2° Tramp Amine ppm'];
            steamAmineppmMax1 = excelData[i]['1° Steam Amine ppm'];
            steamAmineppmMax2 = excelData[i]['2° Steam Amine ppm'];
          }
          refluxMax = excelData[i]['Reflux'];
          productMax = excelData[i]['Product'];
          neutralizerRateMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Neutralizer Rate'];
          outsideNeutralizerMax = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total External Neutralizer Flow'];
          if((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)){
          steamTowerMax = excelData[i]['Steam Tower'];
          steam1SSMax = excelData[i]['Steam 1° SS'];
          steam2SSMax = excelData[i]['Steam 2° SS'];
          steam3SSMax = excelData[i]['Steam 3° SS'];
          steam4SSMax = excelData[i]['Steam 4° SS'];
          steam5SSMax = excelData[i]['Steam 5° SS'];
          steam6SSMax = excelData[i]['Steam 6° SS'];
          }
          else if((unitType === 'coker' || unitType === 'fcc') && isWithDesalter){
            totalTowerSteamMax = excelData[i]['Total Tower Steam'];
            totalSSSteamMax = excelData[i]['Total SS Steam'];
            totalFurnaceSteamMax = excelData[i]['Total Furnace Steam'];
            totalOtherSteamMax = excelData[i]['Total Other Steam'];
          }
          sulphideMax = (unitType === 'coker' || unitType === 'fcc') && excelData[i]['Sulfide'];
          break;
        //unit
        case 11:
          distillationunit = excelData[i]['0'];
          ohTempUnit = excelData[i]['OH Temp'];
          combinedRefluxUnit = (unitType !=='coker' && unitType !=='fcc') && excelData[i]['Total Combined Reflux Temperature'];
          washTempUnit = excelData[i]['Wash Temp'];
          if (configType === '1A' || configType === '1B' || configType === '1C') {
            drumTempUnit = excelData[i]['Drum Temp'];
            distillationunit1 = excelData[i]['0'];
          } else if (configType === '2A' || configType === '2B' || configType === '2C') {
            drumTempUnit = excelData[i]['Hot Drum Temp'];
            distillationunit1 = excelData[i]['0_1'];
          }
          break;
        //input values
        case 12:
          //looping in case of multiple inputs
          for (let j = 12; j < excelData.length; j++) {
            simulationData.push({
              inputDate: excelData[j]['Date'] ? excelData[j]['Date'] : excelData[j]['Date (mm/dd/yyyy)'],
              desalter: {
                brinepHData: {
                  brinepH: ((unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? ((configType === '1A' || configType === '2A') ? excelData[j]['Brine pH'] : excelData[j]['1° Brine pH']): null,
                  brinepH2: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && excelData[j]['2° Brine pH']): null,
                  brinepHMin: ((unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? ((configType === '1A' || configType === '2A') ? brinepHMin : brine1pHMin): null,
                  brinepHMax: ((unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') || ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? ((configType === '1A' || configType === '2A') ? brinepHMax : brine1pHMax): null,
                  brine2pHMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && brine2pHMin) : null,
                  brine2pHMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && brine2pHMax) : null,
                },
                brineSampleTemperature: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['Brine Sample Temp']:null,
                brineSampleTemperatureMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? brineSampleTemperatureMin:null,
                brineSampleTemperatureMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? brineSampleTemperatureMax:null,
                desCrudeBSWData: {
                  desCrudeBSW: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1A' || configType === '2A') ? excelData[j]['Des Crude BS&W'] : excelData[j]['1° Desalter BS&W Out']) : 
                  (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Des charge BS&W']:((configType === '1C' || configType === '2C') ? excelData[j]['Crude BS&W']:null)),
                  desCrudeBSW2: (unitType !=='coker' && unitType !=='fcc') ? ((configType === '1B' || configType === '2B') && excelData[j]['2° Desalter BS&W Out']): null,
                  desCrudeBSWMin:  (configType === '1A' || configType === '2A' || configType === '1C' || configType === '2C' ? desCrudeBSWMin : desCrudeBSWMin1),
                  desCrudeBSWMax: (configType === '1A' || configType === '2A' || configType === '1C' || configType === '2C' ? desCrudeBSWMax : desCrudeBSWMax1),
                  desCrudeBSWMin2: (unitType !=='coker' && unitType !=='fcc') ? (configType === '1B' || configType === '2B') && desCrudeBSWMin2:null,
                  desCrudeBSWMax2: (unitType !=='coker' && unitType !=='fcc') ? (configType === '1B' || configType === '2B') && desCrudeBSWMax2:null,
                },
                crudeCharge: (unitType !=='coker' && unitType !=='fcc') ? excelData[j]['Crude Charge']:(((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Unit Charge']:null) ,
                crudeCharge_UOM: crudeUnit,
                chargeGravity : (((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Charge Gravity']:null ),
                crudeCharge_Time: crudeTime,
                crudeCharge_Volume: crudeVolume,
                crudeChargeMin: crudeMin,
                crudeChargeMax: crudeMax,
                crudeGravity: (unitType !=='coker' && unitType !=='fcc') ? excelData[j]['Crude Gravity']: null,
                crudeGravityMin: (unitType !=='coker' && unitType !=='fcc') ? crudegravityMin : null,
                crudeGravityMax: (unitType !=='coker' && unitType !=='fcc') ? crudegravityMax : null,
                washPercentageData: {
                  washPercentage: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? (configType === '1A' || configType === '2A' ? excelData[j]['Wash %'] : excelData[j]['1° Wash %']) : null,
                  washPercentage2: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && excelData[j]['2° Wash %']) : null,
                  washPercentageMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1A' || configType === '2A') ? washPercentageMin : wash1PercentageMin):null,
                  washPercentageMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1A' || configType === '2A') ? washPercentageMax : wash1PercentageMax):null,
                  wash2PercentageMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && wash2PercentageMin):null,
                  wash2PercentageMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? ((configType === '1B' || configType === '2B') && wash2PercentageMax):null,
                },
                desalterTemperature: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['Desalter Temp']: null,
                desalterTemperatureMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? desalterTemperatureMin: null,
                desalterTemperatureMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? desalterTemperatureMax: null,
                desalterTemperature_UOM: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? desalterTempUnit: null,
                brineRecyclePercentage: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['Brine Recycle %']: null,
                brineRecyclePercentageMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? brineRecyclePercentageMin: null,
                brineRecyclePercentageMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? brineRecyclePercentageMax: null,
                percentageAtmH2OtoDesalter: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['%Atm H2O to Desalter']: null,
                percentageAtmH2OtoDesalterMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageAtmH2OtoDesalterMin: null,
                percentageAtmH2OtoDesalterMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageAtmH2OtoDesalterMax: null,
                percentageAtmH2OtoSWS: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['%Atm H2O to SWS']: null,
                percentageAtmH2OtoSWSMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageAtmH2OtoSWSMin: null,
                percentageAtmH2OtoSWSMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageAtmH2OtoSWSMax: null,
                percentageSSWtoDesalter: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? excelData[j]['%SSW to Desalter']: null,
                percentageSSWtoDesalterMin: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageSSWtoDesalterMin: null,
                percentageSSWtoDesalterMax: (unitType !=='coker' && unitType !=='fcc' && configType !== '1C' && configType !=='2C') ? percentageSSWtoDesalterMax: null,
              },
              liquidAssayStreams:
                configType === '1A' || configType === '1B' || configType === '1C'
                  ? {
                      hydrocarbonLiquid: {
                        reflux: excelData[j]['Reflux'],
                        reflux_UOM: refluxUnit,
                        refluxMin: refluxMin,
                        refluxMax: refluxMax,
                        product: excelData[j]['Product'],
                        product_UOM: productUnit,
                        productMin: productMin,
                        productMax: productMax,
                        gravity: excelData[j]['Gravity'],
                        gravityMin: gravityMin,
                        gravityMax: gravityMax,
                        distillationType: excelData[j]['Distillation'],
                        distillation: {
                          0: excelData[j]['0'],
                          5: excelData[j]['5'],
                          10: excelData[j]['10'],
                          20: excelData[j]['20'],
                          30: excelData[j]['30'],
                          40: excelData[j]['40'],
                          50: excelData[j]['50'],
                          60: excelData[j]['60'],
                          70: excelData[j]['70'],
                          80: excelData[j]['80'],
                          90: excelData[j]['90'],
                          95: excelData[j]['95'],
                          100: excelData[j]['100'],
                          distillation_UOM: distillationunit1,
                          distillationMin: distillationMin,
                          distillationMax: distillationMax,
                        },
                      },
                      isWetReflux: excelData[j]['Wet Reflux'],
                    }
                  : {
                      hydrocarbonLiquidHot: {
                        reflux: excelData[j]['Reflux'],
                        reflux_UOM: refluxUnit,
                        refluxMin: refluxMin,
                        refluxMax: refluxMax,
                        product: excelData[j]['Product'],
                        product_UOM: productUnit,
                        productMin: productMin,
                        productMax: productMax,
                        gravity: excelData[j]['Gravity'],
                        gravityMin: gravityMin,
                        gravityMax: gravityMax,
                        distillationType: excelData[j]['Distillation'],
                        distillation: {
                          0: excelData[j]['0'],
                          5: excelData[j]['5'],
                          10: excelData[j]['10'],
                          20: excelData[j]['20'],
                          30: excelData[j]['30'],
                          40: excelData[j]['40'],
                          50: excelData[j]['50'],
                          70: excelData[j]['70'],
                          80: excelData[j]['80'],
                          90: excelData[j]['90'],
                          95: excelData[j]['95'],
                          100: excelData[j]['100'],
                          distillation_UOM: distillationunit,
                          distillationMin: distillationMin,
                          distillationMax: distillationMax,
                        },
                      },
                      hydrocarbonLiquidCold: {
                        reflux: excelData[j]['Reflux_1'],
                        reflux_UOM: refluxUnit1,
                        refluxMin: refluxMin1,
                        refluxMax: refluxMax1,
                        product: excelData[j]['Product_1'],
                        product_UOM: productUnit1,
                        productMin: productMin1,
                        productMax: productMax1,
                        gravity: excelData[j]['Gravity_1'],
                        gravityMin: gravityMin1,
                        gravityMax: gravityMax1,
                        distillationType: excelData[j]['Distillation_1'],
                        distillation: {
                          0: excelData[j]['0_1'],
                          5: excelData[j]['5_1'],
                          10: excelData[j]['10_1'],
                          20: excelData[j]['20_1'],
                          30: excelData[j]['30_1'],
                          40: excelData[j]['40_1'],
                          50: excelData[j]['50_1'],
                          70: excelData[j]['70_1'],
                          80: excelData[j]['80_1'],
                          90: excelData[j]['90_1'],
                          95: excelData[j]['95_1'],
                          100: excelData[j]['100_1'],
                          distillation_UOM: distillationunit1,
                          distillationMin: distillationMin1,
                          distillationMax: distillationMax1,
                        },
                      },
                      isWetReflux: excelData[j]['Wet Reflux'],
                    },
              offGasData: {
                offGas: excelData[j]['Off Gas'],
                offGas_UOM: offGasUnit,
                offGasMass: offGasMass,
                offGasMax: offGasMax,
                offGasMin: offGasMin,
                offGasTime: offGasTime,
                offGasVolume: offGasVolume,
                offGastStdVol: offGastStdVol,
                H2: excelData[j]['H2'],
                H2Min: H2Min,
                H2Max: H2Max,
                H2S: excelData[j]['H2S'],
                H2SMin: H2SMin,
                H2SMax: H2SMax,
                CO2: excelData[j]['CO2'],
                CO2Min: CO2Min,
                CO2Max: CO2Max,
                C1: excelData[j]['C1'],
                C1Min: C1Min,
                C1Max: C1Max,
                C2: excelData[j]['C2'],
                C2Min: C2Min,
                C2Max: C2Max,
                C3: excelData[j]['C3'],
                C3Min: C3Min,
                C3Max: C3Max,
                iC4: excelData[j]['iC4'],
                iC4Min: iC4Min,
                iC4Max: iC4Max,
                nC4: excelData[j]['nC4'],
                nC4Min: nC4Min,
                nC4Max: nC4Max,
                iC5: excelData[j]['iC5'],
                iC5Min: iC5Min,
                iC5Max: iC5Max,
                nC5: excelData[j]['nC5'],
                nC5Min: nC5Min,
                nC5Max: nC5Max,
                nC6: excelData[j]['nC6'],
                nC6Min: nC6Min,
                nC6Max: nC6Max,
              },
              overheadConditions: {
                OHTemp: excelData[j]['OH Temp'],
                OHTemp_UOM: desalterTempUnit,
                ohTempMin: ohTempMin,
                ohTempMax: ohTempMax,
                OHPress: excelData[j]['OH Press'],
                OHPress_UOM: ohPressureUnit,
                ohPressMin: ohPressMin,
                ohPressMax: ohPressMax,
                ohPressPressure: ohPressPressure,
                washRate:   excelData[j]['Primary Wash Injection Rate'],
                totalExternalWashRate: excelData[j]['Total External Wash Rate'],
                totalExternalWashRateMax : totalExternalWashRateMax,
                totalExternalWashRateMin : totalExternalWashRateMin,
                totalExternalWashRateMax_UOM: totalExternalWashRate_Unit,
                totalExternalWashRate_UOM : washRateUnit,
                washRate_UOM: washRateUnit,
                washRateVolume: washRateVolume,
                washRateTime: washRateTime,
                washRateMin: washRateMin,
                washRateMax: washRateMax,
                washTemp: excelData[j]['Wash Temp'],
                washTemp_UOM: washTempUnit,
                washTempMin: washTempMin,
                washTempMax: washTempMax,
                washProcessTemp: excelData[j]['Wash Process Temp'],
                washProcessTempMin: washProcessTempMin,
                washProcessTempMax: washProcessTempMax,
                washProcessPress: excelData[j]['Wash Process Press'],
                washProcessPress_UOM: washProcessPressUnit,
                washProcessPressMin: washProcessPressMin,
                washProcessPressMax: washProcessPressMax,
                washProcessPressPressure: washProcessPressPressure,
                velocityTemp: excelData[j]['Velocity Temp'],
                velocityTemMin: velocityTemMin,
                velocityTemMax: velocityTemMax,
                velocityPress: excelData[j]['Velocity Press'],
                velocityPress_UOM: velocityPressUnit,
                velocityPressPressure: velocityPressPressure,
                velocityPressMin: velocityPressMin,
                velocityPressMax: velocityPressMax,
                drumTemp: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Drum Temp'] : excelData[j]['Hot Drum Temp'],
                drumTemp_UOM: drumTempUnit,
                drumTempMin: drumTempMin,
                drumTempMax: drumTempMax,
                drumPress: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Drum Press'] : excelData[j]['Hot Drum Press'],
                drumPress_UOM: drumPressUnit,
                drumPressPressure: drumPressPressure,
                drumPressMin: drumPressMin,
                drumPressMax: drumPressMax,
              },
              overheadWater: {
                // bromideData : {
                //   bromide: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Br'] : excelData[j]['1° Br'],
                //   bromideMin : brMin,
                //   bromideMax : brMax,
                //   bromide_2: configType === '2A' || configType === '2B' || configType === '2C' ? excelData[j]['2 ° Br'] : null,
                // },
                combinedRefluxData :{
                  combinedRefluxMin : (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxMin : null,
                  combinedRefluxMax : (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxMax : null,
                  combinedRefluxFlow : (unitType !== 'coker' && unitType !== 'fcc') ? excelData[j]['Total Combined Reflux Flow'] : null,
                  combinedRefluxTempMin : (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxTempMin : null,
                  combinedRefluxTempMax : (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxTempMax : null,
                  combinedRefluxTemp : (unitType !== 'coker' && unitType !== 'fcc') ? excelData[j]['Total Combined Reflux Temperature'] : null,
                  combinedRefluxFlow_UOM: (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxFlow_UOM : null,
                  combinedRefluxTemp_UOM : (unitType !== 'coker' && unitType !== 'fcc') ? combinedRefluxUnit : null
                },
                pHData: {
                  pH: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['pH'] : excelData[j]['1° pH'],
                  pHMin: configType === '1A' || configType === '1B' || configType === '1C' ? pHMin : pHMin1,
                  pHmax: configType === '1A' || configType === '1B' || configType === '1C' ? pHMax : pHMax1,
                  pH_2: (configType === '2A' || configType === '2B' || configType === '2C') && excelData[j]['2° pH'],
                  pHMin2: (configType === '2A' || configType === '2B' || configType === '2C') && pHMin2,
                  pHMax2: (configType === '2A' || configType === '2B' || configType === '2C') && pHMax2,
                },
                clData: {
                  cl: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Cl'] : excelData[j]['1° Cl'],
                  clMin: configType === '1A' || configType === '1B' || configType === '1C' ? clMin : clMin1,
                  clMax: configType === '1A' || configType === '1B' || configType === '1C' ? clMax : clMax1,
                  cl_2: (configType === '2A' || configType === '2B' || configType === '2C') && excelData[j]['2° Cl'],
                  clMin2: (configType === '2A' || configType === '2B' || configType === '2C') && clMin2,
                  clMax2: (configType === '2A' || configType === '2B' || configType === '2C') && clMax2,
                },
                NH3Data: {
                  NH3: configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['NH3'] : excelData[j]['1° NH3'],
                  NH3Min: configType === '1A' || configType === '1B' || configType === '1C' ? NH3Min : NH3Min1,
                  NH3Max: configType === '1A' || configType === '1B' || configType === '1C' ? NH3Max : NH3Max1,
                  NH3_2: (configType === '2A' || configType === '2B' || configType === '2C') && excelData[j]['2 ° NH3'],
                  NH3Min2: (configType === '2A' || configType === '2B' || configType === '2C') && NH3Min2,
                  NH3Max2: (configType === '2A' || configType === '2B' || configType === '2C') && NH3Max2,
                },
                sulfide: (unitType === 'coker' || unitType === 'fcc') ? excelData[j]['Sulfide'] : null,
                sulfideMin: (unitType === 'coker' || unitType === 'fcc') ? sulphideMin: null,
                sulfideMax: (unitType === 'coker' || unitType === 'fcc') ? sulphideMax : null,
                neutralizerName: (unitType !=='coker' && unitType !=='fcc') ? excelData[j]['Neutralizer'] : null,
                neutralizerRate: (unitType !=='coker' && unitType !=='fcc') ? excelData[j]['Neutralizer Rate'] : null,
                neutralizerRate_UOM: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateUnit : null,
                neutralizerRateMin: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateMin : null,
                neutralizerRateMax: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateMax : null,
                neutralizerRateTime: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateTime : null,
                neutralizerRateVolume: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateVolume : null,
                outsideNeutralizer: (unitType !=='coker' && unitType !=='fcc') ? excelData[j]['Total External Neutralizer Flow'] : null,
                outsideNeutralizer_UOM: (unitType !=='coker' && unitType !=='fcc') ? neutralizerRateUnit : null,
                outsideNeutralizerMin: (unitType !=='coker' && unitType !=='fcc') ? outsideNeutralizerMin : null,
                outsideNeutralizerMax: (unitType !=='coker' && unitType !=='fcc') ? outsideNeutralizerMax : null,
                outsideNeutralizerTime: (unitType !=='coker' && unitType !=='fcc') ? outsideNeutralizerTime : null,
                outsideNeutralizerVolume: (unitType !=='coker' && unitType !=='fcc') ? outsideNeutralizerVolume : null,
                trampAmineData: {
                  trampAmine: excelData[j]['Tramp Amine'],
                  trampAmineppm:
                    configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Tramp Amine ppm'] : excelData[j]['1° Tramp Amine ppm'],
                  trampAmineppmMin: configType === '1A' || configType === '1B' || configType === '1C' ? trampAmineppmMin : trampAmineppmMin1,
                  trampAmineppmMax: configType === '1A' || configType === '1B' || configType === '1C' ? trampAmineppmMax : trampAmineppmMax1,
                  trampAmineppm2: (configType === '2A' || configType === '2B' || configType === '2C') && excelData[j]['2° Tramp Amine ppm'],
                  trampAmineppmMin2: (configType === '2A' || configType === '2B' || configType === '2C') && trampAmineppmMin2,
                  trampAmineppmMax2: (configType === '2A' || configType === '2B' || configType === '2C') && trampAmineppmMax2,
                },
                steamAmineData: {
                  steamAmine: excelData[j]['Steam Amine'],
                  steamAmineppm:
                    configType === '1A' || configType === '1B' || configType === '1C' ? excelData[j]['Steam Amine ppm'] : excelData[j]['1° Steam Amine ppm'],
                  steamAmineppmMin: configType === '1A' || configType === '1B' || configType === '1C' ? steamAmineppmMin : steamAmineppmMin1,
                  steamAmineppmMax: configType === '1A' || configType === '1B' || configType === '1C' ? steamAmineppmMax : steamAmineppmMax1,
                  steamAmineppm2: (configType === '2A' || configType === '2B' || configType === '2C') && excelData[j]['2° Steam Amine ppm'],
                  steamAmineppmMin2: (configType === '2A' || configType === '2B' || configType === '2C') && steamAmineppmMin2,
                  steamAmineppmMax2: (configType === '2A' || configType === '2B' || configType === '2C') && steamAmineppmMax2,
                },
              },
              towerData: {
                steamTower: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Total Tower Steam'] : excelData[j]['Steam Tower'],
                steamTower_UOM: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalTowerSteamUnit :steamTowerUnit,
                steamTowerMass: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalTowerSteamMass :steamTowerMass,
                steamTowerTime: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalTowerSteamTime :steamTowerTime,
                steamTowerMin: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalTowerSteamMin :steamTowerMin,
                steamTowerMax: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalTowerSteamMax :steamTowerMax,
                steam1SS: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Total SS Steam'] :excelData[j]['Steam 1° SS'],
                steam1SS_UOM: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalSSSteamUnit :steam1SSUnit,
                steam1SSMass: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalSSSteamMass :steam1SSMass,
                steam1SSTime: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalSSSteamTime :steam1SSTime,
                steam1SSMin: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalSSSteamMin :steam1SSMin,
                steam1SSMax: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalSSSteamMax :steam1SSMax,
                steam2SS: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Total Furnace Steam']: excelData[j]['Steam 2° SS'],
                steam2SS_UOM: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalFurnaceSteamUnit : steam2SSUnit,
                steam2SSMass: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalFurnaceSteamMass :steam2SSMass,
                steam2SSTime: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalFurnaceSteamTime :steam2SSTime,
                steam2SSMin: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalFurnaceSteamMin :steam2SSMin,
                steam2SSMax: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalFurnaceSteamMax :steam2SSMax,
                steam3SS: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? excelData[j]['Total Other Steam'] :excelData[j]['Steam 3° SS'],
                steam3SS_UOM: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalOtherSteamUnit :steam3SSUnit,
                steam3SSMass: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalOtherSteamMass :steam3SSMass,
                steam3SSTime: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalOtherSteamTime :steam3SSTime,
                steam3SSMin: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalOtherSteamMin :steam3SSMin,
                steam3SSMax: ((unitType === 'coker' || unitType === 'fcc') && isWithDesalter) ? totalOtherSteamMax :steam3SSMax,
                steam4SS: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? excelData[j]['Steam 4° SS'] : null,
                steam4SS_UOM: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam4SSUnit : null,
                steam4SSMass: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam4SSMass : null,
                steam4SSTime: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam4SSTime : null,
                steam4SSMin: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam4SSMin : null,
                steam4SSMax: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam4SSMax : null,
                steam5SS: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? excelData[j]['Steam 5° SS'] : null,
                steam5SS_UOM: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam5SSUnit : null,
                steam5SSMass: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam5SSMass : null,
                steam5SSTime: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam5SSTime : null,
                steam5SSMin: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam5SSMin : null,
                steam5SSMax: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam5SSMax : null,
                steam6SS: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? excelData[j]['Steam 6° SS'] : null,
                steam6SS_UOM: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam6SSUnit : null,
                steam6SSMass: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam6SSMass : null,
                steam6SSTime: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam6SSTime : null,
                steam6SSMin: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam6SSMin : null,
                steam6SSMax: ((unitType !=='coker' && unitType !=='fcc') || ((unitType === 'coker' || unitType === 'fcc') && !isWithDesalter)) ? steam6SSMax : null,
              },
            });
          }
          break;
      }
      setSimulationInput(simulationData);
    }
    console.log(simulationData)
    closeSimulationModal();
    if (isAnyDefaultUnitChanged) setUomWarningDialogueOpen(true);
    else {
      setIsLoading(true);
      draftApiCall(simulationData).then(function (value) {
        if (value) {
          success = true;
          return success;
        }
      });
    }
  };
  const draftApiCall = async (simulationData: any) => {
    let status: boolean = false;
    let simulationInput: any = { assetConfig: '', ionicInput: '' };
    let ionicInput: any = [];
    let assetConfig = {
      refineryID: '',
      refineryType: '',
      refineryName: '',
      unitID: '',
      unitType: '',
      unitName: '',
      subUnitId: '',
      subUnitType: '',
      subUnitName: '',
      configId: 0,
      customerName: '',
      customerId: '',
    };
    let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit.length > 0) {
        configFromStorage.refinery.unit.map((unitItem: any) => {
          if (unitItem.subUnit && unitItem.subUnit.length > 0) {
            unitItem.subUnit.map((subUnitItem: any) => {
              assetConfig.configId = subUnitItem.configId;
              assetConfig.subUnitName = subUnitItem.subUnitName;
              assetConfig.subUnitType = subUnitItem.typeSubunit;
              assetConfig.subUnitId = subUnitItem.subUnitId;
              assetConfig.unitName = unitItem.unitName;
              assetConfig.unitID = unitItem.unitId;
              assetConfig.unitType = unitItem.unitType === 'FCC ' ? unitItem.unitType.trim(): unitItem.unitType;
              assetConfig.refineryName = configFromStorage.refinery.refineryName;
              assetConfig.refineryID = configFromStorage.refinery.refineryId;
              assetConfig.refineryType = configFromStorage.refinery.refineryType;
              assetConfig.customerName = configFromStorage.refinery.customerName;
              assetConfig.customerId = configFromStorage.refinery.customerId;
            });
          }
        });
      }
    }
    let configType = localStorage.getItem('configType')!;
    if (simulationData && simulationData.length) {
      simulationData.forEach((element: any) => {
        let formatedDate = changeToShortFormat(new Date(element.inputDate));
        switch (configType) {
          case '1A':
            ionicInput.push(ionicInputFor1A(formatedDate, element, unitType, isWithDesalter, false, false, configType));
            break;
          case '1B':
            ionicInput.push(ionicInputFor1B(formatedDate, element, false));
            break;
          case '2A':
            ionicInput.push(ionicInputFor2A(formatedDate, element, false, false));
            break;
          case '2B':
            ionicInput.push(ionicInputFor2B(formatedDate, element, false));
            break;
          case '1C':
            ionicInput.push(ionicInputFor1A(formatedDate, element, unitType, isWithDesalter, false, true, configType));
            break;
          case '2C':
            ionicInput.push(ionicInputFor2A(formatedDate, element, false, true));
            break;
        }
      });
    }
    simulationInput.assetConfig = assetConfig;
    simulationInput.ionicInput = ionicInput;
    //add new block in case of sandbox mode
    if(isSandbox){
      simulationInput.sandBoxModelDetails={
        sandBoxSimulationName: simulationName,
        sandBoxSimulationComments:comments
      }
    }

    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulation/draft`;
    apiRequest(encodeURI(apiUrl), simulationInput, APIEnum.POST, true, isSandbox ? 'SandBox':'Actual')
      .then((result) => {
        //history table data api call to get updated config
        if (result && result.data && result.data.inputData && result.data.inputData.ionicInput) {
          status = true;
          let dataFromStorage = JSON.parse(localStorage.getItem('simulationData')!);
          //if already data uploaded, then need to update the newly uploaded data
          if (dataFromStorage && dataFromStorage.length) {
            localStorage.setItem('simulationData', JSON.stringify([...dataFromStorage, ...result.data.inputData.ionicInput]));
          }
          //case when user first time uploads data, need to call api and save that response in local storage
          else {
            localStorage.setItem('simulationData', JSON.stringify(result.data.inputData.ionicInput));
          }
        } else {
          status = false;
          setIsErrorDialogueOpen(true);
          if (result && result.fields) setErrorMessage(result.fields);
          else if (result && result.errors && result.errors[0] && result.errors[0].fields) {
            result.errors[0].description ? setErrorHeader(result.errors[0].description) : setErrorHeader('Validation Errors');
            setErrorMessage(result.errors[0].fields);
          }
        }
      })
      .catch((error: { message: any }) => {
        status = false;
        setIsErrorDialogueOpen(!isErrorDialogueOpen);
        setErrorMessage(error.message);
      })
      .finally(() => {
        bindSimulationData();
        setUploadSuccess(false);
        setIsLoading(false);
      });
    return status;
  };
  const readUploadedExcel = (fileList: any) => {
    setFile(fileList);
    Array.from(fileList)
      .filter(
        (file: any) =>
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel.sheet.macroEnabled.12'
      )
      .forEach(async (file: any) => {
        const text = await file.text();
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array', cellText: false, cellDates: true });
          const sheetName = workbook.SheetNames[1];
          const worksheet = workbook.Sheets[sheetName];
          const jsonString = XLSX.utils.sheet_to_json(worksheet, {
            header: 0,
            defval: '',
            raw: false,
            dateNF: 'mm"/"dd"/"yyyy',
          });
          let jsonData = JSON.stringify(jsonString);
          checkUploadedExcel(JSON.parse(jsonData),fileList[0].name);
          setExcelData(JSON.parse(jsonData));
        };
        reader.readAsArrayBuffer(file);
      });
  };

  const handleChange = (fileList: any) => {
    readUploadedExcel(fileList);
  };

  const handleUpload = (event: any) => {
    readUploadedExcel(event.target.files);
  };

  const handleDialogueClose = () => {
    setIsErrorDialogueOpen(!isErrorDialogueOpen);
  };

  const handleWarningDialogueClose = () => {
    setUomWarningDialogueOpen(!IsUomWarningDialogueOpen);
  };

  const handleProceedClick = () => {
    setUomWarningDialogueOpen(false);
    let success: boolean = false;
    setIsLoading(true);
    draftApiCall(simulationInput).then(function (value) {
      if (value) {
        success = true;
        return success;
      }
    });
  };

  const handleSaveInputClick = () => {
    processExcelData(excelData);
  };

  const handleNextClick = () => {
    if(simulationName && comments){
    setDataGiven(true);
    let simulationDetails={'name':simulationName, 'comment':comments};
    localStorage.setItem('sandboxsimulation',JSON.stringify(simulationDetails));
    setNewSimEdit(false);
    }
  };

  const handleBackButtonClick = () => {
    setNewSimEdit(true);
  };

  const handleTextChange = (event: CustomEvent) => {
    if(event.detail){
    setComments(event.detail);
    setCommentError(false);
    }
    else{
      setComments('');
      setCommentError(true);
    }
  };

  const handleInputChange = (event: any) => {
      if(event.target.value){
        setSimulationName(event.target.value);
        setSimNameError(false);
      }
      else{
        setSimulationName('');
        setSimNameError(true);
      }
  }

  const handleModalClose =()=>{
    setDataGiven(false);
    setNewSimEdit(false);
    closeSimulationModal();
  }

  useEffect(() => {
    //set unit type from local storage
    let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configFromStorage && configFromStorage.refinery) {
      if (configFromStorage.refinery.unit && configFromStorage.refinery.unit[0]) {
        setUnitType(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase());
        if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
          setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false);
        }
        else if(configFromStorage.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
          setWithDesalter(configFromStorage.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false);
        else setWithDesalter(false);
      }
    }
    let ctaArray = [];
    ctaArray.push(
      {
        type: 'ghost',
        label: 'Close',
        key: 'btnUploadCancel',
      },
      { type: 'primary', label: 'Save input', key: 'btnUploadSave' }
      // { type: 'ghost', label: 'Test', key: 'btnTest' },
    );
    setCtaData(JSON.stringify(ctaArray));
    if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') {
    let simulationDetails = localStorage.getItem('sandboxsimulation') && JSON.parse(localStorage.getItem('sandboxsimulation')!);
    if(simulationDetails && Object.keys(simulationDetails).length){
    setSimulationName(simulationDetails.name);
    setComments(simulationDetails.comment);
    }
    }
    return () => {
      setUploadError(false);
      setUploadErrorMessage('');
      setFile(null);
      setUploadSuccess(false);
      setSimNameError(false);
      setCommentError(false);
      setSimulationName('');
      setComments('');
      showNewSimEdit ? setDataGiven(true) : setDataGiven(false);
    };
  }, [props,showNewSimEdit]);

  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleModalClose} aria-labelledby="upload-excel-title" open={isSimulationOpen} className="upload-modal">
        <BootstrapDialogTitle id="upload-excel-title" onClose={handleModalClose}>
          <div className="upload-header">{header}</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
         { (isSandbox && isDataGiven && !showNewSimEdit) || !isSandbox ?(
            <div>
            <BhCard class="bh-card-dashed">
              <FileUploader multiple={true} handleChange={handleChange} name="file" progress={true} disabled={isUploadSuccess} />
              {!isUploadSuccess && !isUploadError ? (
                <div className="bh-icon-container">
                  <BhIcon icon="description_24" class="icon-box"></BhIcon>
                </div>
              ) : (
                <div></div>
              )}
              <p className={!isUploadSuccess && !isUploadError ? 'p-left' : 'p-left-7'}>
                <img src={`${process.env.PUBLIC_URL}/assets/images//done.png`} className="done-icon" hidden={!isUploadSuccess}></img>
                {file && isUploadSuccess ? (
                  `${file[0].name}`
                ) : !isUploadSuccess && isUploadError ? (
                  <span className="error-message">{uploadErrorMessage}</span>
                ) : (
                  'Drag & drop excel file here'
                )}
                <img src={`${process.env.PUBLIC_URL}/assets/images//error.svg`} className="done-icon" hidden={!isUploadError}></img>
              </p>
              <div className="btn-wrapper">
                <BhButton
                  leftIcon="file_download"
                  type="secondary"
                  label="Download template"
                  class={!isUploadSuccess ? 'btn-download' : 'btn-hide'}
                  onClick={downloadExcel}
                ></BhButton>
                <div className={!isUploadSuccess ? 'btn-show' : 'btn-hide'}>
                  <Button variant="contained" component="label" id="btn-upload">
                    Upload excel
                    <input
                      type="file"
                      hidden
                      accept="excel/*"
                      onChange={(event) => {
                        handleUpload(event);
                      }}
                    ></input>
                  </Button>
                </div>
              </div>
            </BhCard>
          </div>
          ):(
          <div>
              <BhTextInput
                            id="txt-simname"
                            type="text"
                            label="New simulation name"
                            value={simulationName}
                            isRequired
                            isError={isSimNameError}
                            onBhEventChange={(event) => {
                              handleInputChange(event);
                            }}
                          ></BhTextInput>
                           <BhFormMessage isError message={isSimNameError ? 'This field is mandatory': ''} id="simname-error"></BhFormMessage>
                          <div className='pd-3'></div>
                               <BhTextArea
            fluidHorz={true}
            maxChar={500}
            messageType="count"
            label='Comments'
            isRequired
            placeholder='Write something...'
            onBhEventInput={(event: any) => handleTextChange(event)}
            //onBhEventChange={(event: any) => handleTextChange(event)}
            value={comments}
          ></BhTextArea>
           <BhFormMessage isError message={isCommentError ? 'This field is mandatory': ''} id="comment-error"></BhFormMessage>
          </div>
          )}
        </DialogContent>
        <DialogActions>
          {!isSandbox || (isSandbox && !isDataGiven) ? <BhButton type="ghost" label="Close" key="btnUploadCancel" onClick={handleModalClose} ></BhButton>:null}
          <BhButton
            type="primary"
            label="Next"
            key="btnNext"
            className='btn-next'
            hidden={!isSandbox || (isSandbox && isDataGiven)}
            isDisabled={!simulationName || !comments}
            onClick={handleNextClick}
          ></BhButton>
          { (isSandbox && isDataGiven) ? <BhButton type="ghost" label="Back" key="btnBack" onClick={handleBackButtonClick}></BhButton> :null}
          { !isSandbox || (isSandbox && isDataGiven) ?
          (<div>
          <BhButtonDropdown
            class="input-dropdown"
            isOpen={openDropDownMenu}
            onBhEventCtaClick={() => otherInputClicked()}
            label="Other input"
            id="btnInput"
            type="secondary"
            menuItems={{
              itemGroups: [
                {
                  items: [
                    {
                      label: 'Manual input',
                      value: 'manual-input-item',
                    },
                  ],
                  divider: false,
                },
              ],
            }}
            onBhEventChange={(event: any) => handleCtaClick(event)}
          ></BhButtonDropdown>
          </div>)
          :(null)}
          { !isSandbox || (isSandbox && isDataGiven) ? 
          <BhButton
            type="primary"
            label="Save input"
            key="btnUploadSave"
            class="margin-left"
            isDisabled={!isUploadSuccess}
            onClick={handleSaveInputClick}
          ></BhButton> :null}
        </DialogActions>
      </BootstrapDialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
        open={isLoading}
        onClick={handleClose}
      >
        {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
      </Backdrop>
      <Dialog open={isErrorDialogueOpen} onClose={handleDialogueClose} id="error-dialogue">
        <BootstrapDialogTitle id="upload-excel-title" onClose={handleDialogueClose}>
          <div className="upload-header">{errorHeader}</div>
        </BootstrapDialogTitle>
        <div className="errorinfo-padding">
          {errorMessage &&
            errorMessage.map(function (item: any, i: any) {
              return (
                <>
                  <label>{Object.keys(item)[0]}</label>
                  <ul>
                    {item[Object.keys(item)[0]] &&
                      item[Object.keys(item)[0]].map(function (error: any, index: any) {
                        return <li key={index}>{error}</li>;
                      })}
                  </ul>
                </>
              );
            })}
        </div>
      </Dialog>
      <Dialog open={IsUomWarningDialogueOpen} onClose={handleWarningDialogueClose} id="error-dialogue">
        <BootstrapDialogTitle id="uom-warning-title" onClose={handleWarningDialogueClose}>
          <div className="upload-header">Discrepancy with default units</div>
        </BootstrapDialogTitle>
        <DialogContent>
          <div className="errorinfo-padding">
            {defaultUnitWarningObj &&
              defaultUnitWarningObj.map(function (warningItem: any, index: number) {
                return (
                  <>
                    <label>{Object.keys(warningItem)}</label>
                    <ul>
                      <li key={index}>{warningItem[Object.keys(warningItem)[0]]}</li>
                    </ul>
                  </>
                );
              })}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="bh-dialog__footer">
            <div className="bh-dialog__footer--ctas">
              <BhButton
                type="ghost"
                label="Cancel"
                key="btnCancel"
                onClick={handleWarningDialogueClose}
                isSmall
                className="bh-dialog__footer--cta"
              ></BhButton>
              <BhButton
                isSmall
                className="bh-dialog__footer--cta"
                type="primary"
                label="Proceed"
                key="btnProceed"
                id="btnProceed"
                onClick={handleProceedClick}
              ></BhButton>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
